import { Language, Utils } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import { i18n } from 'i18next'

import { UserRole } from '../apis/types/apiResponseTypes'
import {
  ApiFilterConfig,
  ApiFilterDataSorter,
  ApiFilterType,
  DataFilterValue,
  FilterConfig,
  FilterConfigOptions,
  FilterDataSorter,
  GroupConfig,
  GroupDataSorter,
  GroupedApiFilterTag,
  GroupedData,
  OrderConfig,
  OrderDataSorter,
  SearchKey,
} from '../types'
import TimeUtils from './TimeUtils'

type DataSorter<T, TSub = void> = OrderDataSorter<T, TSub> | GroupDataSorter<T, TSub>

export const filterUtils = {
  initDataGroup<T extends object, TSub = void, TApi = void>(config: GroupConfig<T, TSub, TApi> | undefined): GroupDataSorter<T, TSub>[] {
    if (!config) return []

    return config.config.map<GroupDataSorter<T, TSub>>(([dataKey, textKey, options]) => ({
      id: `${dataKey.toString()}${options?.modifier ?? ''}`,
      type: config.type,
      dataKey,
      textKey,
      active: !!options?.isDefault,
      options,
      apiRequestKey: config.apiRequestKey as string,
    }))
  },
  initDataOrder<T extends object, TSub = void, TApi = void>(config: OrderConfig<T, TSub, TApi> | undefined): OrderDataSorter<T, TSub>[] {
    if (!config) return []

    return config.config.map<OrderDataSorter<T, TSub>>(([dataKey, textKey, options]) => ({
      id: `${dataKey.toString()}${options?.modifier ?? ''}`,
      type: config.type,
      dataKey,
      textKey,
      active: !!options?.isDefault,
      options,
      apiRequestKey: config.apiRequestKey as string,
    }))
  },
  initDataFilter<T extends object, TSub = void>(config: FilterConfig<T, TSub> | undefined): FilterDataSorter<T, TSub>[] {
    return (
      config?.config.map<FilterDataSorter<T, TSub>>(([dataKey, textKey, options]) => ({
        id: `${dataKey.toString()}`,
        type: config?.type,
        dataKey,
        textKey,
        values: [],
        options,
      })) ?? []
    )
  },
  initDataApiFilter<T>(config: ApiFilterConfig<T> | undefined, user: UserRole = UserRole.technician): ApiFilterDataSorter<T>[] {
    return (
      config?.config.map<ApiFilterDataSorter<T>>(([dataKey, textKey, options]) => ({
        id: `${dataKey.toString()}`,
        type: options?.filterType ?? ApiFilterType.Default,
        dataKey,
        textKey,
        active: false,
        value: '',
        text: '',
        group: options?.group,
        textPrefix: options?.textPrefix,
        icon: options?.icon,
        visible: !options?.excludeRoles?.includes(user) && (!options?.roles?.length || options.roles.includes(user)),
      })) ?? []
    )
  },
  prepareFilterValues: <T, Y extends { id?: number } | void = void>(
    data: T[],
    dataKey: keyof T,
    filterId: string,
    options: FilterConfigOptions<Y> | undefined,
    translator: i18n
  ) => {
    let toRet: DataFilterValue[] = []

    switch (options?.filterType) {
      case 'dateRange': {
        toRet.push({
          id: `${filterId}#minDate`,
          value: '',
          isDateRangeMax: false,
          active: false,
        })

        toRet.push({
          id: `${filterId}#maxDate`,
          value: '',
          isDateRangeMax: true,
          active: false,
        })

        break
      }
      case 'array': {
        const foundValues: Y[] = []
        const subArrays = data.map(el => el[dataKey]).filter(el => el !== undefined)

        for (const subArray of subArrays) {
          if (Array.isArray(subArray)) {
            subArray.forEach(el => {
              foundValues.push(el as Y)
            })
          }
        }

        foundValues.forEach(val => {
          const count = foundValues.filter(el => (el && val ? el.id === val.id : undefined)).length
          const id = val ? val.id ?? 0 : 0

          toRet.push({
            id: `${filterId}#${id}`,
            value: options.valueExtractor(val),
            count,
            arrayElementId: id,
          })
        })

        toRet = Utils.keepUniques(toRet, e => (e ? e.id : undefined))
        break
      }
      case 'boolean': {
        let foundValues: string[] = []
        foundValues = data.map(element => element[dataKey]?.toString() ?? '')

        const trueCount = foundValues.filter(el => el !== '').length
        toRet.push({
          id: `${filterId}#true`,
          value: translator.t(options.trueText),
          booleanTypeTrueTag: true,
          count: trueCount,
        })

        const falseCount = foundValues.filter(el => el === '').length
        toRet.push({
          id: `${filterId}#false`,
          value: translator.t(options.falseText),
          booleanTypeTrueTag: false,
          count: falseCount,
        })

        toRet = toRet.filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
        break
      }
      default: {
        let foundValues: string[] = []
        const values = data.map(element => element[dataKey]?.toString() ?? '').filter(q => !!q)
        foundValues = [...new Set(values)]

        for (const filterValue of foundValues) {
          // add new values
          if (!toRet.find(q => q.value === filterValue)) {
            toRet.push({
              id: `${filterId}-${filterValue}`,
              value: filterValue,
            })
          }
        }

        // update the count for each value
        toRet.forEach(value => {
          const count = data.filter(element => element[dataKey]?.toString() === value.value)?.length
          value.count = count
        })

        // filter old values, that no longer exist
        toRet = toRet.filter(filterEntryValue => !!foundValues.find(fv => fv === filterEntryValue.value))
      }
    }

    return toRet
  },
  getActiveFilters<T extends object, TSub = void>(filters: FilterDataSorter<T, TSub>[]) {
    const result: FilterDataSorter<T, TSub>[] = []

    for (const filter of filters) {
      const activeValues = filter.values.filter(v => v.active)
      if (!activeValues.length) continue
      result.push({ ...filter, values: [...activeValues] })
    }
    return result
  },
  getActiveApiFilters<T>(filters: ApiFilterDataSorter<T>[]) {
    return filters.filter(f => !!f.active)
  },
  getActiveFilterCount<T extends object, TApi>(filters: FilterDataSorter<T>[], apiFilter: ApiFilterDataSorter<TApi>[]) {
    return (
      filters.reduce((count, currentFilter) => {
        return count + currentFilter.values.filter(q => q.active).length
      }, 0) + apiFilter.filter(f => !!f.active).length
    )
  },
  getActiveSorter<T extends object>(sorter: DataSorter<T>[]) {
    const result: DataSorter<T>[] = []

    for (const entry of sorter) {
      if (!entry.active) continue
      result.push({ ...entry })
    }

    return result
  },
  sortOrders<T>(a: DataSorter<T>, b: DataSorter<T>) {
    if (a.active && !b.active) return -1
    if (!a.active && b.active) return 1
    return (a.order ?? 0) - (b.order ?? 0)
  },
  applySort<T extends object>(items: T[], orders: DataSorter<T>[]) {
    const activeOrders = orders.filter(o => o.active).sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    // if no active orders are set, use default order instead
    if (!activeOrders.length) {
      const defaultOrder = orders.find(o => o.options?.isDefault)
      if (defaultOrder) activeOrders.push(defaultOrder)
    }
    if (!activeOrders?.length) return [...items]
    const sortedItems = [...items]
    sortedItems.sort((a, b) => {
      for (const activeOrder of activeOrders) {
        const sortDir = activeOrder.options?.modifier === 'inverse' ? -1 : 1
        const valueA = getSorterValue(a, activeOrder)
        const valueB = getSorterValue(b, activeOrder)
        if (valueA && !valueB) return sortDir
        if (!valueA && valueB) return -sortDir
        if (!valueA && !valueB) continue
        if (valueA > valueB) return sortDir
        else if (valueA < valueB) return -sortDir
      }
      return 0
    })

    return sortedItems
  },
  applyFilter<T extends object, TSub = void>(items: T[], filters: FilterDataSorter<T, TSub>[]) {
    const activeFilters = this.getActiveFilters(filters)
    if (!activeFilters.length) return [...items]

    const filtered = items.filter(item => {
      for (const activeFilter of activeFilters) {
        switch (activeFilter.options?.filterType) {
          case 'dateRange': {
            const dataValue = item[activeFilter.dataKey] as string | undefined

            if (dataValue === undefined) return false

            let start: string | undefined
            let end: string | undefined

            for (const activeFilterValue of activeFilter.values) {
              const val = activeFilterValue.value === '' ? undefined : activeFilterValue.value
              if (activeFilterValue.isDateRangeMax) {
                end = val
              } else {
                start = val
              }
            }

            if (start !== undefined && end !== undefined) {
              const endOfDay = dayjs(end).endOf('day')
              if (!dayjs(dataValue).isBetween(dayjs(start), endOfDay)) return false
            } else if (start === undefined && end !== undefined) {
              if (!dayjs(dataValue).isAfter(dayjs(start))) return false
            } else if (start !== undefined && end === undefined) {
              const endOfDay = dayjs(end).endOf('day')
              if (!dayjs(dataValue).isBefore(endOfDay)) return false
            }
            break
          }
          case 'array': {
            const dataValue = item[activeFilter.dataKey]

            if (dataValue === undefined) return false

            if (Array.isArray(dataValue)) {
              for (const activeFilterValue of activeFilter.values) {
                if (dataValue.find(el => (el as { id: number }).id === activeFilterValue.arrayElementId) === undefined) return false
              }
            }
            break
          }
          case 'boolean': {
            const dataValue = item[activeFilter.dataKey]?.toString()
            for (const activeFilterValue of activeFilter.values) {
              if (
                !(
                  (activeFilterValue.active && activeFilterValue.booleanTypeTrueTag === true && dataValue !== undefined) ||
                  (activeFilterValue.booleanTypeTrueTag === false && dataValue === undefined)
                )
              ) {
                return false
              }
            }
            break
          }
          default: {
            const dataValue = item[activeFilter.dataKey]?.toString()
            if (!dataValue) continue

            const results = []
            for (const activeFilterValue of activeFilter.values) {
              results.push(activeFilterValue.active && activeFilterValue.value === dataValue)
            }

            if (!results.includes(true, 0)) return false

            break
          }
        }
      }

      return true
    })

    return filtered
  },
  groupBy<T extends object>(items: T[], group: DataSorter<T> | undefined, language: Language, i18nValue: i18n): GroupedData<T>[] {
    if (!items || !group?.active) return [{ key: null, title: '', data: items }]

    const keys = Utils.keepUniques(
      items.map(q => getSorterValue(q, group)),
      q => q
    )
    return keys
      .map<GroupedData<T>>(keyValue => {
        const data = items.filter(item => getSorterValue(item, group) === keyValue)
        if (!data.length) return { key: '', title: '', data: [] }
        let title = ''
        if (group.options?.textKey) {
          if (typeof group.options.textKey === 'string') {
            title = data[0][group.options.textKey]?.toString() ?? ''
          } else if (typeof group.options.textKey === 'object') {
            const selectedLanguageValue =
              data[0][group.options.textKey[language]]?.toString() ||
              data[0][group.options.textKey.de]?.toString() ||
              data[0][group.options.textKey.it]?.toString() ||
              data[0][group.options.textKey.en]?.toString()
            if (selectedLanguageValue) {
              title = selectedLanguageValue
            }
          }
        } else if (group.options?.textProvider) {
          title = i18nValue.t(group.options.textProvider(keyValue))
        }

        if (!title) title = keyValue?.toString() ?? ''
        if (title.match(/\d{4}-\d{2}-\d{2}/)) {
          try {
            const formattedDate = TimeUtils.format(title, language)
            if (formattedDate) title = formattedDate
          } catch (_e) {
            _e
          }
        }
        return { key: keyValue?.toString() ?? null, title, data }
      })
      .filter(q => !!q.data.length)
      .sort((a, b) => (!a.title && b.title ? 1 : a.title && !b.title ? -1 : 0))
  },
  groupedDataToSectionList<T extends object>(data: GroupedData<T>[], notAssignedText?: string) {
    const result: (string | T)[] = []
    for (const entry of data) {
      if (!entry.data.length) continue
      if (entry.key || (notAssignedText && data.length > 1)) result.push(entry.title || entry.key || notAssignedText || '')
      result.push(...entry.data)
    }
    return result
  },
  searchOnItem<T extends object | string>(item: T | undefined, search: string, keys?: SearchKey<T>[]) {
    if (!item) return false
    if (!search) return true
    const searchTerms = search
      .trim()
      .split(' ')
      .map(q => q.toLowerCase().trim())
      .filter(q => !!q)
    if (typeof item === 'string') {
      const lowerCaseItem = item.toLowerCase()
      for (const searchTerm of searchTerms) {
        if (!lowerCaseItem.includes(searchTerm)) return false
      }
      return true
    }
    if (!keys) return true
    for (const searchTerm of searchTerms) {
      let found = false
      for (const key of keys) {
        let itemValue = null
        // if searchKey is of type string then add the value directly from the object
        if (typeof key === 'string') {
          itemValue = item[key]
          // if searchKey is a combination-key then join the values using the separator ([0])
        } else if (Array.isArray(key)) {
          const [separator, ...combinationKeys] = key
          if (combinationKeys?.length) {
            itemValue = combinationKeys
              .map(comKey => item[comKey]?.toString())
              .filter(q => !!q)
              .join(separator)
          }
        }
        if (itemValue === undefined || itemValue === null) continue
        if (typeof itemValue === 'string' && itemValue.toLowerCase().includes(searchTerm)) {
          found = true
          break
        }
        if (typeof itemValue === 'number' && itemValue.toString() === searchTerm) {
          found = true
          break
        }
      }
      if (!found) return false
    }
    return true
  },
  filterItemsBySearch<T extends object | string>(items: T[] | undefined, search: string, keys?: SearchKey<T>[]) {
    return items?.filter(item => this.searchOnItem(item, search, keys)) ?? []
  },
  groupApiFilters<T>(apiFilter: ApiFilterDataSorter<T>[] | undefined) {
    const result: GroupedApiFilterTag<T>[] = []

    Utils.keepUniques(apiFilter ?? [], q => q.group ?? q.id).forEach(filter => {
      if (!filter.group) {
        result.push({ data: [filter] })
        return
      }
      if (result.find(r => r.group === filter.group)) return
      const foundFilterWithGroup = apiFilter?.filter(f => f.group === filter.group) ?? [filter]
      result.push({ data: foundFilterWithGroup, group: filter.group })
    })

    return result
  },
}

function getSorterValue<T extends object, TSub>(obj: T, sorter: DataSorter<T, TSub>) {
  const value = obj[sorter.dataKey]
  if (value === undefined || value === null) return ''
  if (typeof value !== 'object') return value

  if (sorter.options?.subObjectValueProvider) {
    return sorter.options.subObjectValueProvider(value as TSub, obj) ?? ''
  }
  if (sorter.options?.subObjectKey) {
    const subObjectKey = sorter.options?.subObjectKey
    if (Array.isArray(value)) {
      const foundValue = (value as TSub[]).find(v => !!v[subObjectKey])
      if (!foundValue) return ''
      return foundValue[subObjectKey] ?? ''
    }
    return value[subObjectKey as keyof typeof value] ?? ''
  }
  console.warn('DataSorter value was an object, but no subObjectKey or valueProvider was set in the configuration', sorter.dataKey, sorter.textKey)
  return ''
}
