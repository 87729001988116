import { library } from '@fortawesome/fontawesome-svg-core'
// light
import { fa00 } from '@fortawesome/pro-light-svg-icons/fa00'
import { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook'
import { faAnglesLeft } from '@fortawesome/pro-light-svg-icons/faAnglesLeft'
import { faAnglesRight } from '@fortawesome/pro-light-svg-icons/faAnglesRight'
import { faArrowDownArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowDownArrowUp'
import { faArrowDownShortWide } from '@fortawesome/pro-light-svg-icons/faArrowDownShortWide'
import { faArrowDownToBracket } from '@fortawesome/pro-light-svg-icons/faArrowDownToBracket'
import { faArrowDownWideShort } from '@fortawesome/pro-light-svg-icons/faArrowDownWideShort'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faArrowsFromLine } from '@fortawesome/pro-light-svg-icons/faArrowsFromLine'
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons/faArrowsRepeat'
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate'
import { faArrowTurnDownRight } from '@fortawesome/pro-light-svg-icons/faArrowTurnDownRight'
import { faArrowUpArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowUpArrowDown'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare'
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons/faBadgeCheck'
import { faBarcode } from '@fortawesome/pro-light-svg-icons/faBarcode'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faBinarySlash } from '@fortawesome/pro-light-svg-icons/faBinarySlash'
import { faBox } from '@fortawesome/pro-light-svg-icons/faBox'
import { faBoxArchive } from '@fortawesome/pro-light-svg-icons/faBoxArchive'
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase'
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faCalendarArrowDown } from '@fortawesome/pro-light-svg-icons/faCalendarArrowDown'
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck'
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock'
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay'
import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons/faCalendarWeek'
import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera'
import { faCartMinus } from '@fortawesome/pro-light-svg-icons/faCartMinus'
import { faCartPlus } from '@fortawesome/pro-light-svg-icons/faCartPlus'
import { faCarWrench } from '@fortawesome/pro-light-svg-icons/faCarWrench'
import { faChartGantt } from '@fortawesome/pro-light-svg-icons/faChartGantt'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo'
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons/faClockRotateLeft'
import { faCloudArrowDown } from '@fortawesome/pro-light-svg-icons/faCloudArrowDown'
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp'
import { faCloudExclamation } from '@fortawesome/pro-light-svg-icons/faCloudExclamation'
import { faCloudSlash } from '@fortawesome/pro-light-svg-icons/faCloudSlash'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faCrosshairs } from '@fortawesome/pro-light-svg-icons/faCrosshairs'
import { faCubes } from '@fortawesome/pro-light-svg-icons/faCubes'
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase'
import { faDisplay } from '@fortawesome/pro-light-svg-icons/faDisplay'
import { faDisplayArrowDown } from '@fortawesome/pro-light-svg-icons/faDisplayArrowDown'
import { faDisplayChartUp } from '@fortawesome/pro-light-svg-icons/faDisplayChartUp'
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons/faEllipsisVertical'
import { faEngine } from '@fortawesome/pro-light-svg-icons/faEngine'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faEuroSign } from '@fortawesome/pro-light-svg-icons/faEuroSign'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile'
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract'
import { faFileDoc } from '@fortawesome/pro-light-svg-icons/faFileDoc'
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf'
import { faFileSignature } from '@fortawesome/pro-light-svg-icons/faFileSignature'
import { faFileSlash } from '@fortawesome/pro-light-svg-icons/faFileSlash'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faFilterCircleXmark } from '@fortawesome/pro-light-svg-icons/faFilterCircleXmark'
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons/faFloppyDisk'
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen'
import { faFunction } from '@fortawesome/pro-light-svg-icons/faFunction'
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faGripLines } from '@fortawesome/pro-light-svg-icons/faGripLines'
import { faHardHat } from '@fortawesome/pro-light-svg-icons/faHardHat'
import { faHeadset } from '@fortawesome/pro-light-svg-icons/faHeadset'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faHourglassClock } from '@fortawesome/pro-light-svg-icons/faHourglassClock'
import { faIdBadge } from '@fortawesome/pro-light-svg-icons/faIdBadge'
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage'
import { faImageSlash } from '@fortawesome/pro-light-svg-icons/faImageSlash'
import { faIslandTropical } from '@fortawesome/pro-light-svg-icons/faIslandTropical'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faList } from '@fortawesome/pro-light-svg-icons/faList'
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot'
import { faLocationDotSlash } from '@fortawesome/pro-light-svg-icons/faLocationDotSlash'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass'
import { faMapLocationDot } from '@fortawesome/pro-light-svg-icons/faMapLocationDot'
import { faMessageDots } from '@fortawesome/pro-light-svg-icons/faMessageDots'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile'
import { faNotes } from '@fortawesome/pro-light-svg-icons/faNotes'
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons/faNoteSticky'
import { faPalette } from '@fortawesome/pro-light-svg-icons/faPalette'
import { faPaperclipVertical } from '@fortawesome/pro-light-svg-icons/faPaperclipVertical'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { faPenField } from '@fortawesome/pro-light-svg-icons/faPenField'
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare'
import { faPersonRunningFast } from '@fortawesome/pro-light-svg-icons/faPersonRunningFast'
import { faPersonToDoor } from '@fortawesome/pro-light-svg-icons/faPersonToDoor'
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faPhoneOffice } from '@fortawesome/pro-light-svg-icons/faPhoneOffice'
import { faPhotoFilmMusic } from '@fortawesome/pro-light-svg-icons/faPhotoFilmMusic'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode'
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion'
import { faRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat'
import { faRotate } from '@fortawesome/pro-light-svg-icons/faRotate'
import { faRoute } from '@fortawesome/pro-light-svg-icons/faRoute'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons/faScrewdriverWrench'
import { faShareNodes } from '@fortawesome/pro-light-svg-icons/faShareNodes'
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck'
import { faSidebarFlip } from '@fortawesome/pro-light-svg-icons/faSidebarFlip'
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare'
import { faStop } from '@fortawesome/pro-light-svg-icons/faStop'
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch'
import { faTableColumns } from '@fortawesome/pro-light-svg-icons/faTableColumns'
import { faTableRows } from '@fortawesome/pro-light-svg-icons/faTableRows'
import { faTicket } from '@fortawesome/pro-light-svg-icons/faTicket'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faTruckClock } from '@fortawesome/pro-light-svg-icons/faTruckClock'
import { faTvMusic } from '@fortawesome/pro-light-svg-icons/faTvMusic'
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faUserHelmetSafety } from '@fortawesome/pro-light-svg-icons/faUserHelmetSafety'
import { faUserPen } from '@fortawesome/pro-light-svg-icons/faUserPen'
import { faUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash'
import { faVolume } from '@fortawesome/pro-light-svg-icons/faVolume'
import { faWarehouseFull } from '@fortawesome/pro-light-svg-icons/faWarehouseFull'
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark'
// regular
import { faCheck as RegularCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronUp as RegularChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faStopwatch as RegularStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { faTruckClock as TruckClock } from '@fortawesome/pro-regular-svg-icons/faTruckClock'
import { faCalendarRange } from '@fortawesome/pro-solid-svg-icons/faCalendarRange'
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCircle as faSolidCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
// solid
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faCircleExclamation as faCircleExclamationSolid } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { faCircleInfo as faSolidCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo'
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons/faCircleMinus'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { faClapperboardPlay } from '@fortawesome/pro-solid-svg-icons/faClapperboardPlay'
import { faCloudSlash as SolidCloudSlash } from '@fortawesome/pro-solid-svg-icons/faCloudSlash'
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons/faLocationArrow'
import { faLocationDot as SolidLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot'
import { faLock as SolidLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faMinus as faSolidMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPause as faPauseSolid } from '@fortawesome/pro-solid-svg-icons/faPause'
import { faPhone as SolidPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPlay as faPlaySolid } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { faPlus as faSolidPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTrash as faTrashSolid } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'
import { faUnlock as SolidUnlock } from '@fortawesome/pro-solid-svg-icons/faUnlock'
import React from 'react'

library.add(
  faCamera,
  faUpload,
  faCalendarRange,
  faSquare,
  faSidebarFlip,
  faTrashSolid,
  faRotate,
  faFilterCircleXmark,
  faSolidCircleInfo,
  faFloppyDisk,
  faArrowLeft,
  faPersonToDoor,
  faXmark,
  faHome,
  faMagnifyingGlass,
  faFilter,
  faCloudArrowDown,
  faCloudArrowUp,
  faCloudSlash,
  faCloudExclamation,
  faMapLocationDot,
  faTicket,
  faClockRotateLeft,
  faGear,
  faChevronRight,
  faChevronLeft,
  faBars,
  faIdBadge,
  faLock,
  faArrowsRepeat,
  faBadgeCheck,
  faArrowDownArrowUp,
  faArrowsFromLine,
  faGripLines,
  faUserSlash,
  faUser,
  faBuilding,
  faQrcode,
  faBinarySlash,
  SolidLock,
  faBarcode,
  faArrowUpRightFromSquare,
  faEye,
  faEyeSlash,
  faCopy,
  SolidUnlock,
  faCircleNotch,
  faPlus,
  faCheck,
  faArrowsRotate,
  faTrash,
  fa00,
  faEnvelope,
  faFileSlash,
  faArrowRight,
  faPhoneOffice,
  faLocationDot,
  faStopwatch,
  faMessageDots,
  faHeadset,
  faDisplay,
  faCarWrench,
  faScrewdriverWrench,
  faGlobe,
  faCircleInfo,
  faCircleMinus,
  faCalendar,
  faAddressBook,
  faPhotoFilmMusic,
  faClock,
  faPhone,
  faMobile,
  faUserPen,
  SolidPhone,
  SolidCloudSlash,
  faEnvelopeSolid,
  faLocationDotSlash,
  faRoute,
  faPenField,
  faFolder,
  faTriangleExclamation,
  faFolderOpen,
  faCrosshairs,
  faList,
  SolidLocationDot,
  faRepeat,
  faCircleCheck,
  faImage,
  faArrowDownToBracket,
  faFile,
  faFileDoc,
  faFileExcel,
  faCircleXmark,
  faChevronDown,
  faChevronUp,
  RegularCheck,
  faImageSlash,
  RegularChevronUp,
  faLocationArrow,
  faLayerGroup,
  faArrowUpArrowDown,
  faPen,
  faCubes,
  faFileSignature,
  faShieldCheck,
  faDisplayChartUp,
  faPaperPlane,
  faFunction,
  faNotes,
  faTruckClock,
  faPlay,
  faStop,
  faEllipsisVertical,
  faCircleExclamationSolid,
  faSolidMinus,
  faSolidPlus,
  faBox,
  faEuroSign,
  faCartPlus,
  faMinus,
  faCartMinus,
  faCalendarArrowDown,
  RegularStopwatch,
  TruckClock,
  faCalendarDay,
  faCalendarWeek,
  faCalendarClock,
  faNoteSticky,
  faHardHat,
  faFileContract,
  faQuestion,
  faIslandTropical,
  faHourglassClock,
  faArrowDownWideShort,
  faArrowDownShortWide,
  faDatabase,
  faAnglesLeft,
  faAnglesRight,
  faChartGantt,
  faTableColumns,
  faTableRows,
  faPalette,
  faPlaySolid,
  faPauseSolid,
  faCheckSolid,
  faDisplayArrowDown,
  faPenToSquare,
  faBoxArchive,
  faCalendarCheck,
  faBriefcase,
  faShareNodes,
  faWarehouseFull,
  faClapperboardPlay,
  faPersonRunningFast,
  faEngine,
  faUserHelmetSafety,
  faArrowTurnDownRight,
  faTvMusic,
  faListOl,
  faPaperclipVertical,
  faFilePdf,
  faSolidCircle,
  faVolume
)

export default function InitStructures() {
  return <></>
}
