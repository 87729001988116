import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity, ActivityEmployee, Contract, Technician } from '../../apis/types/apiResponseTypes'
import GroupSpacer from '../../components/GroupSpacer'
import { TextInputProps } from '../../components/input/MultiLangTextInput'
import RepetitionSelector from '../../components/repetition/RepetitionSelector'
import ScrollViewForm from '../../components/ScrollViewForm'
import ConstructionSiteContactSelector from '../../components/selectors/ConstructionSiteContactSelector'
import ContactSelector from '../../components/selectors/ContactSelector'
import MultiTechnicianSelector from '../../components/selectors/MultiTechnicianSelector'
import useUserSettings from '../../hooks/useUserSettings'
import { ContractActivity, Repetition } from '../../types'
import ActivityCreationDefaultInputs from '../activities/ActivityCreationDefaultInputs'

type ContractActivityCreationViewProps = {
  value: ContractActivity
  setValue: React.Dispatch<React.SetStateAction<ContractActivity>>
  contract?: Contract
}

export default function ContractActivityCreationView({ value, setValue, contract }: ContractActivityCreationViewProps) {
  const { userSettings } = useUserSettings()
  const { i18n } = useLanguage()

  const selectedEmployees = useMemo<Technician[]>(
    () => value.activity.createActivityEmployees?.map(e => e.technician).filter(q => !!q) as Technician[],
    [value.activity.createActivityEmployees]
  )

  const commonProps = {
    spacing: 'vertical',
  } satisfies Partial<TextInputProps>

  function handleActivityChanged(activity: Partial<Activity>) {
    setValue(prev => {
      prev.activity = { ...prev.activity, ...activity }
      return { ...prev }
    })
  }

  function handleRepetitionChanged(repetition: Partial<Repetition>) {
    setValue(prev => {
      if (!prev.repetition) prev.repetition = { ...repetition } as Repetition
      else prev.repetition = { ...prev.repetition, ...repetition }
      return { ...prev }
    })
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <ActivityCreationDefaultInputs
        state={value.activity}
        initialValue={value.activity}
        handleDataChange={changedData => {
          if (changedData.taskId) changedData.createActivityEmployees = []
          handleActivityChanged(changedData)
        }}
        hide={value.repetition.type !== 'none' ? ['expireDate'] : undefined}
        {...commonProps}
      />

      <ContactSelector
        customerId={contract?.companyId ?? 0}
        editable={!!contract?.companyId}
        onChange={contact => handleActivityChanged({ contactId: contact?.contactId })}
        noDataMessage={contract?.companyId ? undefined : i18n.t('NO_CUSTOMER_SELECTED')}
        disableFastInput
        value={value.activity.contactId}
        title={i18n.t('CUSTOMER_CONTACT')}
        {...commonProps}
      />
      <ConstructionSiteContactSelector
        objectId={contract?.objectId ?? 0}
        editable={!!contract?.objectId}
        onChange={contact => handleActivityChanged({ objectContactId: contact?.contactId })}
        noDataMessage={contract?.objectId ? undefined : i18n.t('NO_CONSTRUCTION_SITE_SELECTED')}
        disableFastInput
        value={value.activity.objectContactId}
        {...commonProps}
      />

      <IM.View>
        <MultiTechnicianSelector
          selectedTechnicians={selectedEmployees}
          taskId={value.activity.taskId}
          onChange={technicians =>
            handleActivityChanged({
              createActivityEmployees: technicians?.map(
                q =>
                  ({
                    technician: q,
                    technicianId: q.id,
                    technicianType: q.technicianType,
                    plannedByEmployeeId: userSettings?.employee?.id,
                  }) as ActivityEmployee
              ),
            })
          }
          editable={!!value.activity.taskId}
          subTitle={value.activity.taskId ? undefined : i18n.t('SELECT_TASK_ID')}
        />
      </IM.View>
      <GroupSpacer />
      <RepetitionSelector repetition={value.repetition} onChange={handleRepetitionChanged} />
      <GroupSpacer
        style={(value.repetition?.type === 'none' || !value.planned) && styles.endView} //EndView to improve ux because of switch
      />
    </ScrollViewForm>
  )
}

const styles = StyleSheet.create({
  endView: {
    minHeight: 200,
    height: 200,
  },
})
