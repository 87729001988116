import { SpacingProps, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import CustomerContactCard from '../../cards/customer/CustomerContactCard'
import CONSTANTS from '../../constants/Constants'
import useObjectUtils from '../../hooks/useObjectUtils'
import CustomerContactCreationModal from '../../modals/customer/CustomerContactCreationModal'
import { ListSection } from '../../types'
import customerUtils from '../../utils/customerUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Contact | undefined) => void
  disableFastInput?: boolean
  customerId: number
  value?: number
  title?: string
  canCreateNew?: boolean
} & Partial<Pick<SelectInputProps<Contact>, 'disabledInfo' | 'subTitle' | 'noDataMessage'>>

export default function ContactSelector({ customerId, onChange, disableFastInput, value, title, noDataMessage, canCreateNew, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const createContactModal = useModalController<Contact | undefined>()
  const {
    item: data,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.customers.contacts.getList, { chuckSize: CONSTANTS.defaultChunkSize })
  const objectUtils = useObjectUtils<Contact>(apiDtoIds.contacts)
  const { item: selected, loadItem: loadSelected, setItem: setSelected } = useControlledLoader(api.customers.contacts.getDetail)

  useEffect(() => {
    refresh()
  }, [search, customerId])

  const refresh = () => customerId && loadItem({ customerId, searchtext: search })

  useEffect(() => {
    if (value && (selected?.contactId !== value || selected.companyId !== customerId)) loadSelected({ contactId: value, customerId: customerId })
    if (!value) setSelected(undefined)
  }, [value, customerId])

  const render = ({ item }: SectionListRenderItemInfo<Contact, ListSection<Contact>>, onPress?: () => void) => {
    return (
      <CustomerContactCard
        contact={item}
        selected={objectUtils.compare(item, selected)}
        onPress={onPress}
        hideButtons
        spacing={['horizontal', 'bottom']}
      />
    )
  }

  const handleOnChange = (newValue: Contact | undefined) => {
    setSelected(newValue)
    onChange(newValue)
  }
  function handleContactCreated(contact: Contact) {
    refresh()
    onChange(contact)
    setSelected(contact)
  }

  return (
    <>
      <SelectInput
        id={apiDtoIds.contacts}
        data={data}
        value={selected ?? undefined}
        loading={customerId ? loading : false}
        refresh={customerId ? refresh : undefined}
        onSearchChange={setSearch}
        title={title ?? i18n.t('CONTACT')}
        screenTitle={i18n.t('CONTACTS')}
        noDataMessage={noDataMessage ?? i18n.t('NO_CONTACT_FOUND')}
        canBeOpenedIfNoData
        renderItem={render}
        onChange={handleOnChange}
        renderSelectedString={item => customerUtils.getContactInfo(item).contactText ?? ''}
        allDataLoaded={allDataLoaded}
        onLoadMore={loadMore}
        disableLoadAfterMount
        disableFastInput={disableFastInput}
        onCreateNew={canCreateNew ? () => createContactModal.show(undefined) : undefined}
        {...props}
      />
      <CustomerContactCreationModal controller={createContactModal} customerId={customerId} onContactCreated={handleContactCreated} />
    </>
  )
}
