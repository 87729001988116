import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'
import { StyleSheet } from 'react-native'

import { AppStyle } from '../constants/Styles'
import useOnLayout from '../hooks/useOnLayout'
import { ThemeColorExpanded } from '../types'
import PressableTextIcon from './Infominds/PressableTextIcon'
import LoadingIcon from './LoadingIcon'

interface Props {
  title: string
  onPress: () => void
  isBeingDeleted?: boolean
  disabled?: boolean
}

export default function DeleteButton({ title, onPress, isBeingDeleted, disabled }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { layout, onLayout } = useOnLayout()

  return (
    <>
      {isBeingDeleted && (
        <IM.View
          style={[
            { width: layout?.width, height: layout?.height },
            AppStyle.center,
            { backgroundColor: Color(theme.header.main.error).fade(0.9).toString(), borderRadius: IMLayout.borderRadius },
          ]}>
          <LoadingIcon color={theme.header.main.error} />
        </IM.View>
      )}
      {!isBeingDeleted && (
        <IM.View onLayout={onLayout} style={AppStyle.justifyContentCenter}>
          <PressableTextIcon
            disabled={disabled}
            icon={['fal', 'trash']}
            color={disabled ? theme.button.disabledBackground : theme.header.main.error}
            iconSize={16}
            pressableStyle={[styles.pressableStyle, AppStyle.center]}
            containerStyle={[
              {
                backgroundColor: Color(disabled ? theme.button.disabledBackground : theme.header.main.error)
                  .fade(0.9)
                  .toString(),
                borderRadius: IMLayout.borderRadius,
              },
              AppStyle.center,
            ]}
            onPress={onPress}>
            {title.toUpperCase()}
          </PressableTextIcon>
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  pressableStyle: {
    width: '100%',
  },
})
