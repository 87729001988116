import { IM, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { Platform } from 'react-native'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'

export default function ModalContentWrapper({ children }: PropsWithChildren) {
  const { colorScheme } = useTheme()

  return (
    <SafeAreaProvider
      initialMetrics={initialWindowMetrics}
      style={Platform.select({ web: { colorScheme: colorScheme === 'dark' ? 'dark' : 'light', height: '100%' } })}>
      <IM.AlertProvider useNewModalComponent>{children}</IM.AlertProvider>
    </SafeAreaProvider>
  )
}
