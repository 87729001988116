import { DateUtils, useEvent, useLanguage } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import { useEffect, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { ActivityGroupBy, GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { DataFilterConfig } from '../../types'
import TimeUtils from '../../utils/TimeUtils'
import useUserSettings from '../useUserSettings'

type Props = {
  enabled?: boolean
  states?: ActivityState[]
  assignedMode?: GetActivityRequestAssignedUser
  filter?: GetActivityRequest
  searchText?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterConfig?: Pick<DataFilterConfig<Activity, any, GetActivityRequest>, 'group'>
  chunkSize?: number
  dontResetOnLoad?: boolean
}

export default function useActivities({ enabled, states, assignedMode, filter, searchText, filterConfig, chunkSize, dontResetOnLoad }: Props) {
  const { language } = useLanguage()
  const { taskType } = useUserSettings()

  const isFocused = useIsFocused()

  const {
    item: activities,
    loadItem: loadActivities,
    loadMore: loadMoreActivities,
    setItem: setActivities,
    loading: loadingActivities,
    lastSuccessfulRequest: lastRequest,
    reloadInBackground,
  } = useInfiniteLoader(api.activities.getList, {
    backgroundUpdaterInterval: enabled ? CONSTANTS.backgroundUpdateInterval : undefined,
    chuckSize: chunkSize ?? CONSTANTS.activityChunkSize,
    dontResetOnLoad: dontResetOnLoad,
  })

  useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED }, reload)
  useEvent({ key: EVENT_KEYS.ACTIVITY_ASSIGNED }, reload)

  useEffect(() => {
    reload()
  }, [enabled, assignedMode, searchText, filter])

  useEffect(() => {
    if (isFocused) reloadInBackground()
  }, [isFocused])

  function reload() {
    if (!enabled) {
      setActivities([])
      return
    }
    loadActivities({
      state: states,
      assignedUser: assignedMode,
      searchText,
      ...filter,
      taskType,
    })
  }

  /**
   * activities with group title
   */
  const groupedActivities = useMemo<(string | Activity)[]>(() => {
    if (!filterConfig || !lastRequest?.groupBy) return activities
    const [_k, _t, options] = filterConfig.group.config.find(([_1, _2, o]) => o?.apiRequestValue === lastRequest.groupBy) ?? []

    if (!options?.apiRequestValue) return activities

    const result: (string | Activity)[] = []

    activities.forEach(activity => {
      const lastItem = result.at(-1)
      const lastTitle = result.filter(r => typeof r === 'string').at(-1)
      let title = ''
      switch (lastRequest.groupBy) {
        case ActivityGroupBy.Task:
          title = activity.taskDescription ?? activity.taskId?.toString() ?? ''
          break
        case ActivityGroupBy.ActivityType:
          title = activity.activityTypeDescription ?? activity.srvActivityTypeId.toString()
          break
        case ActivityGroupBy.PlanDate:
          title = activity.planDate ? TimeUtils.format(DateUtils.dateify(activity.planDate), language) : ''
          break
      }
      if (!!title && (!lastItem || !lastTitle || lastTitle !== title)) {
        result.push(title)
      }

      result.push(activity)
    })

    return result
  }, [activities, lastRequest])

  return { activities, loadActivities: reload, loadMoreActivities, loadingActivities, setActivities, groupedActivities }
}
