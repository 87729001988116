import TimeUtils from './TimeUtils'

export const ValidationUtils = {
  timeSpan(from: string, to: string) {
    return TimeUtils.timeSpanToMinutes(from) < TimeUtils.timeSpanToMinutes(to)
  },
  dateLT(date: Date | string | undefined, target: Date | string | undefined) {
    return !date || !target || new Date(date) < new Date(target)
  },
  number(value: number | undefined, options?: { minValue?: number; maxValue?: number; allowNegative?: boolean; allowZero?: boolean }) {
    if (value === undefined) return true
    if (value === 0 && !options?.allowZero) return false
    if (value < 0 && !options?.allowNegative) return false
    if (options?.minValue && value < options.minValue) return false
    if (options?.maxValue && value < options.maxValue) return false
    return true
  },
  isRequired<T extends string = string>(field: T, requiredFields: (T | T[])[]) {
    return requiredFields.some(value => (typeof value === 'string' ? field === value : value.includes(field)))
  },
} as const
