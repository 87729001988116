import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Language, ModalController, ThemeButton, ThemeCard, ThemeChip, ThemeColorsType, ThemePressable } from '@infominds/react-native-components'
import { LiteAsset } from '@infominds/react-native-media-lite'
import { Dispatch, SetStateAction } from 'react'
import { EventItem, Resource } from 'react-big-scheduler-stch'
import { ColorSchemeName, TextInput } from 'react-native'
import { FileContent } from 'use-file-picker/dist/interfaces'

import { ActivityClosingRequest } from './apis/types/apiRequestTypes'
import {
  Activity,
  ActivityArticle,
  ActivityEmployee,
  ActivityPlanTime,
  ActivityTime,
  ActivityTravel,
  Contact,
  ContractObjectLot,
  Destination,
  Employee,
  Technician,
  UnavailableTypeDefinition,
  UserRole,
} from './apis/types/apiResponseTypes'
import { translation } from './assets/languages/i18next'
import { DetailHeaderType } from './components/screen/types'

export type i18nKey = keyof typeof translation.en
export type InferArrayType<T> = T extends Array<infer E> ? E : T
export type DropdownItems<T> = { label: string; value: T }

export type LanguageSelectType<T> = Record<Language, T>
export type SupportedLanguage = { label: string; value: Language }

export const SupportedLanguages: DropdownItems<Language>[] = [
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
  { label: 'English', value: 'en' },
]
export type UnavailableTypeDescription = { type: UnavailableTypeDefinition; labelKey: i18nKey }
export const SupportedUnavailableTypes: UnavailableTypeDescription[] = [
  { type: UnavailableTypeDefinition.disease, labelKey: 'UNAVAILABLE_TYPE_ILLNESS' },
  { type: UnavailableTypeDefinition.vacation, labelKey: 'UNAVAILABLE_TYPE_VACATION' },
  { type: UnavailableTypeDefinition.constructionSite, labelKey: 'UNAVAILABLE_TYPE_CONSTRUCTION_SITE' },
]

export type ActivityPriority = { id: number; labelKey: string; color?: (theme: ThemeColorExpanded) => string }
export const SupportedActivityPriorities: ActivityPriority[] = [
  { id: 0, labelKey: 'ACTIVITY_PRIORITY_DEFAULT' },
  { id: 1, labelKey: 'ACTIVITY_PRIORITY_HIGH', color: (theme: ThemeColorExpanded) => theme.error },
]

export type AnagraficsTab = 'ActivityType' | 'TimeSlots' | 'Contracts' | 'UnavailableType'

export type ActivityId = Pick<Activity, 'srvActivityId' | 'srvActivityTypeId' | 'srvActivityYear'>

export type GanttChartView = 'day' | 'week' | 'month'

export enum DnDType {
  ACTIVITY = 'Activity',
  ABSENCE = 'Absence',
}

export type ListDisplayMode = 'full' | 'chunk'

export interface ThemeColorExpanded extends ThemeColorsType {
  access: { granted: string; inactive: string; denied: string }
  article: {
    status: {
      red: string
      yellow: string
      green: string
      grey: string
    }
    image: {
      placeholder: string
    }
  }
  backgroundSecondary: string
  button: ThemeButtonExtended
  card: ThemeCardExtended
  chip: ThemeChipExtended
  filterTag: { default: FilterTag; active: FilterTag; header: FilterTag }
  closing: {
    first: string
    second: string
    third: string
    fourth: string
  }
  drag: {
    active: string
  }
  dropdown: { arrow: string }
  gantt: {
    background: string
    border: { primary: string; secondary: string }
    nonWorking: { background: string; text: string }
    selection: string
  }
  general: {
    info: string
    error: string
    warn: string
    word: string
    excel: string
    pdf: string
    image: string
    text: string
    blue: string
  }
  header: {
    main: {
      background: string
      error: string
      text: { primary: string; secondary: string; placeholder: string }
    }
    detail: {
      background: string
      skeletonBackground: string
    }
  }
  inputBox: {
    border: { active: string; disabled: string; error: string }
    background: { active: string; disabled: string }
  }
  item: { selected: string }
  loader: {
    background: string
    border: string
  }
  map: {
    marker: {
      background: string
      unassigned: string
    }
  }
  moreSheet: {
    background: string
    navigationElement: {
      background: string
      selected: string
    }
    indicator: string
  }
  pressable: ThemePressableExtended
  pressableIcon: {
    disabled: string
  }
  radioButton: string
  settings: {
    badge: {
      background: string
    }
  }
  timeline: {
    background: string
  }
  drawerNavigator: {
    background: string
    bar: string
    hover: string
    icon: {
      focused: string
      unFocused: string
    }
  }
  segmentedControl: {
    tint: string
    background: string
  }
  closingSummary: {
    divider: string
    input: {
      background: string
    }
  }
}

interface ThemePressableExtended extends ThemePressable {
  highlight: string
  animatedHighlight: string
}

interface ThemeChipExtended extends ThemeChip {
  border: string
  active: {
    border: string
    background: string
    text: string
  }
  icon: {
    enabled: string
    disabled: string
  }
  installation: string
  removal: string
}

interface ThemeCardExtended extends ThemeCard {
  secondaryBackground: string
  cardBackground: {
    active: string
    selected: string
  }
  accent: {
    active: string
  }
  button: {
    active: string
    inactive: string
    icon: {
      active: string
      inactive: string
    }
  }
}

interface ThemeButtonExtended extends ThemeButton {
  background: string
  disabledBackground: string
  icon: string
  disabledIcon: string
  qualityCheck: string
}

interface FilterTag {
  background: string
  border: string
  text: string
}

export type ScreenStatus = { type: DetailHeaderType; open: boolean }

export type ScreenHeight = {
  detail: number
  header: number
}

export type MenuPreferenceStorage = {
  id: number
  name: string
}

export type LoadingType = 'reloading' | 'hiddenLoading' | 'aborted' | 'init' | 'catched' | false

export type InfiniteLoadingType = LoadingType | 'loadMore'

export type UploadStatus = 'waiting' | 'waitingWithoutPending' | 'uploading' | 'done' | 'mandatoryMissing'

/**
 *   either a key of T or a combined key, where the first element is the separator by which the values of the keys are joined. f.ex ["-",a, b] will result in "object[a]-object[b]"
 */
export type SearchKey<T> = keyof T | [string, ...(keyof T)[]]

export type DataFilterConfig<T, TSub = void, TApi = void> = {
  group: GroupConfig<T, TSub, TApi>
  order: OrderConfig<T, TSub, TApi>
  filter: FilterConfig<T, TSub>
  apiFilter: ApiFilterConfig<TApi>
  searchKeys: SearchKey<T>[]
}

export type OrderConfig<T, T2 = void, TApi = void> = {
  type: DataFilterConfigType.Order
  apiRequestKey?: keyof TApi
  config: Config<T, OrderConfigOptions<T, T2>>
}

export type GroupConfig<T, T2 = void, TApi = void> = {
  type: DataFilterConfigType.Group
  apiRequestKey?: keyof TApi
  config: Config<T, GroupConfigOptions<T, T2>>
}

export type FilterConfig<T, T2 = void> = {
  type: DataFilterConfigType.Filter
  config: Config<T, FilterConfigOptions<T2>>
}

export type ApiFilterConfig<T> = {
  type: DataFilterConfigType.ApiFilter
  config: Config<T, ApiFilterConfigOption>
}

type Config<T, Y> = ([keyof T, i18nKey] | [keyof T, i18nKey, Y])[]

export type OrderConfigOptions<T, TSub = void> = {
  modifier?: 'inverse'
  textKey?: keyof T | LanguageSelectType<keyof T>
  apiRequestValue?: string | number
  textProvider?: (value: unknown) => i18nKey
  isDefault?: boolean
  subObjectKey?: keyof TSub
  subObjectValueProvider?: (subObj: TSub, obj: T) => string | number | undefined | null
}

export type GroupConfigOptions<T, TSub = void> = OrderConfigOptions<T, TSub>

export type FilterConfigOptions<TSub> =
  | DefaultFilterConfigOption
  | BooleanFilterConfigOption
  | ArrayFilterConfigOption<TSub>
  | DateRangeFilterConfigOption

export type DefaultFilterConfigOption = {
  filterType: undefined
}

export type BooleanFilterConfigOption = {
  filterType: 'boolean'
  trueText: i18nKey
  falseText: i18nKey
}

export type ArrayFilterConfigOption<TSub> = {
  filterType: 'array'
  valueExtractor: (el: TSub) => string
}

export type DateRangeFilterConfigOption = {
  filterType: 'dateRange'
}

export enum DataFilterConfigType {
  Group = 'group',
  Order = 'order',
  Filter = 'filter',
  ApiFilter = 'api-filter',
}

export type ApiFilterConfigOption = {
  /**
   * type of filter
   */
  filterType: ApiFilterType
  /**
   * all filters with the same group will be displayed together as one tag
   */
  group?: string
  /**
   * text-prefix. will be added before the text, but only if NO grouping is active
   */
  textPrefix?: string
  /**
   * show only for certain roles
   */
  roles?: UserRole[]
  /**
   * roles to be excluded
   */
  excludeRoles?: UserRole[]
  /**
   * icon to be displayed
   */
  icon?: IconProp
}

export enum ApiFilterType {
  Default = 'default',
  Customer = 'customer',
  ConstructionSite = 'construction-site',
  Lotto = 'lotto',
  ActivityType = 'activity-type',
  Date = 'date',
  TechnicianFull = 'technician-full',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Technician = 'technician',
  InElaboration = 'inElaboration',
  RdaStatus = 'rdaStatus',
}

type CommonDataSorter<T> = {
  id: string
  dataKey: keyof T
  textKey: i18nKey
}

export type FilterDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Filter
  values: DataFilterValue[]
  options?: FilterConfigOptions<TSub>
}

export type GroupDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Group
  active: boolean
  order?: number
  options?: GroupConfigOptions<T, TSub>
  apiRequestKey?: string
}

export type OrderDataSorter<T, TSub = void> = CommonDataSorter<T> & {
  type: DataFilterConfigType.Order
  active: boolean
  order?: number
  options?: OrderConfigOptions<T, TSub>
  apiRequestKey?: string
}

export type ApiFilterDataSorter<T> = CommonDataSorter<T> & {
  type: ApiFilterType
  active: boolean
  value?: string | number | null | boolean
  values?: number[] | string[] | null
  text: string
  group?: string
  textPrefix?: string
  icon?: IconProp
  visible?: boolean
}

export type DataFilterValue = {
  id: string
  value: string
  active?: boolean
  count?: number
  booleanTypeTrueTag?: boolean
  arrayElementId?: number
  isDateRangeMax?: boolean
}

export interface GroupedData<T> {
  key: string | null
  title: string
  data: T[]
}

export interface GroupedApiFilterTag<T> {
  group?: string
  data: ApiFilterDataSorter<T>[]
}

export type ListSection<T> = {
  title?: string
  data: T[]
}

export type DividerType = 'left' | 'right'

export type ModalScreenProps<T> = {
  controller?: ModalController<T>
}

export interface ModalScreenRef {
  handleGoBack: () => void
}

export interface EditOrCreateViewRef {
  handleUpload: () => void
}

export type TextInputRef = TextInput

export interface ContactViewRef {
  openModal: () => void
}

export interface DestinationViewRef {
  openModal: () => void
}

export interface GanttChartRef {
  next: () => void
  previous: () => void
  pick: (date: Date) => void
  remove: (event: EventItem | EventItem[]) => void
  add: (event: EventItem | EventItem[]) => void
  move: (event: EventItem, newSlotId: string, newSlotName: string, newStart: string, newEnd: string) => void
  updateStart: (event: EventItem | EventItem[], newStart: string) => void
  updateEnd: (event: EventItem | EventItem[], newEnd: string) => void
  updateResources: (currentResource: Resource[]) => void
}

export interface SignaturePadRef {
  readSignatures: () => void
  clearSignature: () => void
}

export type Border = 'top' | 'bottom'

export type FiniteLoadList = {
  allDataLoaded?: never
  onLoadMore?: never
}

export type InfiniteLoadList = {
  allDataLoaded: boolean
  onLoadMore: () => void
}

export type ContactEditOrCreateParams = { companyId?: number; contact?: Contact }

export type DestinationEditOrCreateParams = { companyId?: number; destination?: Destination }

export type FilterStorageType = {
  filterId: string
  values: StorageType[]
}

export type StorageType = {
  id: string
  value: string | number | boolean
  text?: string
  isMax: boolean
}

export type TabType = 'contacts' | 'destinations' | 'construction-sites'

export type ApiError = {
  Message: string
  code: string
}

export enum TimeFormat {
  TIME = 'time',
  TIME_WITH_DIMENSIONS = 'timeWithDimensions',
  COUNTER = 'counter',
}

export type TimeOverflowHandling = 'wrap' | 'limit' | 'none'

export interface DateTimePickerProps {
  date: Date
  show: boolean
  mode: 'date' | 'time' | 'datetime'
  minimumDate?: Date
  maximumDate?: Date
  setDate: (newDate: Date) => void
  setShow: Dispatch<SetStateAction<boolean>>
}

export type PlannedActivityEmployee = Partial<ActivityEmployee> & {
  isDeleted?: boolean
  id: string
  technicianDisabled?: boolean
}
export type PlannedActivityEmployeeGroup = { technician: Technician; planedActivityEmployees: PlannedActivityEmployee[]; disabled?: boolean }

export type GanttChartDate = {
  label: string
  date: Date
}

export type InactiveSlots = {
  from: string
  to: string
}

export type ActivityPlan = {
  technician?: Technician
  planDateFrom?: string
  planDateTo?: string
}

export type GroupedActivityTime = {
  date: string
  times: ActivityTime[]
}

export type GroupedActivityTravel = {
  date: string
  travels: ActivityTravel[]
}

export type ContractLotSelection = {
  id: string
  constructionSiteId: number
  lots: Partial<ContractObjectLot>[]
  activity?: ContractActivity
  employees?: Employee[]
}
export type ContractActivity = {
  activity: Partial<Activity>
  repetition: Repetition
  planned?: ActivityPlanTime
}

export type Repetition =
  | {
      type: 'none'
    }
  | { type: 'day'; startDate?: Date; repeatEvery?: number; endDate?: Date; totalRepetitions?: number }
  | { type: 'week'; startDate?: Date; repeatEvery?: number; endDate?: Date; totalRepetitions?: number; weekDays?: number[] }
  | {
      type: 'month'
      startDate?: Date
      repeatEvery?: number
      endDate?: Date
      totalRepetitions?: number
      weekDays?: number[]
      onDayType?: 'numeric' | 'relative'
      onDayRelativeType?: RelativeDayType
      onDay?: number
      onWeekDay?: number
    }
  | {
      type: 'year'
      startDate?: Date
      repeatEvery?: number
      endDate?: Date
      totalRepetitions?: number
      weekDays?: number[]
      onDayType?: 'numeric' | 'relative'
      onDayRelativeType?: RelativeDayType
      onDay?: number
      onWeekDay?: number
      repeatEveryMonth?: number
    }
export type RelativeDayType = 'first' | 2 | 3 | 4 | 'last'
export type RepetitionType = Repetition['type']

export type ArticleSearchMode = 'all' | 'catalogue' | 'history'
export type ArticleSearchModeSelection = {
  mode: ArticleSearchMode
  languageKey: i18nKey
}
export type SignatureInputEvent = {
  id: string
  signature: string
}

export type TimeRecord = {
  id: string
  startTime?: string
  stopTime?: string
  notes?: string
}
export type PostActivityTime = ActivityTime & {
  technicians?: Technician[]
  times?: TimeRecord[]
}

export type ActivityClosingState = ActivityClosingRequest & { totalAmount?: number; totalVatAmount?: number }

export type ReportPdfScreenParams = ActivityId &
  Partial<Pick<Activity, 'title' | 'paidAmount' | 'totalAmount' | 'discount' | 'isWorking'>> &
  Pick<ActivityClosingRequest, 'customerNote' | 'performedWork' | 'signatureCustomer' | 'signatureEmployee'> & {
    isHistorical?: boolean
    pdfName?: string
    disabledShare?: boolean
  }
export type PDFPreviewButtonProps = {
  disabled?: boolean
  activity: Activity
  closingRequest?: Partial<ActivityClosingRequest>
  disabledShare?: boolean
}

export type GalleryUtilsType = {
  launchGallery(
    prevSelectedMedia: LiteAsset[],
    onAccept: Dispatch<SetStateAction<LiteAsset[]>>,
    cancelTextIOS: string,
    openGalleryIOS: string,
    openFilesIOS: string,
    colorScheme: NonNullable<ColorSchemeName>,
    validateAsset?: (asset: LiteAsset) => boolean
  ): Promise<void>
}

export type UseFilePickerReturn = {
  openFilePicker: () => void
  loading: boolean
  filesContent: FileContent<string>[]
  clear: () => void
}

export type ActivityArticleEditScreenProps = {
  activity: Activity
  article: ActivityArticle
  editable?: boolean
}
export type ActivityMediaArticleScreenProps = ActivityId & {
  taskId: number | undefined
  mediaArticleCode: string
  article?: ActivityArticle
  editable?: boolean
}

export type AnagraficsMasterDetailSelection = { id?: AnagraficsTab }

export type MediaPostInputResult = {
  text: string
  assets: LiteAsset[] | undefined
}
