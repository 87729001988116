import { IM, IMLayout, IMStyle, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { memo, useCallback } from 'react'
import { StyleSheet } from 'react-native'

import { ActivityArticle, ErgoTask } from '../../apis/types/apiResponseTypes'
import CheckBox from '../../components/Infominds/CheckBox'
import { AppStyle } from '../../constants/Styles'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useIsRdaEnabled from '../../hooks/useIsRdaEnabled'
import useLayout from '../../hooks/useLayout'
import CardNumberInput from './CardNumberInput'

export type ArticleSelectionCardProps = {
  article: ActivityArticle
  onValueChanged?: (article: ActivityArticle | undefined) => void
  enableQuickAdd?: boolean
  ergoTask?: ErgoTask | null
} & Pick<ViewProps, 'spacing' | 'style'>

function ArticleSelectionCard({ article, onValueChanged, ergoTask, ...viewProps }: ArticleSelectionCardProps) {
  const { theme } = useExtendedTheme()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const unit = article.measureUnit?.description
  const rda = useIsRdaEnabled(ergoTask)

  // value is saved as separate state to optimize performance due to reduced re-rendering
  const value = article.quantity
  const displayValue = value === 0 ? undefined : value
  const availability = article.actStock

  function updateSelectedArticles(newValue?: number, increment?: number) {
    if (newValue !== undefined) {
      onValueChanged?.({ ...article, quantity: newValue })
    } else if (increment) {
      onValueChanged?.({ ...article, quantity: Math.max((article.quantity ?? 0) + increment, 0) })
    }
  }

  const Availability = useCallback(
    () =>
      availability ? (
        <IM.View style={[AppStyle.justifyContentCenter]}>
          <IM.TextWithIcon icon={['fal', 'warehouse-full']} secondary numberOfLines={2}>
            {availability}
          </IM.TextWithIcon>
        </IM.View>
      ) : (
        <></>
      ),
    [availability]
  )

  return (
    <IM.View {...viewProps}>
      <IM.CardBasic>
        <IM.View style={[IMLayout.flex.row, AppStyle.justifyContentCenter]}>
          {rda && (
            <IM.View style={[AppStyle.center, IMLayout.flex.row, { paddingLeft: IMLayout.horizontalMargin }]}>
              <IM.View style={[AppStyle.center]}>
                <IM.View>
                  <IM.Text>{i18n.t('RDA')}</IM.Text>
                </IM.View>
                <IM.View>
                  <CheckBox
                    value={article.isRda}
                    onChange={q => onValueChanged?.({ ...article, isRda: !!q.nativeEvent.value })}
                    color={IMStyle.palette.tint}
                    disabled={!!article.srvActivityArticleId}
                  />
                </IM.View>
              </IM.View>
            </IM.View>
          )}
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.content, { borderColor: theme.inputBox.border.active }]}>
            <IM.View style={[IMLayout.flex.f1, AppStyle.justifyContentCenter]} spacing={'left'}>
              <IM.View>
                <IM.Text primary numberOfLines={1}>
                  {article.articleId}
                </IM.Text>
                <IM.Text secondary numberOfLines={2}>
                  {article.description}
                </IM.Text>
                {isSmallDevice && <Availability />}
              </IM.View>
            </IM.View>
            {!isSmallDevice && <Availability />}
            {!!unit && (
              <IM.View style={AppStyle.center} spacing={'horizontal'}>
                <IM.Text>{unit}</IM.Text>
              </IM.View>
            )}
          </IM.View>
          <CardNumberInput
            value={displayValue}
            onChangeValue={v => updateSelectedArticles(v)}
            onIncrement={inc => updateSelectedArticles(undefined, inc)}
            minValue={0}
          />
        </IM.View>
      </IM.CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  content: { borderTopLeftRadius: IMLayout.borderRadius, borderBottomLeftRadius: IMLayout.borderRadius, paddingVertical: 6 },
})

export default memo(ArticleSelectionCard)
