import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { createRef, memo } from 'react'
import { DnDSource, EventItem, SchedulerData } from 'react-big-scheduler-stch'
import { StyleSheet } from 'react-native'
import { useRecoilState, useRecoilValue } from 'recoil'

import ChartFilter from '../../components/gantt/ChartFilter'
import TabSelector from '../../components/TabSelector'
import Text from '../../components/Text'
import { PlanningAbsenceFilterConfig, PlanningActivityFilterConfig } from '../../constants/Filters'
import { FilterProvider } from '../../contexts/FilterContext'
import { ThemeColorExpanded } from '../../types'
import {
  plannerAbsenceFilterCountAtom,
  plannerActivityFilterCountAtom,
  plannerActivitySearchFilterCountAtom,
  plannerSideViewTypeAtom,
} from '../../utils/stateManager'
import AbsencesToBePlannedView from './AbsencesToBePlannedView'
import ActivitiesSearchView from './ActivitiesSearchView'
import ActivitiesToBePlannedView from './ActivitiesToBePlannedView'

const styles = StyleSheet.create({
  title: {
    marginLeft: IMLayout.horizontalMargin,
    marginRight: IMLayout.horizontalMargin,
    marginTop: IMLayout.horizontalMargin,
    marginBottom: 4,
  },
  filterContainer: { alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'center' },
  item: { paddingHorizontal: 4 },
  tab: { marginVertical: 4, marginHorizontal: 4 },
})

export type PlannerSideViewRef = { openFilterModal: () => void }

interface Props {
  schedulerData: SchedulerData<EventItem> | undefined
  taskDndSource: DnDSource
  absencesDndSource: DnDSource
  companyId: number | undefined
  objectId: number | undefined
  newEvent: (schedulerData: SchedulerData, slotId: string, slotName: string, start: string, end: string, type: string, item: EventItem) => void
  onDatePick: (date: Date) => void
}

export type PlannerSideViewType = 'activities' | 'unavailables' | 'search'

const PlannerSideView = memo(function PlannerSideView({
  schedulerData,
  companyId,
  objectId,
  taskDndSource,
  absencesDndSource,
  newEvent,
  onDatePick,
}: Props) {
  const { i18n } = useLanguage()
  const { sessionKey } = useAuthentication()
  const { theme } = useTheme<ThemeColorExpanded>()

  const activityViewRef = createRef<PlannerSideViewRef>()
  const activitySearchViewRef = createRef<PlannerSideViewRef>()
  const absenceViewRef = createRef<PlannerSideViewRef>()

  const absenceFilterCount = useRecoilValue(plannerAbsenceFilterCountAtom)
  const activityFilterCount = useRecoilValue(plannerActivityFilterCountAtom)
  const activitySearchFilterCount = useRecoilValue(plannerActivitySearchFilterCountAtom)
  const [sideViewType, setSideViewType] = useRecoilState(plannerSideViewTypeAtom(sessionKey))

  const filterCount =
    sideViewType === 'activities' ? activityFilterCount : sideViewType === 'unavailables' ? absenceFilterCount : activitySearchFilterCount
  const activitiesListHeight = sideViewType === 'activities' ? undefined : 0
  const absencesListHeight = sideViewType === 'unavailables' ? undefined : 0
  const activitySearchListHeight = sideViewType === 'search' ? undefined : 0

  return (
    <>
      <IM.View style={IMLayout.flex.f1}>
        <IM.View style={[styles.title, IMLayout.flex.row]}>
          <IM.View style={IMLayout.flex.f1}>
            <Text style={{ fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightMedium }}>
              {sideViewType === 'activities'
                ? i18n.t('ACTIVITIES_TO_BE_PLANNED')
                : sideViewType === 'unavailables'
                  ? i18n.t('UNAVAILABLE_TYPES')
                  : i18n.t('SEARCH_PLACEHOLDER')}
            </Text>
            <Text secondary>
              {sideViewType === 'activities'
                ? i18n.t('ACTIVITIES_TO_BE_PLANNED_EXPLANATION')
                : sideViewType === 'unavailables'
                  ? i18n.t('UNAVAILABLE_TO_BE_PLANNED_EXPLANATION')
                  : i18n.t('ACTIVITIES_SEARCH_EXPLANATION')}
            </Text>
          </IM.View>
          <IM.View style={styles.filterContainer}>
            <ChartFilter
              count={filterCount ?? 0}
              badgeStyle={{ boxShadow: `0 0 0 1px ${theme.background}` }}
              onClick={() =>
                sideViewType === 'activities'
                  ? activityViewRef.current?.openFilterModal()
                  : sideViewType === 'unavailables'
                    ? absenceViewRef.current?.openFilterModal()
                    : activitySearchViewRef.current?.openFilterModal()
              }
              disabled={filterCount === undefined}
            />

            <TabSelector height={26} style={styles.tab}>
              <TabSelector.Item
                icon={['fal', 'screwdriver-wrench']}
                selected={sideViewType === 'activities'}
                onPress={() => setSideViewType('activities')}
                iconsStyle={styles.item}
                selectionColor={theme.header.main.background}
              />
              <TabSelector.Item
                icon={['fal', 'island-tropical']}
                selected={sideViewType === 'unavailables'}
                onPress={() => setSideViewType('unavailables')}
                iconsStyle={styles.item}
                selectionColor={theme.header.main.background}
              />
              <TabSelector.Item
                icon={['fal', 'magnifying-glass']}
                selected={sideViewType === 'search'}
                onPress={() => setSideViewType('search')}
                disableDivider
                iconsStyle={styles.item}
                selectionColor={theme.header.main.background}
              />
            </TabSelector>
          </IM.View>
        </IM.View>
        <FilterProvider storageKeyUniqueId="ActivityPlanningStack" filterConfig={PlanningActivityFilterConfig.filter}>
          <ActivitiesToBePlannedView
            ref={activityViewRef}
            height={activitiesListHeight}
            taskDndSource={taskDndSource}
            schedulerData={schedulerData}
            newEvent={newEvent}
            showCreateActivityButton={activitiesListHeight === undefined}
            companyId={companyId}
            objectId={objectId}
          />
        </FilterProvider>
        <FilterProvider storageKeyUniqueId="AbsencePlanningStack" filterConfig={PlanningAbsenceFilterConfig.filter}>
          <AbsencesToBePlannedView
            ref={absenceViewRef}
            height={absencesListHeight}
            absencesDndSource={absencesDndSource}
            schedulerData={schedulerData}
            newEvent={newEvent}
          />
        </FilterProvider>
        <FilterProvider storageKeyUniqueId="ActivitySearchPlanningStack" filterConfig={PlanningActivityFilterConfig.filter}>
          <ActivitiesSearchView
            ref={activitySearchViewRef}
            height={activitySearchListHeight}
            onDatePick={onDatePick}
            companyId={companyId}
            objectId={objectId}
          />
        </FilterProvider>
      </IM.View>
    </>
  )
})

export default PlannerSideView
