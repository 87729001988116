import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'

import ActivityDetailArticleCard from '../../cards/activities/detail/ActivityDetailArticleCard'
import ActivityDetailConstructionSiteContactIdCard from '../../cards/activities/detail/ActivityDetailConstructionSiteContactIdCard'
import ActivityDetailConstructionSiteIdCard from '../../cards/activities/detail/ActivityDetailConstructionSiteIdCard'
import ActivityDetailContactIdCard from '../../cards/activities/detail/ActivityDetailContactIdCard'
import ActivityDetailCustomerIdCard from '../../cards/activities/detail/ActivityDetailCustomerIdCard'
import ActivityDetailDestinationIdCard from '../../cards/activities/detail/ActivityDetailDestinationIdCard'
import ActivityDetailDeviceCard from '../../cards/activities/detail/ActivityDetailDeviceCard'
import ActivityDetailNotesCard from '../../cards/activities/detail/ActivityDetailNotesCard'
import ActivityDetailTimesCard from '../../cards/activities/detail/ActivityDetailTimesCard'
import ActivityDetailTravelCard from '../../cards/activities/detail/ActivityDetailTravelCard'
import ActivityButton from '../../components/ActivityButton'
import DynamicView from '../../components/Infominds/DynamicView'
import KeyboardAwareIOS from '../../components/KeyboardAwareIOS'
import ListSpacer from '../../components/ListSpacer'
import ScrollViewData from '../../components/ScrollViewData'
import { useReportActivity } from '../../contexts/report/ReportActivityContext'
import useActivityDetail from '../../hooks/activity/useActivityDetail'
import useLayout from '../../hooks/useLayout'
import { ReportStackParamList } from '../../navigation/types'

export default function NewReportView() {
  const { i18n } = useLanguage()
  const navigation = useNavigation<NavigationProp<ReportStackParamList>>()

  const { isLargeDevice, isMediumDevice } = useLayout(true)
  const { activity, isNewActivity, setIsNewActivity } = useReportActivity()
  const { detail, refreshDetail, loadingDetail, dataCount } = useActivityDetail(activity)
  const allowEdit = true

  useEffect(() => {
    if (detail && isNewActivity && loadingDetail === false) {
      setTimeout(() => setIsNewActivity(false), 1000)
    }
  }, [detail, loadingDetail])

  const showContactCard = !!detail?.contactId
  const showConstructionSiteContactCard = !!detail?.objectContactId
  const showDestinationCard = !!detail?.destinationId
  const showConstructionSiteCard = !!detail?.objectId || !!detail?.objectLotId

  return (
    <KeyboardAwareIOS>
      <ScrollViewData refresh={refreshDetail} loading={loadingDetail}>
        {detail && !loadingDetail && (
          <>
            <DynamicView rowStyle={{ gap: IMLayout.horizontalMargin }}>
              <IM.View style={IMLayout.flex.f6}>
                <ActivityDetailCustomerIdCard spacing="bottom" style={styles.grow} />
              </IM.View>

              {showContactCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailContactIdCard spacing="bottom" style={styles.grow} />
                </IM.View>
              )}

              {showDestinationCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailDestinationIdCard spacing="bottom" style={styles.grow} />
                </IM.View>
              )}
              {showConstructionSiteCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailConstructionSiteIdCard spacing="bottom" style={styles.grow} />
                </IM.View>
              )}
              {showConstructionSiteContactCard && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailConstructionSiteContactIdCard spacing="bottom" style={styles.grow} />
                </IM.View>
              )}

              {detail.device && (
                <IM.View style={IMLayout.flex.f6}>
                  <ActivityDetailDeviceCard style={IMLayout.flex.f1} spacing={'bottom'} device={detail.device} />
                </IM.View>
              )}
            </DynamicView>
            <DynamicView flexLimitLargeDevice={isMediumDevice ? 6 : 12} rowStyle={{ gap: IMLayout.horizontalMargin * 2 }}>
              <IM.View style={IMLayout.flex.f6}>
                <ActivityDetailArticleCard spacing={'bottom'} enableChange={allowEdit} dontLoadOnMount={isNewActivity} />
                {isLargeDevice && <ActivityDetailTravelCard spacing={'bottom'} enableChange={allowEdit} dontLoadOnMount={isNewActivity} />}
                {!isLargeDevice && <ActivityDetailTimesCard spacing={'bottom'} enableChange={allowEdit} dontLoadOnMount={isNewActivity} />}
              </IM.View>
              <IM.View style={IMLayout.flex.f6}>
                {isLargeDevice && <ActivityDetailTimesCard spacing={'bottom'} enableChange={allowEdit} dontLoadOnMount={isNewActivity} />}
                {!isLargeDevice && <ActivityDetailTravelCard spacing={'bottom'} enableChange={allowEdit} dontLoadOnMount={isNewActivity} />}
                <ActivityDetailNotesCard spacing="bottom" style={[styles.notesCard]} enableChange={allowEdit} dontLoadOnMount={isNewActivity} />
              </IM.View>
            </DynamicView>

            <IM.View style={[IMLayout.flex.row, styles.closingButtonsView]} spacing={'bottom'}>
              <ActivityButton
                title={i18n.t('CONTINUE')}
                onPress={() => activity && navigation.navigate('ReportClosing')}
                icon={['fas', 'check']}
                disabled={!dataCount.articles && !dataCount.notes && !dataCount.times && !dataCount.travels}
              />
            </IM.View>

            <ListSpacer />
          </>
        )}
      </ScrollViewData>
    </KeyboardAwareIOS>
  )
}

const styles = StyleSheet.create({
  closingButtonsView: {
    justifyContent: 'flex-end',
    gap: IMLayout.horizontalMargin * 2,
  },

  notesCard: {
    flexShrink: 1,
  },
  grow: {
    flexGrow: 1,
  },
})
