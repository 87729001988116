import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { i18nKey, ListSection } from '../../types'
import RadioPressable from '../RadioPressable'
import SelectInput, { SelectInputProps } from './selectInput/SelectInput'

type Props = {
  value: number | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: number | undefined) => void
} & Pick<SelectInputProps<ContactType>, 'required' | 'disableFastInput' | 'title' | 'placeholder'>

type ContactType = {
  id: number
  labelKey: i18nKey
}

export const SupportedGenders: ContactType[] = [
  { id: 0, labelKey: 'CONTACT_TYPE_GENERAL' },
  { id: 1, labelKey: 'CONTACT_TYPE_BUY' },
  { id: 2, labelKey: 'CONTACT_TYPE_SALES' },
]

export default function ContactTypeSelector({ value, onChange, title, ...props }: Props) {
  const { i18n } = useLanguage()

  const gender = useMemo(() => SupportedGenders.find(g => g.id === value), [value])

  const render = ({ item }: SectionListRenderItemInfo<ContactType, ListSection<ContactType>>, onPress?: () => void) => {
    return <RadioPressable key={item.id} onPress={onPress} selected={item.id === value} text={i18n.t(item.labelKey)} />
  }

  const onPressed = (newGender: ContactType | undefined) => {
    onChange(newGender?.id)
  }

  return (
    <>
      <SelectInput
        id={['id']}
        data={SupportedGenders ?? []}
        value={gender}
        loading={false}
        title={title ?? i18n.t('CONTACT_TYPE')}
        screenTitle={title ?? i18n.t('CONTACT_TYPE')}
        noDataMessage={''}
        renderItem={render}
        onChange={onPressed}
        renderSelectedString={item => i18n.t(item.labelKey)}
        disableLoadAfterMount
        hideNoSelectionItem
        {...props}
      />
    </>
  )
}
