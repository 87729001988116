import { CheckboxValueType } from 'antd/es/checkbox/Group'
import dayjs from 'dayjs'
import { CellUnit, DATETIME_FORMAT, EventItem, Resource, SchedulerData } from 'react-big-scheduler-stch'
import { ColorSchemeName } from 'react-native'

import { Activity, ActivityEmployee, Technician, TechnicianType, TimeSlot, Unavailable } from '../apis/types/apiResponseTypes'
import { USER_COLORS } from '../constants/Colors'
import { DnDType, GanttChartView, InactiveSlots } from '../types'
import { activityUtils } from './ActivityUtils'
import { imDateUtils } from './infominds/imDateUtils'
import TimeUtils from './TimeUtils'

export const chartUtils = {
  isNonWorkingTimeFunc: ({ cellUnit }: SchedulerData, time: string, inactiveSections: InactiveSlots[] | undefined) => {
    if (cellUnit === CellUnit.Hour) {
      const hour = dayjs(new Date(time)).hour()
      const minute = dayjs(new Date(time)).minute()

      let toRet = false
      inactiveSections?.forEach(inactive => {
        const [fromH, fromM] = inactive.from.split(':')
        const [toH, toM] = inactive.to.split(':')

        const result = dayjs(`2024-01-15 ${hour}:${minute}`).isBetween(`2024-01-15 ${fromH}:${fromM}`, `2024-01-15 ${toH}:${toM}`, 'minute', '[)')

        if (result) {
          toRet = true
        }
      })

      return toRet
    }
    const date = new Date(time)
    const dayOfWeek = dayjs(date).isoWeekday()
    return dayOfWeek === 6 || dayOfWeek === 7
  },
  prepareEvents: (evn: EventItem[]) => evn.sort((a, b) => (dayjs(a.start).isAfter(dayjs(b.start)) ? 1 : -1)),
  createInactiveTimeRanges: (activityTimeSlots: TimeSlot[] | undefined) => {
    if (!activityTimeSlots) return undefined
    if (activityTimeSlots.length === 0) {
      return { start: '00:00', end: '23:59', inactiveSlots: [] }
    }

    const activityTimeSlotsSorted = activityTimeSlots.sort((a, b) => {
      if (!a.hourFrom || !b.hourFrom) return 0

      const aFrom = a.hourFrom.replaceAll(':', '')
      const bFrom = b.hourFrom.replaceAll(':', '')

      return parseInt(aFrom, 10) - parseInt(bFrom, 10)
    })

    let extraElement: ({ secondsFrom: number; secondsTo: number } & TimeSlot) | undefined

    const times: ({ secondsFrom: number; secondsTo: number } & TimeSlot)[] = activityTimeSlotsSorted.map(el => {
      if (!el.hourFrom || !el.hourTo) return { ...el, secondsFrom: 0, secondsTo: 0 }

      const [hoursFrom, minutesFrom] = el.hourFrom.split(':')
      const [hoursTo, minutesTo] = el.hourTo.split(':')

      const secondsFrom = parseInt(hoursFrom, 10) * 3600 + parseInt(minutesFrom, 10) * 60
      const secondsTo = parseInt(hoursTo, 10) * 3600 + parseInt(minutesTo, 10) * 60

      if (secondsFrom > secondsTo) {
        extraElement = { ...el, hourFrom: '00:00:00', secondsFrom: 0, secondsTo }
        return {
          ...el,
          hourTo: '23:59:00',
          secondsFrom,
          secondsTo: 23 * 3600 + 59 * 60,
        }
      } else {
        return {
          ...el,
          secondsFrom,
          secondsTo,
        }
      }
    })
    extraElement && times.unshift(extraElement)

    const inactiveTimeRanges: { from: string; to: string }[] = []

    times.forEach((el, index) => {
      if (index === 0 && el.secondsFrom !== 0) {
        inactiveTimeRanges.push({ from: '00:00', to: TimeUtils.formatSeconds(el.secondsFrom, undefined, true) })
      } else {
        if (index === 0) return
        const prevIndex = index - 1

        const difference = el.secondsFrom - times[prevIndex].secondsTo

        if (difference !== 0) {
          inactiveTimeRanges.push({
            from: TimeUtils.formatSeconds(times[prevIndex].secondsTo, undefined, true),
            to: TimeUtils.formatSeconds(el.secondsFrom, undefined, true),
          })
        }

        if (index === times.length - 1 && times[index].secondsTo !== 0 && times[index].secondsTo - (23 * 3600 + 59 * 60) !== 0) {
          inactiveTimeRanges.push({
            from: TimeUtils.formatSeconds(times[index].secondsTo, undefined, true),
            to: '00:00',
          })
        }
      }
    })

    const toRet: InactiveSlots[] = []

    inactiveTimeRanges.forEach(inactiveRange => {
      toRet.push({
        from: inactiveRange.from,
        to: inactiveRange.to,
      })
    })

    const lastTime = activityTimeSlotsSorted[activityTimeSlotsSorted.length - 1].hourTo

    if (!lastTime) return undefined

    const [hours, minutes] = lastTime.split(':')

    return {
      start: activityTimeSlotsSorted[0].hourFrom ?? '00:00',
      end: parseInt(hours, 10) === 0 && parseInt(minutes, 10) === 0 ? '23:59' : lastTime,
      inactiveSlots: toRet,
    }
  },
  createActivityId: (obj: Pick<ActivityEmployee, 'srvActivityId' | 'srvActivityYear' | 'srvActivityEmployeeId' | 'srvActivityTypeId'>) => {
    return parseInt(`${obj.srvActivityYear}${obj.srvActivityEmployeeId}${obj.srvActivityTypeId}${obj.srvActivityId}`, 10)
  },
  createUnavailableId: (obj: Pick<Unavailable, 'srvUnavailableId' | 'srvUnavailableTypeId'>) => {
    return parseInt(`${obj.srvUnavailableId}${obj.srvUnavailableTypeId}`, 10)
  },
  createEvents: (
    events: Array<ActivityEmployee | Unavailable> | undefined,
    colorScheme: NonNullable<ColorSchemeName>,
    view: GanttChartView,
    date: Date,
    showClosed: boolean
  ) => {
    if (!events) return undefined
    const toRet: EventItem[] = []

    events.forEach(event => {
      let created: EventItem | undefined

      if ((event as Unavailable).srvUnavailableId !== undefined) {
        created = chartUtils.createUnavailableEventItem(event as Unavailable, view, date)
      } else {
        created = chartUtils.createActivityEventItem(event as ActivityEmployee, colorScheme, view, date, showClosed)
      }

      created && toRet.push(created)
    })

    return chartUtils.prepareEvents(toRet)
  },
  getActivityBlockColor: (colorScheme: NonNullable<ColorSchemeName>) => (colorScheme === 'light' ? '#CACACA' : '#404040'),
  createActivityEventItem: (
    activity: Pick<
      ActivityEmployee,
      | 'srvActivityId'
      | 'srvActivityYear'
      | 'srvActivityEmployeeId'
      | 'srvActivityTypeId'
      | 'state'
      | 'planDateFrom'
      | 'planDateTo'
      | 'technician'
      | 'title'
      | 'srvContractId'
      | 'planColor'
      | 'objectId'
      | 'constructionSiteDescription'
      | 'customerDescription'
      | 'connectedActivity'
      | 'inElaboration'
    >,
    colorScheme: NonNullable<ColorSchemeName>,
    view: GanttChartView,
    date: Date,
    showClosed: boolean
  ) => {
    let toRet: EventItem | undefined

    const id = chartUtils.createActivityId(activity)
    const activityOpen = !activityUtils.isActivityClosed(activity)
    const blockedColor = chartUtils.getActivityBlockColor(colorScheme)

    if (showClosed === false && !activityOpen) return undefined

    if (activity.planDateFrom && activity.planDateTo && activity.technician) {
      const fromInView = chartUtils.isDateInView(activity.planDateFrom, view, date)
      const endInView = chartUtils.isDateInView(activity.planDateTo, view, date)
      const secondaryColor = USER_COLORS.find(el => el.primary === activity.planColor)?.secondary

      toRet = {
        id,
        start: dayjs(activity.planDateFrom).format(DATETIME_FORMAT),
        end: dayjs(activity.planDateTo).format(DATETIME_FORMAT),
        resourceId: chartUtils.createTechnicianIdentifier(activity.technician),
        title: activity.title ?? '',
        srvContractId: activity.srvContractId,
        bgColor: activityOpen ? activity.planColor : blockedColor,
        showPopover: true,
        resizable: activityOpen,
        endResizable: activityOpen && endInView,
        movable: activityOpen,
        startResizable: activityOpen && fromInView,
        subtitle: activity.constructionSiteDescription ?? activity.customerDescription,
        objectId: activity.objectId,
        borderColor: activityOpen ? secondaryColor : blockedColor,
        srvActivityId: activity.srvActivityId,
        srvActivityYear: activity.srvActivityYear,
        srvActivityTypeId: activity.srvActivityTypeId,
        srvActivityEmployeeId: activity.srvActivityEmployeeId,
        state: activity.state,
        type: DnDType.ACTIVITY,
        connectedActivity: activity.connectedActivity,
        inElaboration: activity.inElaboration,
      }
    }

    return toRet
  },
  createUnavailableEventItem: (unavailable: Unavailable, view: GanttChartView, date: Date) => {
    let toRet: EventItem | undefined
    if (unavailable.fromDate && unavailable.toDate && unavailable.technicianId) {
      const fromInView = chartUtils.isDateInView(unavailable.fromDate, view, date)
      const endInView = chartUtils.isDateInView(unavailable.toDate, view, date)
      const secondaryColor = USER_COLORS.find(el => el.primary.toUpperCase() === unavailable.unavailableTypePlanColor.toUpperCase())?.secondary

      toRet = {
        id: chartUtils.createUnavailableId(unavailable),
        start: dayjs(unavailable.fromDate).format(DATETIME_FORMAT),
        end: dayjs(unavailable.toDate).format(DATETIME_FORMAT),
        resourceId: chartUtils.createTechnicianIdentifier({ id: unavailable.technicianId, technicianType: unavailable.technicianType ?? 'employee' }),
        title: unavailable.unavailableTypeTitle,
        subtitle: unavailable.unavailableTypeNote,
        bgColor: unavailable.unavailableTypePlanColor,
        borderColor: secondaryColor,
        showPopover: true,
        objectId: unavailable.objectId,
        srvUnavailableId: unavailable.srvUnavailableId,
        srvUnavailableTypeId: unavailable.srvUnavailableTypeId,
        type: DnDType.ABSENCE,
        endResizable: endInView,
        startResizable: fromInView,
      }
    }

    return toRet
  },
  isDateInView: (dateToCheck: string, view: GanttChartView, dateView: Date) => {
    return dayjs(dateToCheck).isSame(dateView, view)
  },
  prepareResources: (resources: Resource[], resourceCheckboxSelected: CheckboxValueType[] | undefined) => {
    const selectedResource: Resource[] = []

    resourceCheckboxSelected?.forEach(selected => {
      const found = resources.find(el => el.id === selected)

      if (found) {
        selectedResource.push(found)
      }
    })

    return selectedResource
  },
  createTechnicianIdentifier: (technician: Technician) => {
    return technician.id.toString() + `#${chartUtils.getTechnicianTypeIdentifier(technician.technicianType)}`
  },
  getTechnicianTypeIdentifier: (type: TechnicianType) => {
    switch (type) {
      case 'employee':
        return '1'
      case 'supplier':
        return '2'
    }
  },
  extractTechnicianType: (
    slotId: string
  ): { id: number; type: TechnicianType; dtoIds: Pick<ActivityEmployee, 'technicianId' | 'technicianType'> } => {
    const [resourceId, identifier] = slotId.split('#')
    const id = parseInt(resourceId, 10)
    const employeeIdentifier = chartUtils.getTechnicianTypeIdentifier('employee')

    if (identifier === employeeIdentifier) {
      return { id, type: 'employee', dtoIds: { technicianId: id, technicianType: 'employee' } }
    } else {
      return { id, type: 'supplier', dtoIds: { technicianId: id, technicianType: 'supplier' } }
    }
  },
  getPlanRange: (
    start: string,
    end: string,
    timeSlot: TimeSlot | undefined,
    estimatedTime: string | undefined,
    selectedView: GanttChartView
  ): Pick<ActivityEmployee, 'planDateFrom' | 'planDateTo'> => {
    let localStart = start
    let localEnd = end

    if (selectedView !== 'day' && timeSlot?.hourFrom) {
      const [h, m] = timeSlot.hourFrom.split(':')
      localStart = dayjs(start).add(parseInt(h, 10), 'h').add(parseInt(m, 10), 'm').toISOString()
    }

    if (estimatedTime !== undefined) {
      const [h, m] = estimatedTime.split(':')
      localEnd = dayjs(localStart).add(parseInt(h, 10), 'h').add(parseInt(m, 10), 'm').toISOString()
    }

    return {
      planDateFrom: imDateUtils.toUTC(localStart),
      planDateTo: imDateUtils.toUTC(localEnd),
    }
  },
  prepareDroppedActivity: (
    activityDropped: Activity,
    droppedOnResourceId: number,
    droppedOnResourceType: TechnicianType,
    technicians: Technician[],
    start: string,
    end: string,
    selectedView: GanttChartView,
    plannedById: number | undefined
  ): { showPopUp: boolean; showWrongTimeSlot: boolean; post: Partial<ActivityEmployee>[]; patch: Partial<ActivityEmployee>[] } => {
    let showConfirmationPopup = false
    let showWrongTimeSlotSelected = false
    const post: Partial<ActivityEmployee>[] = []
    const patch: Partial<ActivityEmployee>[] = []

    const foundDroppedOverEmployee = technicians.find(el => el.id === droppedOnResourceId)
    const droppedOverUser: Technician = {
      id: droppedOnResourceId,
      technicianType: droppedOnResourceType,
      firstName: foundDroppedOverEmployee?.firstName,
      lastName: foundDroppedOverEmployee?.lastName,
    }

    // Need to show popup when the activity has been dropped over a not pre-assigned resource
    if (activityDropped.assignedUser) {
      const found = activityDropped.assignedUser.find(el => el.id === droppedOverUser.id)

      if (found === undefined) {
        showConfirmationPopup = true
      }
    }

    if (activityDropped.timeSlot && activityDropped.timeSlot.hourFrom && activityDropped.timeSlot.hourTo && selectedView === 'day') {
      const [timeSlotHF, timeSlotMF] = activityDropped.timeSlot.hourFrom.split(':')
      const [timeSlotHT, timeSlotMT] = activityDropped.timeSlot.hourTo.split(':')

      const slotFrom = `2024-08-08T${timeSlotHF}:${timeSlotMF.padStart(2, '0')}:00Z`
      const slotTo = `2024-08-08T${timeSlotHT}:${timeSlotMT.padStart(2, '0')}:00Z`

      const { planDateFrom, planDateTo } = chartUtils.getPlanRange(start, end, activityDropped.timeSlot, activityDropped.estimatedTime, selectedView)

      const startH = dayjs(planDateFrom).hour()
      const startM = dayjs(planDateFrom).minute()
      const endH = dayjs(planDateTo).hour()
      const endM = dayjs(planDateTo).minute()

      const dropStart = `2024-08-08T${startH}:${startM.toString().padStart(2, '0')}:00Z`
      const dropEnd = `2024-08-08T${endH}:${endM.toString().padStart(2, '0')}:00Z`

      if (!(dayjs(dropStart).isBetween(dayjs(slotFrom), dayjs(slotTo)) || dayjs(dropEnd).isBetween(dayjs(slotFrom), dayjs(slotTo)))) {
        showWrongTimeSlotSelected = true
      }
    }

    const users = [...[droppedOverUser], ...(activityDropped.assignedUser ?? [])]
    const uniqueUsers = [...new Map(users.map(item => [item.id, item])).values()]

    const commonIdentifier: Pick<ActivityEmployee, 'srvActivityId' | 'srvActivityTypeId' | 'srvActivityYear' | 'plannedByEmployeeId'> = {
      srvActivityId: activityDropped.srvActivityId,
      srvActivityTypeId: activityDropped.srvActivityTypeId,
      srvActivityYear: activityDropped.srvActivityYear,
      plannedByEmployeeId: plannedById,
    }

    for (const user of uniqueUsers) {
      const found = activityDropped.connectedActivity.find(el => el.technician.id === user.id)

      if (found) {
        patch.push({
          ...commonIdentifier,
          srvActivityEmployeeId: found.srvActivityEmployeeId,
          technicianId: found.technician.id,
          technicianType: found.technician.technicianType,
          ...chartUtils.getPlanRange(start, end, activityDropped.timeSlot, activityDropped.estimatedTime, selectedView),
        })
      } else {
        post.push({
          ...commonIdentifier,
          technicianId: user.id,
          technicianType: user.technicianType,
          ...chartUtils.getPlanRange(start, end, activityDropped.timeSlot, activityDropped.estimatedTime, selectedView),
        })
      }
    }

    return {
      patch,
      post,
      showPopUp: showConfirmationPopup,
      showWrongTimeSlot: showWrongTimeSlotSelected,
    }
  },
  createEventIds: (event: EventItem) => {
    const type = event.type as DnDType
    let toRet:
      | { srvActivityEmployeeId: number; srvActivityTypeId: number; srvActivityYear: number; srvActivityId: number }
      | { srvUnavailableId: number; srvUnavailableTypeId: number }

    if (type === DnDType.ACTIVITY) {
      const dto = event as unknown as ActivityEmployee

      toRet = {
        srvActivityEmployeeId: dto.srvActivityEmployeeId,
        srvActivityId: dto.srvActivityId,
        srvActivityYear: dto.srvActivityYear,
        srvActivityTypeId: dto.srvActivityTypeId,
      }
    } else {
      const dto = event as unknown as Unavailable

      toRet = {
        srvUnavailableId: dto.srvUnavailableId,
        srvUnavailableTypeId: dto.srvUnavailableTypeId,
      }
    }

    return toRet
  },
}
