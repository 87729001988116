import { IM, IMLayout, IMStyle, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import AssignedUserBadgeList from '../../../components/AssignedUserBadgeList'
import AssignToMeButton from '../../../components/AssignToMeButton'
import Text from '../../../components/Text'
import useLanguageSelect from '../../../hooks/useLanguageSelect'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import { activityUtils } from '../../../utils/ActivityUtils'
import { ActivityListType } from '../../../views/activities/ActivityListView'
import ActivityCardInfoIcons from './ActivityCardInfoIcons'

type Props = {
  type: ActivityListType
  activity: Activity
  assignable: boolean
  showIcon?: boolean
  showEmployee?: boolean
  forceLayout?: ForceLayoutType
  onAssignedUsersPressed?: () => void
}

export default function ActivityCardInfo({
  type,
  activity,
  style,
  showIcon,
  showEmployee,
  forceLayout,
  assignable,
  onAssignedUsersPressed,
  ...props
}: Props & ViewProps) {
  const { isSmallDevice } = useLayout(true, forceLayout)
  const { value: timeSlot } = useLanguageSelect({
    de: activity.timeSlot?.descriptionDe,
    en: activity.timeSlot?.descriptionEn,
    it: activity.timeSlot?.descriptionIt,
  })

  const hasIcons = !!activity.inElaboration

  const isHistorical = useMemo(() => activityUtils.isActivityClosed(activity), [activity.state])

  if (!showIcon && !showEmployee) return <></>

  return (
    <IM.View style={[isSmallDevice ? styles.containerSmallDevice : styles.containerLargeDevice, style]} {...props}>
      {showEmployee && !!activity.assignedUser && (
        <AssignedUserBadgeList
          employees={activity.assignedUser}
          style={isSmallDevice ? styles.employeeBadgeSmallDevice : styles.employeeBadge}
          maxEmployees={3}
          onPress={onAssignedUsersPressed}
          allowExtraIfLast
        />
      )}
      {showEmployee && !activity.assignedUser?.length && assignable && type !== 'gantt' && !isHistorical && (
        <IM.View style={[IMLayout.flex.row, isSmallDevice ? styles.employeeBadgeSmallDevice : styles.employeeBadge, styles.assignToMeButton]}>
          <AssignToMeButton activity={activity} />
        </IM.View>
      )}
      {!isSmallDevice && hasIcons && showIcon && <ActivityCardInfoIcons activity={activity} showIcons={showIcon} forceLayout={forceLayout} />}
      {type === 'gantt' && (
        <Text secondary style={styles.timeSlot} numberOfLines={1}>
          {timeSlot}
          {activity.estimatedTime ? ' (' + activity.estimatedTime + ')' : ''}
        </Text>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  containerSmallDevice: {
    alignItems: 'flex-end',
    minWidth: 150,
  },
  containerLargeDevice: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: IMLayout.verticalMargin * 2,
    minWidth: 150,
  },
  assignToMeButton: {
    justifyContent: 'center',
    marginBottom: IMLayout.verticalMargin / 2,
  },
  employeeBadge: {
    justifyContent: 'center',
  },
  employeeBadgeSmallDevice: {
    justifyContent: 'flex-end',
  },
  timeSlot: { fontSize: IMStyle.typography.fontSizeSmall - 1, flex: 1, paddingBottom: 2 },
})
