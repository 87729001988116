import { api } from '../apis/apiCalls'
import { ActivityTime } from '../apis/types/apiResponseTypes'
import { PostActivityTime } from '../types'

export const ActivityTimeUtils = {
  postTime: (data: Partial<PostActivityTime>, abortController: AbortController) => {
    const technicians = data.technicians ?? []

    const promises: Promise<Partial<ActivityTime>>[] = []
    if (!technicians.length && data.technicianId) {
      technicians.push({ id: data.technicianId, technicianType: data.technicianType ?? 'employee' })
    }

    technicians.forEach(technician => {
      if (data.total) {
        promises.push(
          api.activities.times.post(
            { ...data, technicianId: technician.id, technicianType: technician.technicianType, startTime: undefined, stopTime: undefined },
            abortController
          )
        )
        return
      }
      data.times?.forEach(time => {
        if (!time.startTime || !time.stopTime) return
        promises.push(
          api.activities.times.post(
            {
              ...data,
              technicianId: technician.id,
              technicianType: technician.technicianType,
              startTime: time.startTime,
              stopTime: time.stopTime,
              notes: time.notes,
            },
            abortController
          )
        )
      })
    })

    return Promise.all(promises)
  },
}
