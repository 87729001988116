import { IM, IMLayout, IMStyle, useLanguage, useModalController } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { useNavigation } from '@react-navigation/native'
import { ListRenderItemInfo } from '@shopify/flash-list'
import Color from 'color'
import dayjs from 'dayjs'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import useScrollbarSize from 'react-scrollbar-size'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { api } from '../../apis/apiCalls'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import ActivityListBriefCard from '../../cards/activityList/ActivityListBriefCard'
import ActivityListCard, { ActivityListCardProps } from '../../cards/activityList/ActivityListCard'
import FlashListData from '../../components/FlashListData'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useEmbed from '../../hooks/useEmbed'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useFilter from '../../hooks/useFilter'
import FilterModal from '../../modals/common/FilterModal'
import { CommonStackParamList } from '../../navigation/types'
import { filterUtils } from '../../utils/FilterUtils'
import { imDateUtils } from '../../utils/infominds/imDateUtils'
import { plannerActivitySearchFilterCountAtom, plannerShowActivityExtendedViewAtom, plannerShowClosedActivitiesAtom } from '../../utils/stateManager'
import { PlannerSideViewRef } from './PlannerSideView'

interface Props {
  height: number | undefined
  companyId: number | undefined
  objectId: number | undefined
  onDatePick: (date: Date) => void
}

const ActivitiesSearchView = ({ height, companyId, objectId, onDatePick }: Props, ref: ForwardedRef<PlannerSideViewRef>) => {
  useImperativeHandle(ref, () => ({
    openFilterModal: () => controller.show(undefined),
  }))

  const { item: activities, loadItem: loadActivities, loading: loadingActivities } = useControlledLoader(api.activities.getPlanning)

  const { embed } = useEmbed()
  const { i18n } = useLanguage()
  const { width } = useScrollbarSize()
  const { sessionKey } = useAuthentication()
  const { theme, colorScheme } = useExtendedTheme()
  const controller = useModalController<CommonStackParamList['Filter']>()
  const navigation = useNavigation()

  const [search, setSearch] = useState('')
  const initDone = useRef(false)
  const setEnableFilter = useSetRecoilState(plannerActivitySearchFilterCountAtom)
  const cardExtended = useRecoilValue(plannerShowActivityExtendedViewAtom(sessionKey))
  const showClosedActivities = useRecoilValue(plannerShowClosedActivitiesAtom(sessionKey))

  const { filters, filteredItems, initFilters } = useFilter<Activity>(activities)

  const activeFilters = useMemo(() => filterUtils.getActiveFilters(filters), [filters])

  useEffect(() => {
    reload(search, showClosedActivities)
  }, [search, showClosedActivities])

  useEffect(() => {
    if (initDone.current) return

    if (loadingActivities === false) {
      initDone.current = true
      initFilters(activities ?? [])
    } else {
      setEnableFilter(undefined)
    }
  }, [loadingActivities, activities])

  useEffect(() => {
    setEnableFilter(activeFilters.length)
  }, [activeFilters])

  const reload = (searchString: string, showClosed: boolean) =>
    loadActivities({
      searchText: searchString === '' ? undefined : searchString,
      state: showClosed
        ? [ActivityState.Open, ActivityState.Suspended, ActivityState.Closed, ActivityState.ClosedWithInterventionConnected]
        : [ActivityState.Open, ActivityState.Suspended],
      assignedUser: false,
      companyId,
      objectId,
    })

  const renderItem = (elem: ListRenderItemInfo<Activity | string>) => {
    if (!activities) return <></>

    const isFirst = elem.index === 0
    const isLast = elem.index === activities.length - 1
    const item = elem.item

    if (typeof item === 'string') return <></>

    const common: ActivityListCardProps = {
      onPress: () =>
        navigation.navigate('ActivityDetailStack', {
          screen: 'ActivityDetail',
          params: {
            srvActivityId: item.srvActivityId,
            srvActivityTypeId: item.srvActivityTypeId,
            srvActivityYear: item.srvActivityYear,
          },
        }),
      onTagPress: handleTagPress,
      activity: item,
      forceLayout: 'small',
      type: 'gantt',
      spacing: 'none',
      showPlanTag: true,
      style: {
        marginTop: isFirst ? 0 : IMLayout.horizontalMargin,
        marginBottom: isLast ? IMLayout.horizontalMargin : 0,
        marginLeft: IMLayout.horizontalMargin,
        marginRight: width + IMLayout.horizontalMargin,
      },
      disabled: embed,
    }

    return <>{cardExtended ? <ActivityListCard {...common} /> : <ActivityListBriefCard {...common} />}</>
  }

  const handleTagPress = (date: string) => onDatePick(dayjs(imDateUtils.toUTC(date)).toDate())

  return (
    <>
      <IM.View style={[height === undefined && IMLayout.flex.f1, { height }]}>
        {height === undefined && (
          <IM.SearchBox
            initialValue={search}
            placeholder={i18n.t('SEARCH_ACTIVITIES_PLACEHOLDER')}
            onEnterEnd={setSearch}
            placeholderTextColor={colorScheme === 'dark' ? theme.textPlaceholder : Color(IMStyle.palette.white).darken(0.26).toString()}
            inputMode="search"
            hideIcon
            hideBorder
            autoCapitalize="none"
            height={32}
            spacing="all"
          />
        )}
        <FlashListData
          data={filteredItems}
          loading={loadingActivities}
          noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
          renderItem={renderItem}
          refresh={() => reload(search, showClosedActivities)}
          listSpacer={4}
          ListFooterComponent={<></>}
          extraData={[cardExtended, showClosedActivities]}
        />
      </IM.View>
      <FilterModal controller={controller} />
    </>
  )
}

export default memo(forwardRef(ActivitiesSearchView))
