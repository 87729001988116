import { useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Customer } from '../../apis/types/apiResponseTypes'
import CustomerCard from '../../cards/customer/CustomerCard'
import CustomerCreationModal from '../../modals/customer/CustomerCreationModal'
import { ListSection } from '../../types'
import customerUtils from '../../utils/customerUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import { BaseTextInputProps } from '../input/baseTextInput/BaseTextInput'
import SelectInput from './selectInput/SelectInput'

type Props = {
  onChange: (value: Customer | undefined) => void
  value?: number
  disabledInfo?: string | false
  canCreateNew?: boolean
} & Pick<BaseTextInputProps, 'required' | 'spacing' | 'editable' | 'style' | 'tabIndex'>

export default function CustomerSelector({ onChange, required, value, canCreateNew, ...props }: Props) {
  const { i18n } = useLanguage()

  const { item: data, loadItem, allDataLoaded, loadMore, loading } = useInfiniteLoader(api.customers.getList, { chuckSize: 30 })
  const { item: selected, loadItem: loadSelectedCustomer, setItem: setSelectedCustomer } = useControlledLoader(api.customers.getDetail)
  const createCustomerModal = useModalController<Customer | undefined>()

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (value && selected?.companyId !== value) loadSelectedCustomer({ customerId: value })
    if (!value) setSelectedCustomer(undefined)
  }, [value])

  const refresh = (searchText?: string) => {
    loadItem({ searchtext: searchText })
  }

  const render = ({ item }: SectionListRenderItemInfo<Customer, ListSection<Customer>>, onPress?: () => void) => {
    return <CustomerCard customer={item} onPress={onPress} selected={selected?.companyId === item.companyId} spacing={['horizontal', 'bottom']} />
  }

  const handleOnChange = (newValue: Customer | undefined) => {
    onChange(newValue)
    setSelectedCustomer(newValue)
  }

  function handleOnCreateNew() {
    createCustomerModal.show(undefined)
  }

  function handleCustomerCreated(customer: Customer) {
    onChange(customer)
    setSelectedCustomer(customer)
  }

  return (
    <>
      <SelectInput
        id={apiDtoIds.customers}
        data={data}
        value={selected ?? undefined}
        loading={loading}
        refresh={refresh}
        onSearchChange={refresh}
        title={i18n.t('CUSTOMER')}
        screenTitle={i18n.t('CUSTOMERS')}
        noDataMessage={i18n.t('NO_CUSTOMER_FOUND')}
        renderItem={render}
        onChange={handleOnChange}
        renderSelectedString={item => customerUtils.getTitle(item)}
        required={required}
        allDataLoaded={allDataLoaded}
        onLoadMore={loadMore}
        disableLoadAfterMount
        disableFastInput
        onCreateNew={canCreateNew ? handleOnCreateNew : undefined}
        {...props}
      />
      <CustomerCreationModal controller={createCustomerModal} onCustomerCreated={handleCustomerCreated} />
    </>
  )
}
