import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { createContext, ReactNode, useEffect, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { api } from '../apis/apiCalls'
import { ErgoTaskType } from '../apis/types/apiRequestTypes'
import { Technician, UserRole, type MenuItems, type UserSettings } from '../apis/types/apiResponseTypes'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import { DataProviderStateActions } from '../dataProvider/types'
import { LoadingType } from '../types'
import { revalidateSettingsAtom } from '../utils/stateManager'

type UserSettingsContextType = {
  userSettings: UserSettings | undefined
  menuItems: MenuItems | undefined
  loading: LoadingType
  loadSettings: () => void
  user: UserInfo
  isOnlyReport: boolean
  taskType: ErgoTaskType
}

export type UserInfo = {
  role?: UserRole
  technician?: Technician
  isAdmin: boolean
  isTechnician: boolean
  isTeamLeader: boolean
  isPlanner: boolean
  level: number
  can: {
    see: {
      planer: boolean
      anagrafics: boolean
      unassignedActivities: boolean
    }
    add: {
      activities: boolean
      timesForOthers: boolean
      rda: boolean
    }
    modify: {
      activity: boolean
      timesOfOthers: boolean
      travelsOfOthers: boolean
    }
  }
}

export const UserSettingsContext = createContext<UserSettingsContextType | undefined>(undefined)

type Props = { children: ReactNode | (({ userSettingsReady }: { userSettingsReady: boolean }) => ReactNode) }

export const UserSettingsProvider = ({ children }: Props) => {
  const { isLoggedIn, logout, projectCode } = useAuthentication()
  const { dispatch } = useDataProvider()
  const { i18n } = useLanguage()
  const alert = useAlert()
  const {
    item: userSettings,
    loadItem: loadSettings,
    loading,
    setItem: setSettings,
  } = useControlledLoader(api.userSettings.get, { onErrorCallback: handleFailedToLoad })
  const {
    item: menuItems,
    loadItem: loadMenu,
    loading: loadingMenu,
  } = useControlledLoader(api.menuItems.get, {
    onLoadedCallback: onMenuItemsLoaded,
    onErrorCallback: handleFailedToLoad,
  })
  const revalidate = useRecoilValue(revalidateSettingsAtom)
  const isOnlyReport = useMemo(() => !!isLoggedIn && projectCode === 'APP-EMSRVREP', [projectCode, isLoggedIn])
  const taskType = useMemo(() => (isOnlyReport ? ErgoTaskType.Report : ErgoTaskType.Service), [isOnlyReport])

  useEffect(() => {
    if (!isLoggedIn) return
    loadMenu()
    loadSettings()
  }, [isLoggedIn, revalidate])

  const reloadSettings = () => {
    setSettings(undefined)
    loadSettings()
  }

  function onMenuItemsLoaded(item: MenuItems | null) {
    if (!item) handleFailedToLoad()
  }

  function handleFailedToLoad() {
    alert.alert(i18n.t('ERROR'), i18n.t('ERROR_LOADING_USER_SETTINGS'))
    logout()
      .catch(console.error)
      .finally(() => dispatch({ type: DataProviderStateActions.UpdateOnlineState, payload: { isOnline: true, forcedOfflineMode: false } }))
  }

  const user = useMemo<UserInfo>(() => {
    const isAdmin = isOnlyReport || userSettings?.roles === UserRole.admin
    const isTechnician = userSettings?.roles === UserRole.technician && !isOnlyReport
    const isTeamLeader = userSettings?.roles === UserRole.teamLeader && !isOnlyReport
    const isPlanner = userSettings?.roles === UserRole.planner && !isOnlyReport
    const level = isAdmin ? 4 : isPlanner ? 3 : isTeamLeader ? 2 : 1
    const result: UserInfo = {
      role: userSettings?.roles,
      technician: userSettings?.employee ? ({ ...userSettings?.employee, technicianType: 'employee' } as Technician) : undefined,
      isAdmin,
      isTechnician,
      isTeamLeader,
      isPlanner,
      level,
      can: {
        see: {
          planer: (isAdmin || isPlanner) && !isOnlyReport,
          anagrafics: isAdmin,
          unassignedActivities: level > 1,
        },
        add: {
          activities: level > 1,
          timesForOthers: level > 1,
          rda: level > 1,
        },
        modify: {
          activity: level > 1,
          timesOfOthers: level > 1 && !isOnlyReport,
          travelsOfOthers: level > 1 && !isOnlyReport,
        },
      },
    }

    return result
  }, [userSettings?.roles, userSettings?.employee?.id, isOnlyReport])

  return (
    <UserSettingsContext.Provider
      value={{
        userSettings: userSettings ?? undefined,
        menuItems: menuItems ?? undefined,
        loading: loading || loadingMenu,
        loadSettings: reloadSettings,
        user,
        isOnlyReport,
        taskType,
      }}>
      {typeof children === 'function'
        ? children({
            userSettingsReady: userSettings !== undefined && menuItems !== undefined,
          })
        : children}
    </UserSettingsContext.Provider>
  )
}
