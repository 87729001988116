import { IM, IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, useEffect } from 'react'
import { FlatList, ListRenderItem, Platform } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ActivityOrderBy, GetActivityRequest, GetActivityRequestAssignedUser } from '../../apis/types/apiRequestTypes'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import ActivityListCard from '../../cards/activityList/ActivityListCard'
import FlatListData from '../../components/FlatListData'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import useSearch from '../../components/screen/hooks/useSearch'
import CONSTANTS from '../../constants/Constants'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useObjectUtils from '../../hooks/useObjectUtils'
import useUserSettings from '../../hooks/useUserSettings'

export default function HistoryView() {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { isMediumDevice } = useLayout(true)
  const navigation = useNavigation()
  const { compare: compareActivities } = useObjectUtils<Activity>(apiDtoIds.activity)
  const masterDetail = useMasterDetail<Activity>()
  const { userSettings, taskType } = useUserSettings()
  const listRef = createRef<FlatList<string | Activity>>()
  const {
    item: activities,
    loadItem: loadActivities,
    loading,
    loadMore,
  } = useInfiniteLoader(api.activities.getList, { chuckSize: CONSTANTS.activityChunkSize })

  useEvent({ key: EVENT_KEYS.ACTIVITY_UPDATED }, reload)

  const { apiFilter, requestFilter } = useFilter<Activity, GetActivityRequest>()

  useEffect(() => {
    if (!userSettings) return
    reload()
  }, [userSettings, search, apiFilter])

  useEffect(() => {
    if (loading === false) {
      if (masterDetail.enableMDLayout && !!masterDetail.detailParams && !activities.find(a => compareActivities(a, masterDetail.detailParams))) {
        masterDetail.setDetailParams(activities.length ? activities[0] : undefined)
      } else if (masterDetail.enableMDLayout && !masterDetail.detailParams && activities.length) {
        masterDetail.setDetailParams(activities[0])
      }
    }
  }, [activities, loading])

  function reload() {
    loadActivities({
      ...requestFilter,
      state: [ActivityState.Closed, ActivityState.ClosedWithInterventionConnected],
      assignedUser: requestFilter?.assignedUserId ? GetActivityRequestAssignedUser.AssignedTo : GetActivityRequestAssignedUser.All,
      searchText: search,
      orderBy: [ActivityOrderBy.ClosedDateDesc],
      taskType,
    })
  }
  function onActivityPressed(activity: Activity) {
    if (masterDetail.enableMDLayout) {
      masterDetail.setDetailParams(activity)
    } else {
      navigation.navigate('HistoryDetailStack', {
        screen: 'Detail',
        params: {
          srvActivityId: activity.srvActivityId,
          srvActivityTypeId: activity.srvActivityTypeId,
          srvActivityYear: activity.srvActivityYear,
          title: activity.title,
        },
      })
    }
  }

  const renderItem: ListRenderItem<string | Activity> | null | undefined = elem => {
    const isFirst = elem.index === 0
    const item = elem.item

    // group header
    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          <IM.Text>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <ActivityListCard
          activity={item}
          onPress={() => onActivityPressed(item)}
          forceLayout={isMediumDevice ? undefined : 'small'}
          isSelected={compareActivities(item, masterDetail.detailParams)}
          type={'normal'}
          spacing={'bottom'}
          style={[
            Platform.select({
              web: { marginLeft: 2 * IMLayout.horizontalMargin, marginRight: 2 * IMLayout.horizontalMargin },
              native: { marginHorizontal: 2 * IMLayout.horizontalMargin },
            }),
          ]}
        />
      )
    }
  }

  return (
    <FlatListData
      ref={listRef}
      data={activities}
      loading={loading}
      noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
      renderItem={renderItem}
      isSearching={search !== ''}
      refresh={reload}
      onLoadMore={loadMore}
      listSpacer
    />
  )
}
