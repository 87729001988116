import { IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React, { Dispatch, memo, ReactNode, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { Animated, Platform, SectionListRenderItemInfo } from 'react-native'

import NoSelectionCard from '../cards/common/NoSelectionCard'
import AnimatedButton from '../components/Infominds/AnimatedButton'
import PressableIcon from '../components/Infominds/PressableIcon'
import ListSpacer from '../components/ListSpacer'
import { SearchProvider } from '../components/screen/contexts/SearchContext'
import SectionList from '../components/SectionList'
import SkeletonText from '../components/skeleton/SkeletonText'
import CONSTANTS from '../constants/Constants'
import { FiniteLoadList, InfiniteLoadingType, InfiniteLoadList, ListSection } from '../types'
import SelectModal from './SelectModal'

interface Props {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

export type ScreenSelectModalProps<T> = (FiniteLoadList | InfiniteLoadList) & {
  noDataMessage: string
  loading: InfiniteLoadingType
  focusSearch?: boolean
  screenTitle: string
  deferredTimeout?: number | undefined
  extraIcon?: ReactNode
  data: T[]
  disableLoadAfterMount?: boolean
  showNoSelectionItem?: boolean | React.ReactElement
  selected?: T | undefined | null
  refresh?: () => void
  renderItem: (renderItem: SectionListRenderItemInfo<T, ListSection<T>>, onPress?: () => void) => JSX.Element
  onChange: (value: T | undefined) => void
  onClose?: () => void
  onSearchChange?: (searchText: string) => void
  keepOpenOnChange?: boolean
  onCreateNew?: () => void
}

const buttonAnimationValue = new Animated.Value(0)

function ScreenSelectModal<T>({
  data,
  isVisible,
  noDataMessage,
  focusSearch,
  deferredTimeout,
  extraIcon,
  loading,
  disableLoadAfterMount = false,
  screenTitle,
  selected,
  keepOpenOnChange,
  onCreateNew,
  ...others
}: ScreenSelectModalProps<T> & Props) {
  const { onSearchChange, onChange, onClose, refresh, renderItem, setIsVisible, showNoSelectionItem } = others
  const { colorScheme } = useTheme()

  useEffect(() => {
    !disableLoadAfterMount && isVisible && refresh?.()
    !isVisible && onClose?.()
  }, [isVisible])

  const render = (renderObj: SectionListRenderItemInfo<T, ListSection<T>>) => {
    return renderItem(renderObj, () => {
      onChange(renderObj.item)
      if (!keepOpenOnChange) setIsVisible(false)
    })
  }

  const sections: ListSection<T>[] = useMemo(() => {
    const displayData: ListSection<T>[] = []

    if (data?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        data: data,
      })
    }

    return displayData
  }, [data, loading])

  const ListHeaderComponent = useMemo(() => {
    if (!showNoSelectionItem || !data.length) return <ListSpacer />
    if (showNoSelectionItem === true) {
      return (
        <>
          <ListSpacer />
          <NoSelectionCard
            onPress={() => {
              onChange(undefined)
              setIsVisible(false)
            }}
            selected={!selected}
            spacing={['horizontal', 'bottom']}
          />
        </>
      )
    }

    return showNoSelectionItem
  }, [showNoSelectionItem, selected, data])

  const checkIcon = <PressableIcon icon={['fal', 'check']} size={22} onPress={() => setIsVisible(false)} color={IMStyle.palette.white} />

  const onSearchSubmit = useCallback(
    function () {
      // if exactly 1 item is shown, then on submit that item will be selected directly
      if (sections && sections.length === 1 && sections[0]?.data && sections[0].data.length === 1) {
        onChange(sections[0].data[0])
        setIsVisible(false)
      }
    },
    [sections]
  )

  function onCreateNewPressed() {
    onCreateNew?.()
    setIsVisible(false)
  }

  return (
    <SearchProvider defaultTabletOpen={Platform.OS === 'web' && !!onSearchChange} onSubmit={onSearchSubmit}>
      <SelectModal
        focusSearch={focusSearch}
        isVisible={isVisible}
        close={() => setIsVisible(false)}
        title={screenTitle}
        deferredTimeout={deferredTimeout}
        extraIcon={extraIcon ?? keepOpenOnChange ? checkIcon : undefined}
        hideGoBackIcon={keepOpenOnChange}
        onSearchChange={onSearchChange}>
        <>
          <SectionList
            {...others}
            sections={sections}
            loading={loading}
            renderItem={render}
            noDataMessage={noDataMessage}
            skeletonElements={CONSTANTS.skeletonCards}
            skeletonComponent={<SkeletonText height={20} width="100%" spacing="bottom" />}
            skeletonTopSpacing
            onRefresh={refresh}
            contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 2 * IMLayout.verticalMargin }}
            ListHeaderComponent={ListHeaderComponent}
            indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
          />
          {!!onCreateNew && (
            <AnimatedButton id={'ScreenSelectModal'} value={buttonAnimationValue} icon={['fal', 'plus']} onPress={onCreateNewPressed} />
          )}
        </>
      </SelectModal>
    </SearchProvider>
  )
}

export default memo(ScreenSelectModal) as typeof ScreenSelectModal
