import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { ActivityArticle, RdaStatus } from '../../../apis/types/apiResponseTypes'
import { AppStyle } from '../../../constants/Styles'
import useActivityDetail from '../../../hooks/activity/useActivityDetail'
import useIsRdaEnabled from '../../../hooks/useIsRdaEnabled'
import { articleUtils } from '../../../utils/ArticleUtils'
import TimeUtils from '../../../utils/TimeUtils'
import CheckBox from '../../Infominds/CheckBox'
import Pressable from '../../Infominds/Pressable'
import Separator from '../../Infominds/Separator'
import RdaState from '../rda/RdaState'
import ArticleImage from './ArticleImage'

export type ActivityArticleEntryProps = {
  article: ActivityArticle
  separator?: boolean
  onEdit: (article: ActivityArticle) => void
  onChange: (article: ActivityArticle | null, reload?: boolean) => void
  enableChange?: boolean
  canCreateRda?: boolean
}

export default function ActivityArticleRecord(props: ActivityArticleEntryProps) {
  const { article, separator, onEdit, enableChange } = props
  const { theme } = useTheme()
  const quantity = useMemo(() => articleUtils.formatQuantity(article), [article])
  const { detail, ergoTask } = useActivityDetail()

  const isJolly = useMemo(() => article.isJolly, [article])
  const title = useMemo(() => (article.isJolly ? article.title ?? article.articleId : article.articleId), [article])
  const subTitle = useMemo(() => article.description, [article])
  const rdaEnabled = useIsRdaEnabled(ergoTask)

  return (
    <IM.View>
      {separator && <Separator />}
      <IM.View style={[IMLayout.flex.row]}>
        <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
          <ArticleImage
            onPress={() => onEdit(article)}
            article={article}
            style={{ marginRight: IMLayout.horizontalMargin }}
            taskId={detail?.taskId}
          />
          <IM.View style={[IMLayout.flex.f1]}>
            <Pressable onPress={() => onEdit(article)} style={[styles.pressable]} disabled={!enableChange}>
              <IM.View style={[IMLayout.flex.f1]}>
                <IM.View style={[IMLayout.flex.row]}>
                  <IM.View style={[IMLayout.flex.f1]}>
                    <IM.TextWithIcon icon={isJolly ? ['fal', 'tv-music'] : ['fal', 'barcode']} primary style={IMLayout.flex.f1} numberOfLines={2}>
                      {title}
                    </IM.TextWithIcon>
                    {!!subTitle && (
                      <IM.TextWithIcon icon={['fal', 'cubes']} secondary numberOfLines={3} style={IMLayout.flex.f1}>
                        {subTitle}
                      </IM.TextWithIcon>
                    )}
                  </IM.View>
                  {!!enableChange && (
                    <IM.View>
                      <IM.Icon icon={['fal', 'chevron-right']} color={theme.textPlaceholder} size={18} />
                    </IM.View>
                  )}
                </IM.View>
              </IM.View>
            </Pressable>
            {!!quantity && (
              <IM.View
                style={[IMLayout.flex.row, styles.quantityView]}
                spacing={rdaEnabled && (Platform.OS === 'web' || Platform.OS === 'ios') ? ['top', 'right'] : 'right'}>
                <IM.View>{!isJolly && <RDAView {...props} />}</IM.View>
                <IM.Text primary>{quantity}</IM.Text>
              </IM.View>
            )}
          </IM.View>
        </IM.View>
      </IM.View>
    </IM.View>
  )
}

const viewWidth = 30
function RDAView({ article, onChange, enableChange, canCreateRda }: ActivityArticleEntryProps) {
  const { i18n, language } = useLanguage()

  const rdaDoc = useMemo(() => articleUtils.getRdaDocNumber(article), [article])
  const docDate = useMemo(() => !!article.docDate && TimeUtils.format(article.docDate, language), [article])
  const status = useMemo(() => article.rdaStatus ?? RdaStatus.None, [article])
  const { ergoTask } = useActivityDetail()
  const rdaEnabled = useIsRdaEnabled(ergoTask)

  function handleRdaChange(value: boolean) {
    const newArticle: ActivityArticle = { ...article, isRda: value }
    onChange(newArticle)
  }

  if (!rdaEnabled) return <></>

  return (
    <IM.View style={[IMLayout.flex.row]}>
      <IM.View style={[styles.rdaView, IMLayout.flex.row]}>
        {status === RdaStatus.None && canCreateRda && (
          <IM.View style={{ width: viewWidth }}>
            <CheckBox
              value={article.isRda}
              onChange={q => handleRdaChange(q.nativeEvent.value)}
              color={IMStyle.palette.tint}
              disabled={!!article.refer2SerialNr || !enableChange}
            />
          </IM.View>
        )}
        {status !== RdaStatus.None && (
          <IM.View style={[{ width: viewWidth }, AppStyle.center]}>
            <RdaState rdaStatus={status} />
          </IM.View>
        )}
        <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
          {!rdaDoc && canCreateRda && <IM.Text>{i18n.t('RDA')}</IM.Text>}
          {!!rdaDoc && <IM.Text secondary>{rdaDoc}</IM.Text>}
          {!!docDate && <IM.Text secondary>{docDate}</IM.Text>}
        </IM.View>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  quantityView: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pressable: {
    paddingBottom: 0,
  },
  rdaView: {
    alignItems: 'center',
  },
})
