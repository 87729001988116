import { IM, IMLayout, useDimensions, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import AnagraficMenuCard from '../../cards/anagrafics/AnagraficMenuCard'
import DynamicView from '../../components/Infominds/DynamicView'
import ListSpacer from '../../components/ListSpacer'
import useDetail from '../../components/MasterDetail/hooks/useDetail'
import useMaster from '../../components/MasterDetail/hooks/useMaster'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import useUserSettings from '../../hooks/useUserSettings'
import { AnagraficsStackParamList } from '../../navigation/types'
import { AnagraficsMasterDetailSelection } from '../../types'
import ActivityTypeListView from '../activities/activityTypes/ActivityTypeListView'
import ContractListView from '../activities/contracts/ContractListView'
import TimeSlotListView from '../activities/timeSlots/TimeSlotListView'
import UnavailableTypeListView from '../activities/unavailables/UnavailableTypeListView'

export default function AnagraficsView() {
  const { isOnlyReport } = useUserSettings()
  if (isOnlyReport) {
    return <ActivityTypeListView />
  }

  return (
    <MasterDetail>
      <MasterDetail.Master>
        <AnagraficsMasterView />
      </MasterDetail.Master>
      <MasterDetail.Detail>
        <AnagraficsDetailView />
      </MasterDetail.Detail>
    </MasterDetail>
  )
}

function AnagraficsMasterView() {
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<AnagraficsStackParamList>>()
  const { i18n } = useLanguage()
  const master = useMaster<AnagraficsMasterDetailSelection>()

  return (
    <IM.ScrollView style={[IMLayout.flex.f1]}>
      <ListSpacer />
      <DynamicView
        forceLayout={'small'}
        style={{ marginHorizontal: IMLayout.horizontalMargin * 2 }}
        rowStyle={{ gap: 2 * IMLayout.horizontalMargin }}
        flexLimitSmallDevice={Platform.OS !== 'web' || isSmallDevice ? 6 : 3}>
        <AnagraficMenuCard
          title={i18n.t('ACTIVITY_TYPES')}
          onPress={() => {
            if (isSmallDevice) navigation.navigate('AnagraficsActivityTypeStack', { screen: 'ActivityTypeList' })
            else master.setDetail({ id: 'ActivityType' })
          }}
          style={[IMLayout.flex.f3, styles.card]}
          icon={['fal', 'box']}
          selected={!isSmallDevice && (!master.detail?.id || master.detail?.id === 'ActivityType')}
        />
        <AnagraficMenuCard
          title={i18n.t('TIME_SLOTS')}
          onPress={() => {
            if (isSmallDevice) navigation.navigate('AnagraficsTimeSlotStack', { screen: 'TimeSlotList' })
            else master.setDetail({ id: 'TimeSlots' })
          }}
          style={[IMLayout.flex.f3, styles.card]}
          icon={['fal', 'clock']}
          selected={master.detail?.id === 'TimeSlots'}
        />
        <AnagraficMenuCard
          title={i18n.t('UNAVAILABLE_TYPES')}
          onPress={() => {
            if (isSmallDevice) navigation.navigate('AnagraficsUnavailableTypeStack', { screen: 'UnavailableTypeList' })
            else master.setDetail({ id: 'UnavailableType' })
          }}
          style={[IMLayout.flex.f3, styles.card]}
          icon={['fal', 'island-tropical']}
          selected={master.detail?.id === 'UnavailableType'}
        />
        <AnagraficMenuCard
          title={i18n.t('CONTRACTS')}
          onPress={() => {
            if (isSmallDevice) navigation.navigate('AnagraficsContractStack', { screen: 'ContractList' })
            else master.setDetail({ id: 'Contracts' })
          }}
          style={[IMLayout.flex.f3, styles.card]}
          icon={['fal', 'file-contract']}
          selected={master.detail?.id === 'Contracts'}
        />
      </DynamicView>
    </IM.ScrollView>
  )
}

function AnagraficsDetailView() {
  const { detail } = useDetail<AnagraficsMasterDetailSelection>()

  if (detail?.id === 'TimeSlots') {
    return <TimeSlotListView />
  } else if (detail?.id === 'Contracts') {
    return <ContractListView />
  } else if (detail?.id === 'UnavailableType') {
    return <UnavailableTypeListView />
  } else {
    return <ActivityTypeListView />
  }
}

const styles = StyleSheet.create({
  card: { marginBottom: IMLayout.horizontalMargin },
})
