import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity, ActivityTravel, Technician } from '../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../components/ApiDeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import DateInput from '../../components/input/DateInput'
import NumberInput from '../../components/input/NumberInput'
import { TextInputProps } from '../../components/input/TextInput'
import TimeInput from '../../components/input/TimeInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ActivityTypeSelector from '../../components/selectors/ActivityTypeSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useObjectUtils from '../../hooks/useObjectUtils'
import useUserSettings from '../../hooks/useUserSettings'
import { EditOrCreateViewRef, UploadStatus } from '../../types'
import { ValidationUtils } from '../../utils/ValidationUtils'

export type ActivityTravelCreateOrEditViewProps = {
  activity: Activity
  activityTravel?: ActivityTravel
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function ActivityTravelCreateOrEditView(
  { activity, activityTravel, onDone, onUploadStatus }: ActivityTravelCreateOrEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: handleCreate,
  }))
  const { i18n } = useLanguage()
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const { userSettings } = useUserSettings()

  function handleCreate() {
    createOrUpdate()
  }

  const initialValue: Partial<ActivityTravel> | undefined = activityTravel ?? {
    technician: { ...userSettings?.employee, technicianType: 'employee' } as Technician,
    travelDate: new Date().toISOString(),
    activityTravelSrvActivityTypeId: activity.srvActivityTypeId,
  }
  const requiredFields = useMemo<EditDataHandlerRequiredFields<ActivityTravel>>(
    () => ['technician', 'activityTravelSrvActivityTypeId', 'travelDate', ['km', 'travelDuration']],
    []
  )
  const { state, editMode, createOrUpdate, handleDataChange, errorOnFields } = useEditDataHandler<ActivityTravel>(
    api.activities.travels.post,
    api.activities.travels.put,
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.ACTIVITY_TRAVELS_UPDATED,
      eventKeyModification: EVENT_KEYS.ACTIVITY_TRAVELS_UPDATED,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!activityTravel,
      initialValue: initialValue,
      showErrorAlert: true,
      requiredFields,
      modifyDataBeforeRequest: data => ({
        ...data,
        ...activityObjectUtils.createRequestObject(activity),
        technicianId: data.technician?.id,
        technicianType: data.technician?.technicianType,
      }),
      validate: value => (!ValidationUtils.number(value.km) ? ['km'] : []),
    }
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <ActivityTypeSelector
        initialValue={initialValue?.activityTravelSrvActivityTypeId}
        onChange={value => handleDataChange({ activityTravelSrvActivityTypeId: value?.srvActivityTypeId })}
        required
        {...commonProps}
      />

      <DateInput
        containerStyle={IMLayout.flex.f1}
        title={i18n.t('DATE')}
        value={state?.travelDate}
        onChangeDate={value => handleDataChange({ travelDate: value?.toISOString() })}
        required
        {...commonProps}
      />

      <IM.View style={[IMLayout.flex.row, { gap: 2 * IMLayout.horizontalMargin }]}>
        <TimeInput
          required={!state?.km}
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('TRAVEL_DURATION')}
          value={state?.travelDuration}
          onChangeTime={value => handleDataChange({ travelDuration: value })}
          error={errorOnFields.includes('travelDuration')}
          defaultPickerValue={'00:00'}
          {...commonProps}
        />
        <NumberInput
          required={!state?.travelDuration}
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('TRAVEL_DISTANCE')}
          value={state?.km}
          onValueChanged={value => handleDataChange({ km: value })}
          error={errorOnFields.includes('km')}
          disableFastInputs
          unit={'km'}
          {...commonProps}
        />
      </IM.View>
      <GroupSpacer />

      {editMode && !!activityTravel && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={activityTravel}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_TRAVEL_ALERT')}
          deleteAlertTitle={i18n.t('DELETE_ACTIVITY_TRAVEL')}
          deleteRequest={api.activities.travels.delete}
          eventKey={EVENT_KEYS.ACTIVITY_TRAVELS_UPDATED}
          {...commonProps}
        />
      )}
      <GroupSpacer />
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityTravelCreateOrEditView)
