import { IMLayout, useLanguage, Utils } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity, ActivityTime } from '../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../components/ApiDeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import DateInput from '../../components/input/DateInput'
import MultiTimeInput from '../../components/input/MultiTimeInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import TimeInput from '../../components/input/TimeInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ActivityTypeSelector from '../../components/selectors/ActivityTypeSelector'
import MultiTechnicianSelector from '../../components/selectors/MultiTechnicianSelector'
import TechnicianSelector from '../../components/selectors/TechnicianSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useObjectUtils from '../../hooks/useObjectUtils'
import useUserSettings from '../../hooks/useUserSettings'
import { EditOrCreateViewRef, PostActivityTime, UploadStatus } from '../../types'
import { ActivityTimeUtils } from '../../utils/ActivityTimeUtils'
import { ValidationUtils } from '../../utils/ValidationUtils'

export type ActivityTimeCreateOrEditViewProps = {
  activity: Activity
  activityTime?: ActivityTime
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
}

function ActivityTimeCreateOrEditView(
  { activity, activityTime, onDone, onUploadStatus }: ActivityTimeCreateOrEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: handleCreate,
  }))
  const { i18n } = useLanguage()
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)
  const { userSettings, user } = useUserSettings()

  function handleCreate() {
    createOrUpdate()
  }
  const singleTechnicianInput = useMemo(() => !!activityTime || !user.can.add.timesForOthers, [activityTime, user])

  const initialValue: Partial<PostActivityTime> | undefined = useMemo(
    () =>
      activityTime
        ? {
            ...activityTime,
            technicianId: activityTime.technician?.id,
            technicianType: activityTime.technician?.technicianType,
            times: [{ id: Utils.getUid(), startTime: activityTime.startTime, stopTime: activityTime.stopTime }],
            total: activityTime.startTime && activityTime.startTime ? undefined : activityTime.total,
          }
        : {
            technicians: user.can.add.timesForOthers ? activity.assignedUser : [],
            technician: user.technician,
            timeDate: new Date().toISOString(),
            activityTimeSrvActivityTypeId: activity.srvActivityTypeId,
          },
    [activityTime, user.technician, activity]
  )
  const requiredFields = useMemo<EditDataHandlerRequiredFields<PostActivityTime>>(
    () => ['technician', 'activityTimeSrvActivityTypeId', 'timeDate'],
    []
  )
  const { state, editMode, createOrUpdate, handleDataChange } = useEditDataHandler<PostActivityTime>(
    ActivityTimeUtils.postTime,
    api.activities.times.put,
    undefined,
    {
      eventKeyCreation: EVENT_KEYS.ACTIVITY_TIME_MODIFIED,
      eventKeyModification: EVENT_KEYS.ACTIVITY_TIME_MODIFIED,
      onDone: onDone,
      onUploadStatus: onUploadStatus,
      editMode: !!activityTime,
      initialValue: initialValue,
      showErrorAlert: true,
      requiredFields,
      modifyDataBeforeRequest: data => ({
        ...data,
        ...activityObjectUtils.createRequestObject(activity),
        technicianId: data.technician?.id,
        technicianType: data.technician?.technicianType,
        startTime: data.times?.at(0)?.startTime,
        stopTime: data.times?.at(0)?.stopTime,
      }),

      validate: value => {
        if (!singleTechnicianInput && !value.technicians?.length) return ['technicians']
        if (!value.times?.find(t => !!t.startTime && !!t.stopTime) && !value.total) return ['total', 'times']
        if (!!value.times?.length && value.times.find(t => !!t.startTime && !!t.stopTime && !ValidationUtils.timeSpan(t.startTime, t.stopTime))) {
          return ['startTime', 'stopTime']
        }
        return []
      },
    }
  )

  const editable =
    !editMode ||
    user.can.modify.timesOfOthers ||
    (activityTime?.technician?.id === userSettings?.employee?.id && activityTime?.technician?.technicianType === 'employee')

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
    editable: editable,
  }

  const showDurationInput = useMemo(() => state?.total || !state?.times?.find(t => t.startTime || t.stopTime), [state])

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      {singleTechnicianInput && (
        <TechnicianSelector
          value={state?.technician?.id}
          onChange={value =>
            handleDataChange({
              technician: value,
            })
          }
          required
          disableFastInput
          taskId={activity.taskId}
          {...commonProps}
          editable={editMode && user.can.modify.timesOfOthers && editable}
        />
      )}
      {!singleTechnicianInput && (
        <MultiTechnicianSelector
          selectedTechnicians={state?.technicians}
          onChange={value => handleDataChange({ technicians: value })}
          required
          disableFastInput
          taskId={activity.taskId}
          {...commonProps}
          editable={!editMode && user.can.add.timesForOthers && editable}
        />
      )}
      <ActivityTypeSelector
        initialValue={activityTime?.activityTimeSrvActivityTypeId ?? activity.srvActivityTypeId}
        onChange={value => handleDataChange({ activityTimeSrvActivityTypeId: value?.srvActivityTypeId })}
        required
        {...commonProps}
      />

      <DateInput
        containerStyle={IMLayout.flex.f1}
        title={i18n.t('DATE')}
        value={state?.timeDate}
        onChangeDate={value => handleDataChange({ timeDate: value?.toISOString() })}
        required
        {...commonProps}
      />
      {showDurationInput && (
        <TimeInput
          required
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('DURATION')}
          value={state?.total}
          onChangeTime={value => handleDataChange({ total: value, startTime: undefined, stopTime: undefined })}
          defaultPickerValue={'00:00'}
          {...commonProps}
        />
      )}

      {!state?.total && (
        <MultiTimeInput
          times={state?.times}
          onChange={value => {
            handleDataChange({ times: value })
          }}
          editable={editMode ? 'only-current' : 'full'}
          hasNotes={!editMode}
        />
      )}

      {(!!state?.total || editMode) && (
        <TextInput
          value={state?.notes}
          onChangeText={value => handleDataChange({ notes: value })}
          editable
          title={i18n.t('NOTES')}
          multiline
          {...commonProps}
        />
      )}

      <GroupSpacer />
      {editMode && editable && !!activityTime && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={activityTime}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_TIME_ALERT')}
          deleteAlertTitle={i18n.t('DELETE_ACTIVITY_TIME')}
          deleteRequest={api.activities.times.delete}
          eventKey={EVENT_KEYS.ACTIVITY_TIME_MODIFIED}
          {...commonProps}
        />
      )}

      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityTimeCreateOrEditView)
