import { ModalController, useLanguage } from '@infominds/react-native-components'
import { FormProvider } from 'antd/es/form/context'
import React, { createRef } from 'react'
import { Keyboard } from 'react-native'

import { Customer } from '../../apis/types/apiResponseTypes'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { EditOrCreateViewRef, ModalScreenRef } from '../../types'
import CustomerCreateOrEditView from '../../views/customer/CustomerCreateOrEditView'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<Customer | undefined>
  onCustomerCreated: (customer: Customer) => void
}

export default function CustomerCreationModal({ controller, onCustomerCreated }: Props) {
  const ref = createRef<ModalScreenRef>()

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const customerToEdit = controller.data

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <SearchProvider>
        <Screen transparent>
          <Screen.Header
            goBack={() => handleGoBack()}
            backHandlerCallback={handleGoBack}
            isModal
            title={customerToEdit ? i18n.t('EDIT_CUSTOMER') : i18n.t('CREATE_CUSTOMER')}
            onRightIconPress={() => {
              Keyboard.dismiss()
              createViewRef.current?.handleUpload()
            }}
            rightIcon={['fal', 'check']}
            disable={status === 'done' || status === 'mandatoryMissing'}
            loading={status === 'uploading'}
          />
          <Screen.Content>
            <FormProvider>
              <CustomerCreateOrEditView
                customer={customerToEdit}
                ref={createViewRef}
                onDone={(type, result, request) => {
                  handleGoBack(true)
                  const createdCompanyId = result?.find(r => r.companyId)?.companyId
                  if (!createdCompanyId) return
                  onCustomerCreated({ ...request, companyId: createdCompanyId } as Customer)
                }}
                onUploadStatus={setStatus}
              />
            </FormProvider>
          </Screen.Content>
        </Screen>
      </SearchProvider>
    </BaseServiceModal>
  )
}
