import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { ToastContainer as RTToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

export default function ToastContainer() {
  const { colorScheme } = useTheme()

  return <RTToastContainer theme={colorScheme} pauseOnHover position="bottom-right" />
}
