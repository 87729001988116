import { IM, IMLayout } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet, ViewProps } from 'react-native'

import { RdaStatus } from '../../../apis/types/apiResponseTypes'
import useExtendedTheme from '../../../hooks/useExtendedTheme'

export type RdaStateProps = { rdaStatus: RdaStatus | undefined | null; size?: number } & Pick<ViewProps, 'style'>

export default function RdaState({ rdaStatus, size = 16, style }: RdaStateProps) {
  const { theme } = useExtendedTheme()
  const status = useMemo(() => rdaStatus ?? RdaStatus.None, [rdaStatus])
  const statusColor = useMemo(() => {
    switch (status) {
      case RdaStatus.None:
        return undefined
      case RdaStatus.Green:
        return theme.article.status.green
      case RdaStatus.Yellow:
        return theme.article.status.yellow
      case RdaStatus.Red:
        return theme.article.status.red
    }
  }, [status])

  if (status === RdaStatus.None) return <></>

  return <IM.View style={[styles.rdaStatusSignal, { backgroundColor: statusColor, height: size, width: size }, style, IMLayout.shadow]} />
}

const styles = StyleSheet.create({
  rdaStatusSignal: {
    borderRadius: 100,
  },
})
