import { IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Activity } from '../../apis/types/apiResponseTypes'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useUserSettings from '../../hooks/useUserSettings'
import { InfiniteLoadingType, LoadingType } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import TabSelector from '../TabSelector'

export type ActivityListTabs = 'my' | 'unassigned'

type ActivityListTabsProps = {
  tab: ActivityListTabs
  setTab: (tab: ActivityListTabs) => void
  activities: (string | Activity)[]
  unassignedActivities: (string | Activity)[]
  loading: LoadingType | InfiniteLoadingType
  loadingUnassigned: LoadingType | InfiniteLoadingType
  showCount?: boolean
}

export default function ActivityListTabs({
  tab,
  setTab,
  activities,
  unassignedActivities,
  loading,
  loadingUnassigned,
  showCount,
}: ActivityListTabsProps) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useExtendedTheme()
  const { user } = useUserSettings()
  const tabTitle_my = useMemo(
    () => activityUtils.getTabTitle(user.isAdmin ? i18n.t('ASSIGNED') : i18n.t('MY'), activities, loading, showCount),
    [activities, loading, user.isAdmin]
  )
  const tabTitle_unassigned = useMemo(
    () => activityUtils.getTabTitle(i18n.t('UNASSIGNED'), unassignedActivities, loadingUnassigned, showCount),
    [unassignedActivities, loadingUnassigned]
  )

  const borderStyle = useMemo<StyleProp<ViewStyle>>(() => [styles.addBorder, { borderColor: theme.textPlaceholder }, IMLayout.shadow], [theme])

  return (
    <TabSelector height={34} style={[styles.container, colorScheme === 'dark' && borderStyle]}>
      <TabSelector.Item
        iconSize={18}
        text={tabTitle_my}
        selected={tab === 'my'}
        onPress={() => setTab('my')}
        disableDivider
        selectionColor={theme.header.main.background}
      />
      <TabSelector.Item
        text={tabTitle_unassigned}
        selected={tab === 'unassigned'}
        onPress={() => setTab('unassigned')}
        disableDivider
        selectionColor={theme.header.main.background}
      />
    </TabSelector>
  )
}

const styles = StyleSheet.create({
  addBorder: {
    borderWidth: 1,
  },
  container: {
    marginHorizontal: 14,
    marginTop: 12,
  },
})
