import { useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import { useEffect, useMemo, useRef, useState } from 'react'

import useVibrationFeedback from '../../../hooks/useVibrationFeedback'
import { LoadingType, UploadStatus } from '../../../types'
import appUtils from '../../../utils/appUtils'
import { ExceptionUtils } from '../../../utils/ExceptionUtils'
import { objectUtils } from '../../../utils/objectUtils'

export type EditDataHandlerRequestType = 'create' | 'update' | 'delete'

export type EditDataHandlerRequiredFields<T> = (keyof T | (keyof T)[])[]

export type EditDataHandlerOptions<TData, TResult> = {
  showErrorAlert?: boolean
  // onSuccess?: (response: unknown) => void
  onError?: (error: unknown) => void
  onAborted?: () => void
  onDone?: (type: EditDataHandlerRequestType, requestResults?: TResult[], request?: Partial<TData>) => void
  preDoneRoutine?: (type: EditDataHandlerRequestType, requestResults?: TResult[], request?: Partial<TData>) => Promise<unknown>
  onUploadStatus?: (status: UploadStatus) => void
  editMode?: boolean
  initialValue?: Partial<TData>
  requiredFields?: EditDataHandlerRequiredFields<TData> | ((state: TData | undefined) => EditDataHandlerRequiredFields<TData>)
  modifyDataBeforeRequest?: (data: TData, requestType: EditDataHandlerRequestType) => TData
  eventKeyCreation?: string
  eventKeyModification?: string
  eventKeyDeletion?: string
  validate?: (state: TData) => (keyof TData)[]
  preRequestRoutine?: (
    request: TData,
    requestType: EditDataHandlerRequestType
  ) => (void | false | null | 'abort') | Promise<void | false | null | 'abort'>
}

export type EditDataHandlerResult<TData> = {
  state: TData | undefined
  createOrUpdate: (amountToCreate?: number) => void
  deleteData: () => void
  handleDataChange: (data: Partial<TData>) => void
  reValidate: () => void
  requestState: LoadingType
  uploadState: UploadStatus
  error: string
  editMode: boolean
  errorOnFields: (keyof TData)[]
  requiredFields: EditDataHandlerRequiredFields<TData>
}

export default function useEditDataHandler<TData extends object, TResult = Partial<TData>>(
  createFunc?: (request: Partial<TData>, abortController: AbortController) => Promise<unknown>,
  updateFunc?: (request: Partial<TData>, abortController: AbortController) => Promise<unknown>,
  deleteFunc?: (request: Partial<TData>, abortController: AbortController) => Promise<unknown>,
  options?: EditDataHandlerOptions<TData, TResult>
): EditDataHandlerResult<TData> {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { vibrationFeedback } = useVibrationFeedback()

  const editMode = !!options?.editMode
  const [state, setState] = useState<TData | undefined>(options?.initialValue as TData)
  const [errorOnFields, setErrorOnFields] = useState<(keyof TData)[]>([])
  const [requestState, setRequestState] = useState<LoadingType>(false)
  const requestData = useRef<{ request: TData; type: EditDataHandlerRequestType | undefined; amountToCreate?: number }>({
    request: {} as TData,
    type: undefined,
    amountToCreate: undefined,
  })
  const requiredFields = useMemo(
    () => (typeof options?.requiredFields === 'function' ? options.requiredFields(state) : options?.requiredFields ?? []),
    [state, options?.requiredFields]
  )
  const errorMessage = useRef('')
  const { emit: emitOnCreateEvent } = useEvent({ key: options?.eventKeyCreation ?? '' })
  const { emit: emitOnModifiedEvent } = useEvent({ key: options?.eventKeyModification ?? '' })
  const { emit: emitOnDeletedEvent } = useEvent({ key: options?.eventKeyDeletion ?? '' })

  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  useEffect(() => {
    const abortController = new AbortController()
    requestState === 'reloading' && loader(abortController).catch(console.error)
    return () => abortController.abort()
  }, [requestState])

  useEffect(validateState, [state, requiredFields])

  function validateState() {
    if (
      state === undefined ||
      JSON.stringify(state) === '{}' ||
      !objectUtils.getDifferenceBetweenObjects(state, options?.initialValue ?? {}, 'soft').length
    ) {
      updateUploadStatus('done')
      setErrorOnFields([])
      return
    }

    let uploadStatus: UploadStatus = 'done'
    const validationResult: (keyof TData)[] = options?.validate?.(state) ?? []
    setErrorOnFields(validationResult)
    if (!objectUtils.validateItemRequiredFields(state, requiredFields) || validationResult.length) {
      uploadStatus = 'mandatoryMissing'
    } else {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }

  async function loader(abortController: AbortController) {
    try {
      errorMessage.current = ''
      const type = requestData.current.type as EditDataHandlerRequestType
      const request = { ...requestData.current.request }
      const preRequestRoutineResult = await options?.preRequestRoutine?.(request, type)
      if (preRequestRoutineResult === 'abort') {
        updateUploadStatus('waiting')
        setRequestState(false)
        return
      }
      const result = await runRequests(abortController, type, request, requestData.current.amountToCreate)

      if (options?.preDoneRoutine) await options.preDoneRoutine(type, result as TResult[], request)
      setRequestState(false)
      completeUploadProcedure(type, result, request)
    } catch (err) {
      if (appUtils.isAbortError(err)) {
        console.debug(`Aborted useRequest`)
        options?.onAborted?.()
        setRequestState('aborted')
        return
      }
      options?.onError?.(err)
      setRequestState('catched')
      errorMessage.current = ExceptionUtils.exceptionToString(err)

      if (options?.showErrorAlert) {
        alert.alert(i18n.t('API_CATCH_TITLE'), errorMessage.current)
      }
    }

    return
  }

  async function runRequests(abortController: AbortController, type: EditDataHandlerRequestType, request: Partial<TData>, amountToCreate?: number) {
    const requestFunction = type === 'update' ? updateFunc : type === 'delete' ? deleteFunc : createFunc
    if (!requestFunction) {
      throw new Error(`useEditDataHandler() request of type ${type} was called but the corresponding function was not assigned`)
    }
    const numberOfRequests = type === 'create' && !!amountToCreate ? Math.max(amountToCreate, 1) : 1
    const results: unknown[] = []
    for (let requestIndex = 0; requestIndex < numberOfRequests; requestIndex++) {
      results.push(await requestFunction(request, abortController))
    }
    return results
  }

  useEffect(() => {
    if (waitingUpload === 'waiting') return

    if (requestState === 'catched') {
      updateUploadStatus('waiting')

      return
    }
  }, [requestState, waitingUpload])

  function completeUploadProcedure(type: EditDataHandlerRequestType, results: unknown[], request?: Partial<TData>) {
    updateUploadStatus('done')
    vibrationFeedback()
    options?.onDone?.(type, results as TResult[], request)
    if (type === 'create' && options?.eventKeyCreation) {
      emitOnCreateEvent()
    } else if (type === 'update' && options?.eventKeyModification) {
      emitOnModifiedEvent()
    } else if (type === 'delete' && options?.eventKeyDeletion) {
      emitOnDeletedEvent()
    }
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    options?.onUploadStatus?.(newStatus)
  }

  function upload(amountToCreate?: number) {
    if (state === undefined) return

    updateUploadStatus('uploading')
    const requestType = editMode ? 'update' : 'create'
    const toUpload = options?.modifyDataBeforeRequest ? options.modifyDataBeforeRequest({ ...state }, requestType) : { ...state }
    requestData.current = { request: toUpload, type: requestType, amountToCreate }
    setRequestState('reloading')
  }

  function deleteData() {
    if (state === undefined) return
    updateUploadStatus('uploading')
    requestData.current = { request: options?.initialValue as TData, type: 'delete', amountToCreate: undefined }
    setRequestState('reloading')
  }

  function handleDataChange(newVal: Partial<TData>) {
    if (waitingUpload === 'uploading') return
    //@ts-ignore  not important
    setState(prev => ({
      ...prev,
      ...newVal,
    }))
  }

  return {
    state: state,
    editMode,
    handleDataChange,
    createOrUpdate: upload,
    deleteData,
    reValidate: validateState,
    requestState,
    error: errorMessage.current,
    uploadState: waitingUpload,
    errorOnFields,
    requiredFields,
  }
}
