import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { RdaStatus } from '../../apis/types/apiResponseTypes'
import RdaState from '../activity/rda/RdaState'
import BaseTextInputTitle from '../input/baseTextInput/BaseTextInputTitle'
import { TextInputProps } from '../input/TextInput'
import Tag from '../Tag'

type Props = {
  value: RdaStatus[] | undefined
  onChange: (values: RdaStatus[] | undefined) => void
} & Pick<TextInputProps, 'title' | 'editable' | 'spacing' | 'disableBorderRadius' | 'placeholder'>

const RDA_STATES = [RdaStatus.Green, RdaStatus.Yellow, RdaStatus.Red]
export default function RdaStatusSelector({ onChange, value, editable, spacing, title }: Props) {
  const { i18n } = useLanguage()

  function onTagPress(status: RdaStatus) {
    if (value?.includes(status)) {
      onChange(value?.filter(v => v !== status))
    } else {
      onChange([...(value ?? []), status])
    }
  }

  return (
    <IM.View spacing={spacing}>
      <BaseTextInputTitle title={title ?? i18n.t('RDA_STATUS')} />
      <IM.View style={[IMLayout.flex.row]}>
        {RDA_STATES.map((status, index) => (
          <Tag id={index} active={!!value?.includes(status)} name={status} disabled={!editable} onPress={() => onTagPress(status)}>
            <RdaState rdaStatus={status} />
          </Tag>
        ))}
      </IM.View>
    </IM.View>
  )
}
