import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { ConstructionSiteLotto, Contract } from '../../apis/types/apiResponseTypes'
import ConstructionSiteLottoCard from '../../cards/constructionSites/ConstructionSiteLottoCard'
import { ConstructionSiteLottoFilterConfig } from '../../constants/Filters'
import useObjectUtils from '../../hooks/useObjectUtils'
import { Border, ListSection } from '../../types'
import constructionSiteUtils from '../../utils/constructionSiteUtils'
import { filterUtils } from '../../utils/FilterUtils'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import SelectInput from './selectInput/SelectInput'

type Props = {
  editable?: boolean
  spacing?: SpacingProps
  objectId?: number
  onChange: (value: ConstructionSiteLotto | undefined) => void
  required?: boolean | 'semi'
  value?: number
  disabledInfo?: string | false
  filter?: (value: ConstructionSiteLotto | undefined) => boolean
  disableBorderRadius?: Border | Border[] | undefined
  contract?: Contract
  title?: string
}

export default function ConstructionSiteLottoSelector({
  onChange,
  required,
  objectId,
  value,

  title,
  filter,
  contract,
  ...props
}: Props) {
  const { i18n } = useLanguage()
  const objectUtils = useObjectUtils<ConstructionSiteLotto>(apiDtoIds.constructionsSiteLottos)
  const [search, setSearch] = useState('')

  const {
    item: allData,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
    setItem,
  } = useInfiniteLoader(api.constructionsSites.lotto.getList, { chuckSize: 1000000 })

  const data = useMemo(() => {
    let filteredData = [...allData]
    if (filter) {
      filteredData = filteredData.filter(filter)
    }
    if (contract?.srvContractId) {
      filteredData = filteredData.filter(d => !!contract.lots?.find(cl => cl.objectLotId === d.lotId))
    }
    if (search) {
      filteredData = filterUtils.filterItemsBySearch(filteredData, search, ConstructionSiteLottoFilterConfig.searchKeys)
    }
    return filteredData
  }, [allData, contract, filter, search])

  const selected = useMemo(() => data.find(d => d.lotId === value), [value, data])

  useEffect(() => {
    refresh()
  }, [objectId])

  const refresh = () => {
    if (!objectId) {
      setItem([])
      return
    }
    loadItem({ objectId })
  }

  const render = ({ item }: SectionListRenderItemInfo<ConstructionSiteLotto, ListSection<ConstructionSiteLotto>>, onPress?: () => void) => {
    return (
      <ConstructionSiteLottoCard
        constructionSiteLotto={item}
        onPress={onPress}
        selected={objectUtils.compare(selected, item)}
        spacing={['horizontal', 'bottom']}
        lite
      />
    )
  }

  const handleOnChange = (newValue: ConstructionSiteLotto | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={apiDtoIds.constructionsSiteLottos}
      data={data}
      value={selected}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={title ?? i18n.t('CONSTRUCTION_SITE_LOTTO') + (required === 'semi' ? ` *` : '')}
      screenTitle={i18n.t('CONSTRUCTION_SITE_LOTTO')}
      noDataMessage={i18n.t('NO_CONSTRUCTION_SITE_LOTTO_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => constructionSiteUtils.getLottoTitle(item)}
      required={required === true}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      disableFastInput
      {...props}
    />
  )
}
