import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import { PlannedActivityEmployee } from '../../types'
import { objectUtils } from '../../utils/objectUtils'
import BaseServiceModal from '../BaseServiceModal'
import ActivityPlanEmployeeModalView from './views/ActivityPlanEmployeeModalView'

export type ActivityPlanEmployeeModalProps = PlannedActivityEmployee

interface Props {
  controller: ModalController<ActivityPlanEmployeeModalProps>
  onChange: (value: PlannedActivityEmployee) => void
  onDelete?: (value: PlannedActivityEmployee) => void
  estimatedTime?: string
  timeSlotId?: number
}

// wrapper to avoid first render error due to state being initialized by controller.data
export default function ActivityPlanEmployeeModal(props: Props) {
  return (
    <BaseServiceModal controller={props.controller}>
      <ModalContent {...props} />
    </BaseServiceModal>
  )
}

function ModalContent({ controller, onChange, onDelete, estimatedTime, timeSlotId }: Props) {
  const { i18n } = useLanguage()

  const [state, setState] = useState<PlannedActivityEmployee | undefined>(controller.data ? { ...controller.data } : undefined)

  const error = useMemo(
    () => !!state?.planDateFrom && !!state?.planDateTo && state?.planDateFrom >= state?.planDateTo,
    [state?.planDateFrom, state?.planDateTo]
  )

  const canSave = !error && !objectUtils.compareObjects(controller.data, state)

  function handleDelete() {
    if (onDelete && controller.data) onDelete(controller.data)
    controller.close()
  }

  return (
    <Screen transparent>
      <Screen.Header
        goBack={() => controller.close()}
        isModal
        title={i18n.t('PLANNING')}
        onRightIconPress={() => {
          Keyboard.dismiss()
          if (state) onChange(state)
          controller.close()
        }}
        rightIcon={['fal', 'check']}
        disable={!canSave}
        loading={false}
      />
      <Screen.Content>
        <FormProvider>
          <ActivityPlanEmployeeModalView
            state={state}
            onDelete={handleDelete}
            setState={setState}
            error={error}
            estimatedTime={estimatedTime}
            timeSlotId={timeSlotId}
          />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}
