import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'

import { api } from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../components/ApiDeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ConstructionSiteContactSelector from '../../components/selectors/ConstructionSiteContactSelector'
import ConstructionSiteLottoSelector from '../../components/selectors/ConstructionSiteLottoSelector'
import ConstructionSiteSelector from '../../components/selectors/ConstructionSiteSelector'
import ContactSelector from '../../components/selectors/ContactSelector'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import DestinationSelector from '../../components/selectors/DestinationSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { useReportActivity } from '../../contexts/report/ReportActivityContext'
import useActivityEmployeePlaner from '../../hooks/activity/useActivityEmployeePlaner'
import useActivityTimes from '../../hooks/activity/useActivityTimes'
import useErgoTask from '../../hooks/useErgoTask'
import useUserSettings from '../../hooks/useUserSettings'
import { EditOrCreateViewRef, UploadStatus } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import { ValidationUtils } from '../../utils/ValidationUtils'
import ActivityCreationDefaultInputs from './ActivityCreationDefaultInputs'

export type ReportActivityCreateOrEditViewProps = {
  activity?: Activity
  handleCreateActivity?: (activity: Activity) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
  onDeleted: () => void
}

function ReportActivityCreateOrEditView(
  { activity, onDone, onDeleted, onUploadStatus, handleCreateActivity }: ReportActivityCreateOrEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: handleCreate,
  }))

  const { i18n } = useLanguage()

  const { user } = useUserSettings()
  const { setActivity: setReportActivity, setIsNewActivity } = useReportActivity()

  const { plannedEmployees, handleUpdatePlannedEmployees } = useActivityEmployeePlaner(
    activity,
    user.technician ? [{ id: user.technician.id.toString(), technician: user.technician, technicianType: 'employee' }] : []
  )
  const { times: activityTimes, loadingTimes: loadingActivityTimes } = useActivityTimes(activity)

  const initialValue = activity

  const [ergoTaskId, setErgoTaskId] = useState<number | undefined>(initialValue?.taskId)
  const { ergoTask } = useErgoTask(ergoTaskId)
  const deviceMandatory = useMemo(() => ergoTask?.paramList?.AUTOMATIC_DEVICE_COMPILATION !== '1', [ergoTask])

  const getRequiredFields = useCallback(
    (state?: Activity): EditDataHandlerRequiredFields<Activity> => {
      const fields: EditDataHandlerRequiredFields<Activity> = ['taskId', 'srvActivityTypeId', 'title', 'companyId']
      if (deviceMandatory) {
        fields.push(['objectId', 'device'], ['objectLotId', 'device', 'srvContractLotId'])
      }
      if (!state?.device && state?.objectId) {
        fields.push(['objectLotId'])
      }
      return fields
    },
    [deviceMandatory]
  )

  const { state, editMode, createOrUpdate, handleDataChange, requiredFields } = useEditDataHandler<
    Activity & { plannedEmployeesHash?: string; articleHash?: string }, // adding hash allows to activate save icon for plannedEmployees (since they are not part of the post-object)
    Pick<Activity, 'srvActivityId' | 'srvActivityTypeId' | 'srvActivityYear'>
  >(api.activities.post, api.activities.put, undefined, {
    eventKeyModification: EVENT_KEYS.ACTIVITY_UPDATED,
    onDone: (type, results) => {
      if (type === 'create') {
        const createdActivity = results?.at(0)
        setReportActivity(createdActivity)
        setIsNewActivity(true)
      }
      onDone()
    },
    onUploadStatus: onUploadStatus,
    editMode: !!activity,
    initialValue: initialValue,
    showErrorAlert: true,
    requiredFields: getRequiredFields,
    modifyDataBeforeRequest: data => {
      const request = activityUtils.createBasicRequest(data)
      if (editMode) return request
      if (plannedEmployees?.length) {
        request.createActivityEmployees = activityUtils.convertPlannedEmployeesForRequest(plannedEmployees, [])
      }
      return request
    },
    preDoneRoutine: type => {
      if (type === 'delete' || !editMode) return Promise.resolve()
      const promises: Promise<void>[] = [handleUpdatePlannedEmployees()]
      return Promise.all(promises)
    },
  })

  function handleCreate() {
    if (!state) return

    if (handleCreateActivity) {
      handleCreateActivity({
        ...state,
        createActivityEmployees: activityUtils.convertPlannedEmployeesForRequest(plannedEmployees, []),
      })
    } else {
      createOrUpdate()
    }
  }

  const selectedLotto = useMemo(() => {
    if (state?.objectLotId) return state.objectLotId

    return undefined
  }, [state])

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <CustomerSelector
        editable
        required
        onChange={value => {
          if (value?.companyId === state?.companyId) return
          handleDataChange({
            companyId: value?.companyId,
            destinationId: undefined,
            objectId: undefined,
            objectLotId: undefined,
            contactId: undefined,
            device: undefined,
          })
        }}
        value={state?.companyId}
        {...commonProps}
      />
      {!!state?.companyId && (
        <ContactSelector
          customerId={state?.companyId}
          editable
          canCreateNew
          onChange={value => handleDataChange({ contactId: value?.contactId })}
          disableFastInput
          value={state?.contactId}
          title={i18n.t('CUSTOMER_CONTACT')}
          {...commonProps}
        />
      )}

      <ConstructionSiteSelector
        customerId={state?.companyId}
        value={state?.objectId}
        editable
        onChange={value => {
          if (value?.objectId === state?.objectId) return
          handleDataChange({
            objectId: value?.objectId,
            objectLotId: undefined,
            device: value?.objectId ? undefined : state?.device,
            companyId: value?.companyId ?? state?.companyId,
            objectContactId: undefined,
          })
        }}
        {...commonProps}
      />

      {!!state?.objectId && !state.device && (
        <>
          <ConstructionSiteLottoSelector
            objectId={state.objectId}
            required={ValidationUtils.isRequired('objectLotId', requiredFields)}
            onChange={value => handleDataChange({ objectLotId: value?.lotId, device: undefined, srvContractLotId: undefined })}
            value={selectedLotto}
            {...commonProps}
          />
        </>
      )}
      {!state?.objectLotId && (
        // the device (apparato) is an alternative to the objectLot
        <TextInput
          title={i18n.t('LOTTO_DEVICE')}
          required={ValidationUtils.isRequired('device', requiredFields)}
          placeholder={i18n.t('LOTTO_DEVICE')}
          value={state?.device}
          editable
          onChangeText={value => handleDataChange({ device: value, objectLotId: undefined })}
          showClearTextIcon
          {...commonProps}
        />
      )}
      {state?.companyId && (
        <>
          <DestinationSelector
            customerId={state?.companyId}
            editable
            onChange={value => handleDataChange({ destinationId: value?.destinationId })}
            disableFastInput
            value={state?.destinationId}
            {...commonProps}
          />

          {!!state.objectId && (
            <ConstructionSiteContactSelector
              objectId={state?.objectId}
              editable
              onChange={value => handleDataChange({ objectContactId: value?.contactId })}
              disableFastInput
              value={state?.objectContactId}
              {...commonProps}
            />
          )}
        </>
      )}
      <GroupSpacer />
      <ActivityCreationDefaultInputs
        state={state}
        handleDataChange={data => {
          handleDataChange(data)
          if ('taskId' in data) setErgoTaskId(data.taskId)
        }}
        initialValue={initialValue}
        editMode={editMode}
        hasTimesRegistered={activityTimes?.length ? true : loadingActivityTimes !== false ? 'loading' : false}
        hide={['priority', 'srvTimeSlotId', 'estimatedTime', 'expireDate']}
      />

      <GroupSpacer />

      {editMode && !!activity && (
        <ApiDeleteButton
          onDeleted={() => {
            onDeleted()
            setReportActivity(undefined)
          }}
          data={activity}
          deleteAlertMessage={i18n.t('DELETE_REPORT_ACTIVITY_ALERT')}
          deleteAlertTitle={i18n.t('DELETE_ACTIVITY')}
          deleteRequest={api.activities.delete}
          eventKey={EVENT_KEYS.ACTIVITY_UPDATED}
          additionalRequestData={{ forceDeletion: true }}
          {...commonProps}
        />
      )}
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(ReportActivityCreateOrEditView)
