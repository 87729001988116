import { IM, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Technician } from '../../apis/types/apiResponseTypes'
import EmployeeCard from '../../cards/common/EmployeeCard'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import useObjectUtils from '../../hooks/useObjectUtils'
import { ListSection } from '../../types'
import { EmployeeUtils } from '../../utils/EmployeeUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import { TextInputProps } from '../input/TextInput'
import SelectInput from './selectInput/SelectInput'

type Props = {
  values: number[] | undefined
  onChange: (values: Technician[] | undefined) => void
  noTitle?: boolean
  filterDisabled?: boolean
} & Pick<TextInputProps, 'editable' | 'spacing' | 'disableBorderRadius' | 'placeholder'>

export default function MultiEmployeeSelector({ onChange, values, editable, spacing, noTitle, filterDisabled = true, ...inputProps }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const objectUtils = useObjectUtils<Technician>(apiDtoIds.technicians)
  const { item: data, loadItem, loading } = useControlledLoader(api.technicians.getList)
  const enabledData = useMemo(() => (filterDisabled ? data?.filter(d => !d.disabled) : data), [data, filterDisabled])
  const filteredData = useDataSearchFilter(enabledData, search, ['firstName', 'lastName', 'id'])
  const sortedAndFilteredData = useMemo(() => EmployeeUtils.sort(filteredData), [filteredData])

  const selectedEmployees = useMemo(() => data?.filter(d => values?.includes(d.id)), [values, data])

  const selectedText = useMemo(
    () =>
      EmployeeUtils.sort(selectedEmployees)
        ?.map(se => `• ${EmployeeUtils.getName(se)}`)
        .join('\n') ?? '',
    [selectedEmployees]
  )

  useEffect(() => {
    refresh()
  }, [])

  const refresh = (_text?: string) => loadItem({})

  const render = ({ item }: SectionListRenderItemInfo<Technician, ListSection<Technician>>, onPress?: () => void) => {
    return (
      <EmployeeCard
        employee={item}
        selected={!!selectedEmployees?.find(selected => objectUtils.compare(item, selected))}
        onPress={onPress}
        spacing={['horizontal', 'bottom']}
      />
    )
  }

  function handleEmployeeSelected(value: Technician | undefined) {
    if (!value) {
      onChange(undefined)
      return
    }
    if (selectedEmployees?.find(se => objectUtils.compare(se, value))) {
      onChange(selectedEmployees.filter(se => !objectUtils.compare(se, value)))
      return
    }
    onChange(Utils.keepUniques([...(selectedEmployees ?? []), value], q => objectUtils.createId(q)))
  }

  return (
    <IM.View spacing={spacing}>
      <SelectInput<Technician>
        id={['id', 'technicianType']}
        editable={editable}
        title={noTitle ? undefined : i18n.t('EMPLOYEES')}
        data={sortedAndFilteredData}
        loading={loading}
        value={selectedEmployees?.find(se => !!se)}
        noDataMessage={i18n.t('NO_EMPLOYEE_FOUND')}
        keepOpenOnChange
        onSearchChange={setSearch}
        renderItem={render}
        screenTitle={i18n.t('EMPLOYEES')}
        onChange={handleEmployeeSelected}
        renderSelectedString={selectedText}
        showNoSelectionItem={false}
        multiline
        {...inputProps}
      />
    </IM.View>
  )
}
