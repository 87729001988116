import { IM, IMLayout, useLanguage, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import ActivityPlanStatusTag from '../../../components/ActivityPlanStatusTag'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import useUserSettings from '../../../hooks/useUserSettings'
import { activityUtils } from '../../../utils/ActivityUtils'
import { ActivityListType } from '../../../views/activities/ActivityListView'
import ActivityCardIcon from './ActivityCardIcon'
import ActivityCardInfo from './ActivityCardInfo'
import ActivityCardTimeInfo from './ActivityCardTimeInfo'
import ActivityCardTitle from './ActivityCardTitle'
import ActivityCardTypeInfo from './ActivityCardTypeInfo'

type Props = {
  activity: Activity
  assignable: boolean
  type: ActivityListType
  showIcon?: boolean
  showEmployee?: boolean
  showActivityTypeInfo?: boolean
  forceLayout?: ForceLayoutType
  showPlanTag?: boolean
  onTagPress?: (start: string) => void
}

export default function ActivityCardHeader({
  activity,
  style,
  type,
  showEmployee,
  showIcon,
  forceLayout,
  assignable,
  showActivityTypeInfo = true,
  showPlanTag,
  onTagPress,
  ...props
}: Props & ViewProps) {
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { language } = useLanguage()
  const { user } = useUserSettings()

  const showTimeInfo = useMemo(
    () =>
      !!activity.expireDate ||
      !!activity.closedDate ||
      !!activityUtils.formatNextActivityPlanDate(activity, language, user.isTechnician && user.technician ? user.technician : undefined)?.length,
    [activity, user]
  )

  return (
    <IM.View style={style} {...props}>
      <IM.View style={IMLayout.flex.row}>
        {isSmallDevice && <ActivityCardIcon activity={activity} spacing="right" type={type} forceLayout={forceLayout} />}
        <IM.View style={[IMLayout.flex.f1, type === 'carousel' && styles.map]}>
          <IM.View style={IMLayout.flex.f1}>
            <ActivityCardTitle activity={activity} spacing="bottom" />
            {!isSmallDevice && showTimeInfo && <ActivityCardTimeInfo activity={activity} forceLayout={forceLayout} />}
          </IM.View>
        </IM.View>
        <ActivityCardInfo
          type={type}
          assignable={assignable}
          activity={activity}
          showEmployee={showEmployee}
          showIcon={showIcon}
          forceLayout={forceLayout}
        />
      </IM.View>
      {showPlanTag && <ActivityPlanStatusTag activity={activity} onPress={onTagPress} />}
      {showActivityTypeInfo && <ActivityCardTypeInfo forceLayout={forceLayout} activity={activity} />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  map: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
