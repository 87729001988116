import { IM, IMLayout, useEvent, useLanguage } from '@infominds/react-native-components'
import React, { createRef, memo, useEffect, useState } from 'react'
import { Animated, FlatList, ListRenderItem, Platform } from 'react-native'
import { useRecoilState } from 'recoil'

import { Activity } from '../../apis/types/apiResponseTypes'
import ActivityListCard from '../../cards/activityList/ActivityListCard'
import FlatListData from '../../components/FlatListData'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import Separator from '../../components/Infominds/Separator'
import useScreen from '../../components/screen/hooks/useScreen'
import useSearch from '../../components/screen/hooks/useSearch'
import { ScreenViewType } from '../../components/screen/types'
import { ADD_ACTIVITY_BUTTON_ID } from '../../constants/ButtonIds'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useFilter from '../../hooks/useFilter'
import useLayout from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import { InfiniteLoadingType, LoadingType } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import { activityTabAtom } from '../../utils/stateManager'
import { utils } from '../../utils/utils'
import ActivityListHeader from './ActivityListHeader'

export type ActivityListType = 'normal' | 'carousel' | 'gantt' | 'history'

export type ActivityListProps = {
  activities: (string | Activity)[]
  loading: LoadingType | InfiniteLoadingType
  reloadActivities: () => void
  loadMoreActivities: () => void
  onActivityPressed: (ticket: Activity) => void
  buttonAnimationValue: Animated.Value
  onCreate: () => void
} & (
  | {
      unassignedActivities: (string | Activity)[]
      loadingUnassigned: InfiniteLoadingType
      reloadActivitiesUnassigned: () => void
      loadMoreUnassignedActivities: () => void
    }
  | {
      unassignedActivities?: never
      loadingUnassigned?: never
      reloadActivitiesUnassigned?: never
      loadMoreUnassignedActivities?: never
    }
)

type Props = {
  showSmallDeviceCards?: boolean
  selectedActivity?: Activity | undefined | null
  type: ActivityListType

  showTabs?: boolean
} & ActivityListProps

const ActivityListView = memo(function ActivityListView({
  activities,
  unassignedActivities,
  loading,
  loadingUnassigned,
  buttonAnimationValue,
  showSmallDeviceCards,
  selectedActivity,
  type,
  showTabs,
  reloadActivities,
  reloadActivitiesUnassigned,
  onCreate,
  onActivityPressed,
  loadMoreActivities,
  loadMoreUnassignedActivities,
}: Props) {
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { user } = useUserSettings()
  const { isOnline } = useDataProvider()
  const { filters, groups, orders } = useFilter<Activity>()
  const { view } = useScreen()
  const isMapView = view === ScreenViewType.LOCATION
  const [uuid, setUuid] = useState(utils.generateUuid())

  const [selectedTab, setTab] = useRecoilState(activityTabAtom)
  const tab = showTabs ? selectedTab : 'my'

  const listRef = createRef<FlatList<string | Activity>>()

  useEvent<Activity>({ key: EVENT_KEYS.ACTIVITY_ASSIGNED }, () => {
    setTab('my')
  })

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, groups, orders])

  useEffect(() => {
    setUuid(utils.generateUuid())
  }, [selectedActivity])

  const renderItem: ListRenderItem<string | Activity> | null | undefined = elem => {
    const isFirst = elem.index === 0
    const isLast = elem.index === activities.length - 1
    const item = elem.item

    // group header
    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          {item === i18n.t('UNASSIGNED') && !isFirst && <Separator />}
          <IM.Text>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <ActivityListCard
          activity={item}
          onPress={() => onActivityPressed(item)}
          forceLayout={showSmallDeviceCards && 'small'}
          isSelected={activityUtils.compareActivities(item, selectedActivity)}
          showLocationMissingIndicator={isMapView}
          type={type}
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              marginTop: IMLayout.horizontalMargin,
              marginBottom: isLast ? IMLayout.horizontalMargin : 0,
            },
            Platform.select({
              web: { marginLeft: 2 * IMLayout.horizontalMargin, marginRight: 2 * IMLayout.horizontalMargin },
              native: { marginHorizontal: 2 * IMLayout.horizontalMargin },
            }),
          ]}
        />
      )
    }
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <FlatListData
        ref={listRef}
        data={tab === 'my' ? activities : unassignedActivities}
        loading={tab === 'my' ? loading : loadingUnassigned}
        noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
        renderItem={renderItem}
        isSearching={search !== ''}
        refresh={tab === 'my' ? reloadActivities : reloadActivitiesUnassigned}
        keyExtractor={item => (typeof item === 'string' ? item : activityUtils.createId(item))}
        extraData={uuid}
        listSpacer={isSmallDevice || !showTabs || !unassignedActivities ? !showTabs : 0}
        stickyHeaderIndices={[0]}
        onScrollToIndexFailed={console.error}
        onLoadMore={tab === 'my' ? loadMoreActivities : loadMoreUnassignedActivities}
        ListHeaderComponent={
          <>
            {showTabs && !!unassignedActivities && (
              <ActivityListHeader
                tab={tab}
                setTab={setTab}
                activities={activities}
                unassignedActivities={unassignedActivities}
                loading={loading}
                loadingUnassigned={loadingUnassigned}
                // style={{ position: 'absolute', top: 0, zIndex: 50 }}
              />
            )}
          </>
        }
      />

      {loading === false && isOnline && user.can.add.activities && !!onCreate && (
        <>
          <AnimatedButton id={ADD_ACTIVITY_BUTTON_ID} value={buttonAnimationValue} icon={['fal', 'plus']} onPress={onCreate} />
        </>
      )}
    </IM.View>
  )
})

export default ActivityListView
