import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react'

import { api } from '../../apis/apiCalls'
import { Contact, Customer } from '../../apis/types/apiResponseTypes'
import useEditDataHandler, { EditDataHandlerOptions, EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import AddressInput from '../../components/input/AddressInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import { EVENT_KEYS } from '../../constants/EventKeys'
import { EditOrCreateViewRef, UploadStatus } from '../../types'

type Props = {
  customer?: Customer
  onUploadStatus: (status: UploadStatus) => void
  onDone: EditDataHandlerOptions<Contact, Partial<Contact>>['onDone']
}

function CustomerCreateOrEditView({ customer, onDone, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) {
  const { i18n } = useLanguage()

  useImperativeHandle(ref, () => ({
    handleUpload: createOrUpdate,
  }))

  const initialValue = useMemo(() => customer, [customer])

  const requiredFields = useMemo<EditDataHandlerRequiredFields<Customer>>(() => ['company1'], [])
  const { state, createOrUpdate, handleDataChange } = useEditDataHandler(api.customers.post, undefined, undefined, {
    eventKeyCreation: EVENT_KEYS.UPDATED_ACTIVITY_TYPES,
    eventKeyModification: EVENT_KEYS.UPDATED_ACTIVITY_TYPES,
    onDone: onDone,
    onUploadStatus: onUploadStatus,
    editMode: !!customer,
    initialValue: initialValue,
    showErrorAlert: true,
    requiredFields,
    modifyDataBeforeRequest: data => ({ ...data }),
  })

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <TextInput
        required
        value={state?.company1}
        title={i18n.t('TITLE_COMPANY1')}
        onChangeText={value => handleDataChange({ company1: value })}
        showClearTextIcon
        {...commonProps}
      />
      <TextInput
        value={state?.company2}
        title={i18n.t('TITLE_COMPANY2')}
        onChangeText={value => handleDataChange({ company2: value })}
        showClearTextIcon
        {...commonProps}
      />
      <TextInput
        title={i18n.t('PHONE')}
        placeholder={i18n.t('PHONE')}
        value={state?.phone}
        onChangeText={value => handleDataChange({ phone: value })}
        inputMode="tel"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('PHONE') + ' 2'}
        placeholder={i18n.t('PHONE')}
        value={state?.phone2}
        onChangeText={value => handleDataChange({ phone2: value })}
        inputMode="tel"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('EMAIL')}
        placeholder={i18n.t('EMAIL')}
        value={state?.email}
        onChangeText={value => handleDataChange({ email: value })}
        inputMode="email"
        {...commonProps}
      />
      <TextInput
        title={i18n.t('VAT')}
        placeholder={i18n.t('VAT')}
        value={state?.vatId}
        onChangeText={value => handleDataChange({ vatId: value })}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('VAT')}
        placeholder={i18n.t('VAT')}
        value={state?.vatId}
        onChangeText={value => handleDataChange({ vatId: value })}
        {...commonProps}
      />

      <AddressInput address={state?.address} onValueChange={value => handleDataChange({ address: value })} {...commonProps} />

      {/* {editMode && !!customer && (
        <ApiDeleteButton
          onDeleted={onDone}
          data={customer}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_TYPE_ALERT')}
          deleteAlertTitle={i18n.t('DELETE')}
          deleteRequest={api.activities.types.delete}
          eventKey={EVENT_KEYS.UPDATED_ACTIVITY_TYPES}
        />
      )} */}
    </ScrollViewForm>
  )
}

export default forwardRef(CustomerCreateOrEditView)
