import { IM, IMLayout, IMStyle, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { ListRenderItemInfo } from '@shopify/flash-list'
import Color from 'color'
import React, { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { DnDSource, EventItem, SchedulerData } from 'react-big-scheduler-stch'
import { Animated } from 'react-native'
import useScrollbarSize from 'react-scrollbar-size'
import { useSetRecoilState } from 'recoil'

import { api } from '../../apis/apiCalls'
import { Activity, ActivityState } from '../../apis/types/apiResponseTypes'
import FlashListData from '../../components/FlashListData'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { ADD_ACTIVITY_BUTTON_ID } from '../../constants/ButtonIds'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useExtendedTheme from '../../hooks/useExtendedTheme'
import useFilter from '../../hooks/useFilter'
import ActivityCreationModal from '../../modals/activity/ActivityCreationModal'
import FilterModal from '../../modals/common/FilterModal'
import { CommonStackParamList } from '../../navigation/types'
import { filterUtils } from '../../utils/FilterUtils'
import { plannerActivityFilterCountAtom } from '../../utils/stateManager'
import { ActivityCreateOrEditViewProps } from '../activities/ActivityCreateOrEditView'
import { PlannerSideViewRef } from './PlannerSideView'

const buttonAnimationValue = new Animated.Value(0)

interface Props {
  height: number | undefined
  schedulerData: SchedulerData<EventItem> | undefined
  taskDndSource: DnDSource
  showCreateActivityButton?: boolean
  companyId: number | undefined
  objectId: number | undefined
  newEvent: (schedulerData: SchedulerData, slotId: string, slotName: string, start: string, end: string, type: string, item: EventItem) => void
}

const ActivitiesToBePlannedView = (
  { height, schedulerData, taskDndSource, companyId, objectId, showCreateActivityButton = false, newEvent }: Props,
  ref: ForwardedRef<PlannerSideViewRef>
) => {
  useImperativeHandle(ref, () => ({
    openFilterModal: () => controller.show(undefined),
  }))
  const [search, setSearch] = useState('')
  const setEnableFilter = useSetRecoilState(plannerActivityFilterCountAtom)

  const { item: activities, loadItem: loadActivities, loading: loadingActivities } = useControlledLoader(api.activities.getPlanning)
  useEvent({ key: EVENT_KEYS.GANTT_CHART_TO_PLAN_REFRESH }, () => reload(search))

  const { i18n } = useLanguage()
  const { width } = useScrollbarSize()
  const { theme, colorScheme } = useExtendedTheme()
  const controller = useModalController<CommonStackParamList['Filter']>()
  const createActivityModal = useModalController<ActivityCreateOrEditViewProps>()
  const { filters, filteredItems, initFilters } = useFilter<Activity>(activities)

  const activeFilters = useMemo(() => filterUtils.getActiveFilters(filters), [filters])

  const TaskDnDRenderItem = taskDndSource.getDragSource()

  useEffect(() => {
    reload(search)
  }, [search])

  useEffect(() => {
    if (loadingActivities === false) {
      initFilters(activities ?? [])
    } else {
      setEnableFilter(undefined)
    }
  }, [loadingActivities, activities])

  useEffect(() => {
    setEnableFilter(activeFilters.length)
  }, [activeFilters])

  const reload = (searchString: string) =>
    loadActivities({
      searchText: searchString === '' ? undefined : searchString,
      state: [ActivityState.Open, ActivityState.Suspended],
      assignedUser: true,
      companyId,
      objectId,
    })

  const renderItem = (elem: ListRenderItemInfo<Activity | string>) => {
    if (!activities) return <></>

    const isFirst = elem.index === 0
    const isLast = elem.index === activities.length - 1
    const item = elem.item

    return (
      <TaskDnDRenderItem
        newEvent={newEvent}
        schedulerData={schedulerData}
        activity={item}
        forceLayout="small"
        type="gantt"
        spacing="none"
        style={
          // eslint-disable-next-line react-native/no-inline-styles
          {
            marginTop: isFirst ? 0 : IMLayout.horizontalMargin,
            marginBottom: isLast ? IMLayout.horizontalMargin : 0,
            marginLeft: IMLayout.horizontalMargin,
            marginRight: width + IMLayout.horizontalMargin,
          }
        }
      />
    )
  }

  return (
    <>
      <IM.View style={[height === undefined && IMLayout.flex.f1, { height }]}>
        {height === undefined && (
          <IM.SearchBox
            initialValue={search}
            placeholder={i18n.t('SEARCH_ACTIVITY_TO_BE_PLANNED_PLACEHOLDER')}
            onEnterEnd={setSearch}
            placeholderTextColor={colorScheme === 'dark' ? theme.textPlaceholder : Color(IMStyle.palette.white).darken(0.26).toString()}
            inputMode="search"
            hideIcon
            hideBorder
            autoCapitalize="none"
            height={32}
            spacing="all"
          />
        )}
        <FlashListData
          data={filteredItems}
          loading={loadingActivities}
          noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
          renderItem={renderItem}
          refresh={() => reload(search)}
          listSpacer={4}
          ListFooterComponent={<></>}
        />
      </IM.View>
      {showCreateActivityButton && (
        <AnimatedButton
          id={ADD_ACTIVITY_BUTTON_ID}
          value={buttonAnimationValue}
          icon={['fal', 'plus']}
          onPress={() => createActivityModal.show({})}
        />
      )}
      <FilterModal controller={controller} />
      <ActivityCreationModal controller={createActivityModal} />
    </>
  )
}

export default memo(forwardRef(ActivitiesToBePlannedView))
