const APP_NAME = 'ErgoService.'

export const STORAGE_KEYS = {
  CALENDAR_VIEW: APP_NAME + 'CalendarView',
  CUSTOMER_DETAILS_TAB: APP_NAME + 'CustomerDetailsTab',
  FILTER_CONTEXT_GROUP: APP_NAME + 'FilterContextGroupStorage',
  LAST_USED_CUSTOMERS: APP_NAME + 'LastUsedCustomers',
  MENU_ORDER: APP_NAME + 'MenuPreference',
  NAVIGATION_STATE: APP_NAME + 'NavigationState',
  SAVE_MEDIA_ON_STORAGE: APP_NAME + 'saveMediaOnStorage',
  SAVE_PLANNING_DAYS_ON_STORAGE: APP_NAME + 'savePlanningDaysOnStorage',
  PLANNER_SIDE_VIEW_TYPE: APP_NAME + 'PlannerSideViewType',
  PLANNER_SHOW_ACTIVITY_EXTENDED_VIEW: APP_NAME + 'PlannerShowActivityExtendedView',
  PLANNER_SHOW_CLOSED_ACTIVITIES: APP_NAME + 'PlannerShowClosedActivities',
  TICKET_VIEW: APP_NAME + 'TicketView',
  DRAWER_OPEN: APP_NAME + 'DrawerOpen',
  ACTIVITY_TAB: APP_NAME + 'ActivityTab',
  ANAGRAFICS_TAB: APP_NAME + 'AnagraficsTab',
  MASTER_DETAIL: APP_NAME + 'MasterDetailValue',
  DATA_PROVIDER_STATE: APP_NAME + 'dataProviderState',
  GANTT_CHART_VIEW: APP_NAME + 'GanttChartView',
  GANTT_CHART_SELECTED_TECHNICIANS: APP_NAME + 'GanttChartSelectedTechnicians',
  GANTT_CHART_DATE_LABEL: APP_NAME + 'GanttChartDateLabel',
  GANTT_CHART_SELECTED_QUALIFICATION: APP_NAME + 'GanttChartSelectedQualification',
  GANTT_CHART_CLOSED_PLANNER_SIDE_VIEW: APP_NAME + 'GanttChartClosedPlannerSideView',
  REPORT_ACTIVITY: APP_NAME + 'ReportActivity',
}
