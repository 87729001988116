import { IM, IMLayout, useLanguage, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { Activity } from '../../../apis/types/apiResponseTypes'
import { DynamicLayoutView } from '../../../components/DynamicLayoutView'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import useUserSettings from '../../../hooks/useUserSettings'
import { activityUtils } from '../../../utils/ActivityUtils'
import TimeUtils from '../../../utils/TimeUtils'

type Props = {
  activity: Activity
  forceLayout?: ForceLayoutType
}

export default function ActivityCardTimeInfo({ activity, style, forceLayout, ...props }: Props & ViewProps) {
  const { language } = useLanguage()
  const { theme } = useTheme()
  const { user } = useUserSettings()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const expireDate = useMemo(() => activity.expireDate && TimeUtils.format(activity.expireDate, language), [activity, language])
  const dateText = useMemo(
    () =>
      activity.closedDate
        ? TimeUtils.format(activity.closedDate, language)
        : activityUtils.formatNextActivityPlanDate(activity, language, user.isTechnician && user.technician ? user.technician : undefined),
    [activity, language]
  )

  // date + time is 24 characters long, but if dateFrom !== dateTo then it gets longer and needs more space
  const dateTextIsLarge = !!dateText && dateText?.length > 30

  return (
    <>
      <DynamicLayoutView forceLayout={forceLayout}>
        <DynamicLayoutView.Small>
          <IM.View style={[!dateTextIsLarge && [IMLayout.flex.row, IMLayout.flex.f1, { gap: 2 * IMLayout.horizontalMargin }], style]} {...props}>
            {!!dateText && (
              <IM.TextWithIcon icon={activity.closedDate ? ['fal', 'calendar-check'] : ['fal', 'calendar']} iconColor={theme.textDetail}>
                {dateText}
              </IM.TextWithIcon>
            )}
            {expireDate && (
              <IM.View style={[IMLayout.flex.f1, isSmallDevice && !!dateText && !dateTextIsLarge && styles.alignEnd]}>
                <IM.TextWithIcon icon={['fal', 'hourglass-clock']} iconColor={theme.textDetail}>
                  {expireDate}
                </IM.TextWithIcon>
              </IM.View>
            )}
          </IM.View>
        </DynamicLayoutView.Small>
        <DynamicLayoutView.Large>
          <IM.View style={[style]} spacing={'bottom'} {...props}>
            {!!dateText && (
              <IM.View style={[IMLayout.flex.row, styles.dateViewLargeDevice]}>
                <IM.TextWithIcon viewStyle={IMLayout.flex.f1} style={IMLayout.flex.f1} icon={['fal', 'calendar']} iconColor={theme.textDetail}>
                  {dateText}
                </IM.TextWithIcon>
              </IM.View>
            )}
            {expireDate && (
              <IM.View style={[isSmallDevice && styles.alignEnd]}>
                <IM.TextWithIcon icon={['fal', 'hourglass-clock']} iconColor={theme.textDetail}>
                  {expireDate}
                </IM.TextWithIcon>
              </IM.View>
            )}
          </IM.View>
        </DynamicLayoutView.Large>
      </DynamicLayoutView>
    </>
  )
}

const styles = StyleSheet.create({
  dateViewLargeDevice: {
    justifyContent: 'space-between',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
})
