import { useAppNews } from '@infominds/react-native-app-news'
import { IM, openTeamViewer, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { CommonActions, useNavigation } from '@react-navigation/native'
import React, { createRef, useEffect, useState } from 'react'
import { Platform, ScrollView, StyleSheet } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import UserCard from '../../cards/settings/UserCard'
import Pressable from '../../components/Infominds/Pressable'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import LanguageSelector, { LanguageSelectorRef } from '../../components/LanguageSelector'
import MandantSelector, { MandantSelectorRef } from '../../components/MandantSelector'
import SettingsRow from '../../components/SettingsRow'
import SettingsRowContainer from '../../components/SettingsRowContainer'
import Switch from '../../components/Switch'
import CONSTANTS from '../../constants/Constants'
import useEmbed from '../../hooks/useEmbed'
import usePWAInstall from '../../hooks/usePWAInstall'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'
import {
  apiVersionAtom,
  navigationRefAtom,
  plannerShowActivityExtendedViewAtom,
  plannerShowClosedActivitiesAtom,
  revalidateSettingsAtom,
  saveMediaOnStorageAtom,
} from '../../utils/stateManager'

export default function SettingsView() {
  const navigation = useNavigation()
  const navigationRef = useRecoilValue(navigationRefAtom)
  const { i18n } = useLanguage()
  const { embed } = useEmbed()
  const { theme, updateColorScheme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { url, name, license, sessionKey } = useAuthentication()
  const { user } = useUserSettings()
  const { pwaInstall, supported, isInstalled } = usePWAInstall()
  const { appNewsVideoAvailable, showAppNewsVideo } = useAppNews()

  const [companyOpen, setCompanyOpen] = useState(false)
  const [languageOpen, setLanguageOpen] = useState(false)
  const [themeReady, setThemeReady] = useState(true)
  const [isDark, setIsDark] = useState(colorScheme === 'dark')
  const revalidateSettings = useSetRecoilState(revalidateSettingsAtom)
  const [saveMediaOnStorage, setSaveMediaOnStorage] = useRecoilState(saveMediaOnStorageAtom(sessionKey))
  const [showExtended, setShowExtended] = useRecoilState(plannerShowActivityExtendedViewAtom(sessionKey))
  const [showClosedActivities, setShowClosedActivities] = useRecoilState(plannerShowClosedActivitiesAtom(sessionKey))

  const apiVersion = useRecoilValue(apiVersionAtom)

  const mandantRef = createRef<MandantSelectorRef>()
  const languageRef = createRef<LanguageSelectorRef>()

  useEffect(() => {
    if ((isDark && colorScheme === 'dark') || (!isDark && colorScheme === 'light')) {
      setThemeReady(true)
    }
  }, [colorScheme, isDark, themeReady])

  useEffect(() => {
    languageOpen && mandantRef.current?.close()
  }, [languageOpen])

  useEffect(() => {
    companyOpen && languageRef.current?.close()
  }, [companyOpen])

  const changeColorScheme = () => {
    !__DEV__ &&
      navigationRef &&
      navigation.dispatch(
        CommonActions.reset({
          ...navigationRef.getState(),
          routes: [
            {
              ...navigationRef.getState().routes[0],
              // @ts-ignore to understand
              state: {
                ...navigationRef.getState().routes[0].state,
                //@ts-ignore: todo
                routes: navigationRef.getState().routes[0].state?.routes.map(elem => {
                  return { name: elem.name, key: elem.key }
                }),
              },
            },
          ],
        })
      )

    updateColorScheme(colorScheme === 'dark' ? 'light' : 'dark')
  }

  const onChangeTheme = () => {
    setThemeReady(false)
    setIsDark(prev => !prev)
    requestAnimationFrame(changeColorScheme)
  }

  const resetApp = () => {
    navigation.reset({
      routes: [
        {
          name: 'BottomTab',
          state: {
            routes: [{ name: 'SettingsStack', state: { routes: [{ name: 'Setting' }] } }],
          },
        },
      ],
    })

    revalidateSettings(true)
  }

  const installPWAPress = () => {
    pwaInstall().catch(() => console.debug('User opted out from installing'))
  }

  return (
    <>
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          {!embed && <UserCard />}

          {!embed && (
            <SettingsRowContainer title={i18n.t('APP')} spacing={!embed ? 'bottom' : 'vertical'}>
              <SettingsRow title={i18n.t('SETTINGS_DARKMODE')}>
                {themeReady ? (
                  <Switch onValueChange={onChangeTheme} value={isDark} />
                ) : (
                  <IM.View style={styles.loading}>
                    <IM.LoadingSpinner isVisible size="small" />
                  </IM.View>
                )}
              </SettingsRow>

              {/* eslint-disable-next-line react-native/no-inline-styles */}
              <SettingsRow title={i18n.t('SETTINGS_LANGUAGE')} style={[styles.dropdownRow, { zIndex: languageOpen ? 2 : 1 }]}>
                <LanguageSelector
                  ref={languageRef}
                  onOpen={() => {
                    setCompanyOpen(false)
                    setLanguageOpen(true)
                  }}
                  onReLogIn={resetApp}
                />
              </SettingsRow>

              {/* eslint-disable-next-line react-native/no-inline-styles */}
              <SettingsRow title={i18n.t('SETTINGS_MANDANT')} style={[styles.dropdownRow, { zIndex: !languageOpen ? 2 : 1 }]}>
                <MandantSelector
                  ref={mandantRef}
                  onOpen={() => {
                    setCompanyOpen(true)
                    setLanguageOpen(false)
                  }}
                  onReLogIn={resetApp}
                />
              </SettingsRow>

              <SettingsRow title={i18n.t('SETTINGS_SUPPORT')} style={styles.dropdownRow}>
                <Pressable
                  onPress={() => {
                    openTeamViewer().catch(err => console.error('Open teamViewer error', err))
                  }}>
                  <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
                    {i18n.t('OPEN')}
                  </TextWithIcon>
                </Pressable>
              </SettingsRow>

              {appNewsVideoAvailable && (
                <SettingsRow title={i18n.t('VERSION_NEWS_VIDEO')} style={styles.dropdownRow}>
                  <Pressable onPress={showAppNewsVideo}>
                    <TextWithIcon secondary alignIcon="right" icon={['fas', 'clapperboard-play']} color={theme.textDetail}>
                      {i18n.t('VIDEO')}
                    </TextWithIcon>
                  </Pressable>
                </SettingsRow>
              )}

              {Platform.OS === 'web' && supported() && !isInstalled() && (
                <SettingsRow title={i18n.t('SETTINGS_INSTALL')} style={styles.dropdownRow}>
                  <Pressable onPress={installPWAPress}>
                    <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
                      {i18n.t('APP_INSTALL')}
                    </TextWithIcon>
                  </Pressable>
                </SettingsRow>
              )}
            </SettingsRowContainer>
          )}

          {Platform.OS !== 'web' && (
            <SettingsRowContainer title={i18n.t('MEDIA')} spacing="vertical">
              <SettingsRow title={i18n.t('SETTINGS_SAVE_MEDIA')}>
                <Switch onValueChange={setSaveMediaOnStorage} value={saveMediaOnStorage} />
              </SettingsRow>
            </SettingsRowContainer>
          )}

          {Platform.OS === 'web' && user.can.see.planer && (
            <SettingsRowContainer title={i18n.t('TAB_PLANNING')} spacing="vertical" style={styles.planningContainer}>
              <SettingsRow title={i18n.t('SETTINGS_ACTIVITY_EXTENDED_VIEW')}>
                <Switch onValueChange={setShowExtended} value={showExtended} />
              </SettingsRow>
              <SettingsRow title={i18n.t('SETTINGS_SHOW_CLOSED_ACTIVITIES')}>
                <Switch onValueChange={setShowClosedActivities} value={showClosedActivities} />
              </SettingsRow>
            </SettingsRowContainer>
          )}

          <SettingsRowContainer title={i18n.t('INFO')} spacing="vertical">
            <SettingsRow title={i18n.t('SETTINGS_VERSION')} compact>
              <IM.Text secondary selectable style={styles.text}>
                {Platform.OS === 'web' ? CONSTANTS.appName : DeviceInfo.getApplicationName()} v
                {Platform.OS === 'web' ? process.env.VERSION : DeviceInfo.getVersion()}
                {(Platform.OS === 'android' || Platform.OS === 'ios') && ` (${DeviceInfo.getBuildNumber()})`}
              </IM.Text>
            </SettingsRow>
            <SettingsRow title={i18n.t('SETTINGS_API_VERSION')} compact>
              <IM.Text secondary selectable style={styles.text}>
                v{apiVersion}
              </IM.Text>
            </SettingsRow>
            <SettingsRow title={i18n.t('SETTINGS_LICENSE')} compact>
              <IM.Text secondary selectable style={styles.text}>
                {license}
              </IM.Text>
            </SettingsRow>
            <SettingsRow title={i18n.t('USERNAME')} compact>
              <IM.Text secondary selectable style={styles.text}>
                {name}
              </IM.Text>
            </SettingsRow>
            <SettingsRow title={i18n.t('SETTINGS_SERVER')} compact>
              <IM.Text secondary selectable style={styles.text}>
                {url}
              </IM.Text>
            </SettingsRow>
          </SettingsRowContainer>
        </IM.View>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  dropdownRow: { zIndex: 1 },
  text: { textAlign: 'right', flex: 3 },
  loading: {
    ...Platform.select({
      native: { paddingTop: 12, paddingBottom: 11 },
      web: { paddingTop: 2, paddingBottom: 1 },
    }),
    paddingRight: 10,
  },
  planningContainer: { zIndex: -1 },
})
