import { useTheme } from '@infominds/react-native-components'
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack'
import React from 'react'
import { Platform } from 'react-native'

import { GetActivityRequest } from '../apis/types/apiRequestTypes'
import { Activity } from '../apis/types/apiResponseTypes'
import DrawerNavigator from '../components/Infominds/DrawerNavigator'
import TabNavigator from '../components/Infominds/TabNavigator'
import { MasterDetailProvider } from '../components/MasterDetail/contexts/MasterDetailContext'
import { ActivityFilterConfig, HistoryFilterConfig, ReportActivityFilterConfig } from '../constants/Filters'
import { ActivityDetailContextProvider } from '../contexts/activity/ActivityDetailContext'
import { FilterProvider } from '../contexts/FilterContext'
import { ReportActivityProvider } from '../contexts/report/ReportActivityContext'
import { SignatureProvider } from '../contexts/SignatureContext'
import { VaultProvider } from '../contexts/VaultContext'
import useLayout from '../hooks/useLayout'
import useUserSettings from '../hooks/useUserSettings'
import ActivitiesScreen from '../screens/activity/ActivitiesScreen'
import ActivityArticleEditScreen from '../screens/activity/ActivityArticleEditScreen'
import ActivityArticleSelectionScreen from '../screens/activity/ActivityArticleSelectionScreen'
import ActivityClosingScreen from '../screens/activity/ActivityClosingScreen'
import ActivityCreateOrEditScreen from '../screens/activity/ActivityCreateOrEditScreen'
import ActivityDetailScreen from '../screens/activity/ActivityDetailScreen'
import ActivityDetailWorkScreen from '../screens/activity/ActivityDetailWorkScreen'
import ActivityMediaArticleScreen from '../screens/activity/ActivityMediaArticleScreen'
import ActivityTimeCreateOrEditScreen from '../screens/activity/ActivityTimeCreateOrEditScreen'
import ActivityTravelCreateOrEditScreen from '../screens/activity/ActivityTravelCreateOrEditScreen'
import ActivityTypeCreationScreen from '../screens/activity/activityTypes/ActivityTypeCreationScreen'
import ActivityTypeListScreen from '../screens/activity/activityTypes/ActivityTypeListScreen'
import ContractDetailScreen from '../screens/activity/contracts/ContractDetailScreen'
import ContractListScreen from '../screens/activity/contracts/ContractListScreen'
import TimeSlotCreationScreen from '../screens/activity/timeSlots/TimeSlotCreationScreen'
import TimeSlotListScreen from '../screens/activity/timeSlots/TimeSlotListScreen'
import UnavailableTypeCreationScreen from '../screens/activity/unavailables/UnavailableTypeCreationScreen'
import UnavailableTypeListScreen from '../screens/activity/unavailables/UnavailableTypeListScreen'
import AnagraficsScreen from '../screens/anagrafics/AnagraficsScreen'
import CustomerDetailsScreen from '../screens/customer/CustomerDetailScreen'
import CustomersListScreen from '../screens/customer/CustomersListScreen'
import HistoryDetailScreen from '../screens/history/HistoryDetailScreen'
import HistoryScreen from '../screens/history/HistoryScreen'
import LoginScreen from '../screens/login/LoginScreen'
import EditNavigationScreen from '../screens/more/EditNavigationScreen'
import EmptyMoreScreen from '../screens/more/EmptyMoreScreen'
import MoreScreen from '../screens/more/MoreScreen'
import NotFoundScreen from '../screens/notFound/NotFoundScreen'
import PlannerScreen from '../screens/planning/PlannerScreen'
import ReportClosingScreen from '../screens/report/ReportClosingScreen'
import ReportDetailScreen from '../screens/report/ReportDetailScreen'
import ReportScreen from '../screens/report/ReportScreen'
import ReportPdfScreen from '../screens/reportPDF/ReportPdfScreen'
import SettingsScreen from '../screens/settings/SettingsScreen'
import SignatureScreen from '../screens/SignatureScreen'
import {
  ActivityCreationStackParamList,
  ActivityDetailStackParamList,
  ActivityStackParamList,
  ActivityTypeStackParamList,
  AnagraficsStackParamList,
  BottomTabParamList,
  ContractDetailStackParamList,
  ContractStackParamList,
  CustomerListStackParamList,
  CustomerStackParamList,
  HistoryDetailStackParamList,
  HistoryStackParamList,
  PlannerStackParamList,
  ReportStackParamList,
  RootStackParamList,
  SettingsStackParamList,
  TabNavigationScreen,
  TimeSlotStackParamList,
  UnavailableTypeStackParamList,
} from './types'

export const StackNavigationOptions: NativeStackNavigationOptions = {
  headerShown: false,
  animation: Platform.OS === 'ios' ? 'default' : 'fade',
}

export const BottomTabs = {
  activities: {
    id: 0,
    name: 'ActivitiesStack',
    component: ActivityStackNavigator,
    icon: ['fal', 'list'],
    title: 'TAB_ACTIVITIES',
    iconSize: 20,
    options: {
      lazy: false,
    },
  },
  customers: {
    id: 1,
    name: 'CustomersStack',
    component: CustomersStackNavigator,
    icon: ['fal', 'building'],
    title: 'CUSTOMERS',
    iconSize: 19,
  },
  report: {
    id: 3,
    name: 'ReportStack',
    component: ReportStackNavigator,
    icon: ['fal', 'ticket'],
    title: 'TAB_ACTIVITIES',
    iconSize: 21,
  },
  anagrafics: {
    id: 4,
    name: 'AnagraficsStack',
    component: AnagraficsStackNavigator,
    icon: ['fal', 'database'],
    title: 'TAB_ANAGRAFICS',
    iconSize: 21,
  },
  history: {
    id: 5,
    name: 'HistoryStack',
    component: HistoryStackNavigator,
    icon: ['fal', 'box-archive'],
    title: 'TAB_HISTORY',
    iconSize: 21,
  },
  planing: {
    id: 6,
    name: 'PlannerStack',
    component: PlanningStackNavigator,
    icon: ['fal', 'chart-gantt'],
    title: 'TAB_PLANNING',
    iconSize: 21,
  },
  // synchronization: {
  //   id: 7,
  //   name: 'SynchronizationStack',
  //   component: SynchronizationStackNavigator,
  //   icon: ['fal', 'cloud-arrow-down'],
  //   title: i18n.t('TAB_SYNCHRONIZATION'),
  //   iconSize: 21,
  // },

  more: {
    id: 8,
    isFixed: true,
    name: 'MoreEmpty',
    component: EmptyMoreScreen,
    icon: ['fal', 'bars'],
    title: 'TAB_MORE',
    iconSize: 19,
    listeners: ({ navigation }) => {
      return {
        tabPress: event => {
          event.preventDefault()
          navigation.navigate('More')
        },
      }
    },
  },
  settings: {
    id: 10,
    name: 'SettingsStack',
    component: SettingsStackNavigator,
    icon: ['fal', 'gear'],
    title: 'TAB_SETTINGS',
    iconSize: 20,
  },
} satisfies Record<string, TabNavigationScreen<keyof BottomTabParamList>>

const ActivitiesStack = createNativeStackNavigator<ActivityStackParamList>()
export function ActivityStackNavigator() {
  return (
    <FilterProvider
      storageKeyUniqueId="ActivitiesStack"
      apiFilterConfig={ActivityFilterConfig.apiFilter}
      groupConfig={ActivityFilterConfig.group}
      orderConfig={ActivityFilterConfig.order}>
      <ActivitiesStack.Navigator screenOptions={StackNavigationOptions}>
        <ActivitiesStack.Screen name="Activities" component={ActivitiesScreen} />
      </ActivitiesStack.Navigator>
    </FilterProvider>
  )
}

const ActivityDetailStack = createNativeStackNavigator<ActivityDetailStackParamList>()
function ActivityDetailStackNavigator() {
  return (
    <ActivityDetailContextProvider>
      <SignatureProvider>
        <ActivityDetailStack.Navigator screenOptions={StackNavigationOptions}>
          <ActivityDetailStack.Screen name="ActivityDetail" component={ActivityDetailScreen} />
          <ActivityDetailStack.Screen name="ActivityDetailWork" component={ActivityDetailWorkScreen} />
          <ActivityDetailStack.Screen name="ActivityCustomerStack" component={CustomerStackNavigator} />
          <ActivityDetailStack.Screen name="ActivityEditStack" component={ActivityCreationStackNavigator} />
          <ActivityDetailStack.Screen name="ActivityTimeEditScreen" component={ActivityTimeCreateOrEditScreen} />
          <ActivityDetailStack.Screen name="ActivityTravelEditScreen" component={ActivityTravelCreateOrEditScreen} />
          <ActivityDetailStack.Screen name="ActivityArticleSelectionScreen" component={ActivityArticleSelectionScreen} />
          <ActivityDetailStack.Screen name="ActivityArticleEditScreen" component={ActivityArticleEditScreen} />
          <ActivityDetailStack.Screen name="ActivityMediaArticleScreen" component={ActivityMediaArticleScreen} />
          <ActivityDetailStack.Screen name="ActivityClosing" component={ActivityClosingScreen} />
        </ActivityDetailStack.Navigator>
      </SignatureProvider>
    </ActivityDetailContextProvider>
  )
}

const AnagraficsStack = createNativeStackNavigator<AnagraficsStackParamList>()
function AnagraficsStackNavigator() {
  return (
    <AnagraficsStack.Navigator screenOptions={StackNavigationOptions}>
      <AnagraficsStack.Screen name="Anagrafics" component={AnagraficsScreen} />
      <AnagraficsStack.Screen name="AnagraficsActivityTypeStack" component={ActivityTypeStackNavigator} />
      <AnagraficsStack.Screen name="AnagraficsTimeSlotStack" component={TimeSlotStackNavigator} />
      <AnagraficsStack.Screen name="AnagraficsContractStack" component={ContractStackNavigator} />
      <AnagraficsStack.Screen name="AnagraficsUnavailableTypeStack" component={UnavailableTypeStackNavigator} />
    </AnagraficsStack.Navigator>
  )
}

const CustomerStack = createNativeStackNavigator<CustomerStackParamList>()
function CustomerStackNavigator() {
  return (
    <CustomerStack.Navigator screenOptions={StackNavigationOptions}>
      <CustomerStack.Screen name="CustomerDetail" component={CustomerDetailsScreen} />
    </CustomerStack.Navigator>
  )
}

const CustomersStack = createNativeStackNavigator<CustomerListStackParamList>()
export function CustomersStackNavigator() {
  return (
    <VaultProvider>
      <CustomersStack.Navigator screenOptions={StackNavigationOptions}>
        <CustomersStack.Screen name="CustomersList" component={CustomersListScreen} />
      </CustomersStack.Navigator>
    </VaultProvider>
  )
}

// const SynchronizationStack = createNativeStackNavigator<SynchronizationStackParamList>()
// function SynchronizationStackNavigator() {
//   return (
//     <SynchronizationStack.Navigator screenOptions={screenOptions}>
//       <SynchronizationStack.Screen name="Synchronization" component={SynchronizationScreen} />
//     </SynchronizationStack.Navigator>
//   )
// }

const SettingsStack = createNativeStackNavigator<SettingsStackParamList>()
function SettingsStackNavigator() {
  return (
    <SettingsStack.Navigator screenOptions={StackNavigationOptions}>
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}

const ActivityTypeStack = createNativeStackNavigator<ActivityTypeStackParamList>()
function ActivityTypeStackNavigator() {
  return (
    <ActivityTypeStack.Navigator screenOptions={StackNavigationOptions}>
      <ActivityTypeStack.Screen name="ActivityTypeList" component={ActivityTypeListScreen} />
    </ActivityTypeStack.Navigator>
  )
}

const TimeSlotStack = createNativeStackNavigator<TimeSlotStackParamList>()
function TimeSlotStackNavigator() {
  return (
    <TimeSlotStack.Navigator screenOptions={StackNavigationOptions}>
      <TimeSlotStack.Screen name="TimeSlotList" component={TimeSlotListScreen} />
    </TimeSlotStack.Navigator>
  )
}

const ContractStack = createNativeStackNavigator<ContractStackParamList>()
function ContractStackNavigator() {
  return (
    <ContractStack.Navigator screenOptions={StackNavigationOptions} key={'contractStack'}>
      <ContractStack.Screen name="ContractList" component={ContractListScreen} />
    </ContractStack.Navigator>
  )
}

const ContractDetailStack = createNativeStackNavigator<ContractDetailStackParamList>()
function ContractDetailStackNavigator() {
  return (
    <ContractDetailStack.Navigator screenOptions={StackNavigationOptions} key={'contractDetailStack'}>
      <ContractDetailStack.Screen name="ContractDetail" component={ContractDetailScreen} />
      <ContractDetailStack.Screen name="ContractActivityCreation" component={ActivityCreationStackNavigator} />
      <ContractDetailStack.Screen name="ContractActivityDetailStack" component={ActivityDetailStackNavigator} />
    </ContractDetailStack.Navigator>
  )
}

const ActivityCreationStack = createNativeStackNavigator<ActivityCreationStackParamList>()
export function ActivityCreationStackNavigator() {
  return (
    <ActivityCreationStack.Navigator screenOptions={StackNavigationOptions}>
      <ActivityCreationStack.Screen name="ActivityCreation" component={ActivityCreateOrEditScreen} />
    </ActivityCreationStack.Navigator>
  )
}

const UnavailableTypeStack = createNativeStackNavigator<UnavailableTypeStackParamList>()
function UnavailableTypeStackNavigator() {
  return (
    <UnavailableTypeStack.Navigator screenOptions={StackNavigationOptions}>
      <UnavailableTypeStack.Screen name="UnavailableTypeList" component={UnavailableTypeListScreen} />
    </UnavailableTypeStack.Navigator>
  )
}

const PlannerStack = createNativeStackNavigator<PlannerStackParamList>()
function PlanningStackNavigator() {
  return (
    <PlannerStack.Navigator screenOptions={StackNavigationOptions}>
      <PlannerStack.Screen name="Planner" component={PlannerScreen} />
      <PlannerStack.Screen name="PlannerEmbed" component={PlannerScreen} />
    </PlannerStack.Navigator>
  )
}

const HistoryStack = createNativeStackNavigator<HistoryStackParamList>()
function HistoryStackNavigator() {
  const { isMediumDevice } = useLayout(true)
  return (
    <FilterProvider<Activity, void, GetActivityRequest> storageKeyUniqueId="HistoryStack" apiFilterConfig={HistoryFilterConfig.apiFilter}>
      <MasterDetailProvider storageKey="HistoryMasterDetail" forceSmallDevice={isMediumDevice}>
        <ActivityDetailContextProvider>
          <HistoryStack.Navigator screenOptions={StackNavigationOptions}>
            <HistoryStack.Screen name="History" component={HistoryScreen} />
          </HistoryStack.Navigator>
        </ActivityDetailContextProvider>
      </MasterDetailProvider>
    </FilterProvider>
  )
}

const HistoryDetailStack = createNativeStackNavigator<HistoryDetailStackParamList>()
function HistoryDetailStackNavigator() {
  return (
    <FilterProvider<Activity, void, GetActivityRequest> storageKeyUniqueId="HistoryDetailStack" apiFilterConfig={HistoryFilterConfig.apiFilter}>
      <ActivityDetailContextProvider>
        <HistoryDetailStack.Navigator screenOptions={StackNavigationOptions}>
          <HistoryDetailStack.Screen name="Detail" component={HistoryDetailScreen} />
          <HistoryDetailStack.Screen name="ActivityArticleEditScreen" component={ActivityArticleEditScreen} />
          <HistoryDetailStack.Screen name="ActivityMediaArticleScreen" component={ActivityMediaArticleScreen} />
        </HistoryDetailStack.Navigator>
      </ActivityDetailContextProvider>
    </FilterProvider>
  )
}

const ReportStack = createNativeStackNavigator<ReportStackParamList>()
function ReportStackNavigator() {
  const { isMediumDevice } = useLayout(true)
  return (
    <ReportActivityProvider>
      <FilterProvider
        storageKeyUniqueId="ReportStack"
        apiFilterConfig={ReportActivityFilterConfig.apiFilter}
        orderConfig={ReportActivityFilterConfig.order}>
        <MasterDetailProvider forceSmallDevice={isMediumDevice}>
          <ActivityDetailContextProvider>
            <ReportStack.Navigator screenOptions={StackNavigationOptions}>
              <ReportStack.Screen name="ReportScreen" component={ReportScreen} />
              <ReportStack.Screen name="Detail" component={ReportDetailScreen} />
              <ReportStack.Screen name="ActivityCustomerStack" component={CustomerStackNavigator} />
              <ReportStack.Screen name="ActivityTimeEditScreen" component={ActivityTimeCreateOrEditScreen} />
              <ReportStack.Screen name="ActivityTravelEditScreen" component={ActivityTravelCreateOrEditScreen} />
              <ReportStack.Screen name="ActivityArticleSelectionScreen" component={ActivityArticleSelectionScreen} />
              <ReportStack.Screen name="ActivityArticleEditScreen" component={ActivityArticleEditScreen} />
              <ReportStack.Screen name="ActivityMediaArticleScreen" component={ActivityMediaArticleScreen} />
              <ReportStack.Screen name="ReportClosing" component={ReportClosingScreen} />
            </ReportStack.Navigator>
          </ActivityDetailContextProvider>
        </MasterDetailProvider>
      </FilterProvider>
    </ReportActivityProvider>
  )
}

const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
  animation: Platform.OS === 'ios' ? 'default' : 'fade',
}

// ############################ Root Navigator ############################

const RootStack = createNativeStackNavigator<RootStackParamList>()
export function RootStackNavigator() {
  const { theme } = useTheme()
  const { isOnlyReport } = useUserSettings()

  return (
    <RootStack.Navigator
      screenOptions={{
        ...screenOptions,
        navigationBarColor: theme.tabNavigator.background,
        gestureEnabled: Platform.OS !== 'web', // This is to enable the navigation event listener "beforeRemove" on web
      }}>
      <RootStack.Group>
        <RootStack.Screen name="BottomTab" initialParams={{ screen: isOnlyReport ? 'ReportStack' : 'ActivitiesStack' }}>
          {() =>
            Platform.OS === 'web' ? (
              <DrawerNavigator
                screenOptions={{ lazy: true }} // set lazy to true to optimize performance. lazy = false is only required to navigate to stacks that are not mounted yet. Maybe a better solution can be found
              />
            ) : (
              <TabNavigator
                screenOptions={{ lazy: true }} // set lazy to true to optimize performance. lazy = false is only required to navigate to stacks that are not mounted yet. Maybe a better solution can be found
              />
            )
          }
        </RootStack.Screen>
        {/* Alternative: https://github.com/th3rdwave/react-navigation-bottom-sheet */}
        <RootStack.Screen name="More" component={MoreScreen} options={{ presentation: 'containedTransparentModal', animation: 'fade' }} />
        <RootStack.Screen
          name="EditNavigation"
          component={EditNavigationScreen}
          options={{ presentation: 'containedTransparentModal', animation: 'fade_from_bottom' }}
        />
        <RootStack.Screen name="NotFound" component={NotFoundScreen} />

        <RootStack.Screen name="Signature" component={SignatureScreen} />
        <RootStack.Screen name="ReportPdf" component={ReportPdfScreen} />
        <RootStack.Screen name="ActivityDetailStack" component={ActivityDetailStackNavigator} />
        <RootStack.Screen name="ActivityCreation" component={ActivityCreationStackNavigator} />
        <RootStack.Screen name="CustomerStack" component={CustomerStackNavigator} />
        <RootStack.Screen name="ActivityTypeCreateOrEdit" component={ActivityTypeCreationScreen} />
        <RootStack.Screen name="TimeSlotCreateOrEdit" component={TimeSlotCreationScreen} />
        <RootStack.Screen name="ContractDetailStack" component={ContractDetailStackNavigator} />
        <RootStack.Screen name="UnavailableTypeCreateOrEdit" component={UnavailableTypeCreationScreen} />
        <RootStack.Screen name="HistoryDetailStack" component={HistoryDetailStackNavigator} />
      </RootStack.Group>
    </RootStack.Navigator>
  )
}

const LoginStack = createNativeStackNavigator<RootStackParamList>()
export function LoginStackNavigator() {
  const { theme } = useTheme()

  return (
    <LoginStack.Navigator
      screenOptions={{
        ...screenOptions,
        navigationBarColor: theme.tabNavigator.background,
        gestureEnabled: Platform.OS !== 'web', // This is to enable the navigation event listener "beforeRemove" on web
      }}>
      <LoginStack.Group>
        <LoginStack.Screen name="Login" component={LoginScreen} />
      </LoginStack.Group>
    </LoginStack.Navigator>
  )
}
