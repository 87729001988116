import { useAlert, useEvent, useLanguage, Utils } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import { api, apiDtoIds } from '../../apis/apiCalls'
import { Activity, ActivityArticle, Contract, Technician } from '../../apis/types/apiResponseTypes'
import ApiDeleteButton from '../../components/ApiDeleteButton'
import GroupSpacer from '../../components/GroupSpacer'
import useEditDataHandler, { EditDataHandlerRequiredFields } from '../../components/Infominds/hooks/useEditDataHandler'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ConstructionSiteContactSelector from '../../components/selectors/ConstructionSiteContactSelector'
import ConstructionSiteLottoSelector from '../../components/selectors/ConstructionSiteLottoSelector'
import ConstructionSiteSelector from '../../components/selectors/ConstructionSiteSelector'
import ContactSelector from '../../components/selectors/ContactSelector'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import DestinationSelector from '../../components/selectors/DestinationSelector'
import MultiActivityArticleSelector from '../../components/selectors/MultiActivityArticleSelector'
import PlanEmployeeSelector from '../../components/selectors/PlanEmployeeSelector'
import { EVENT_KEYS } from '../../constants/EventKeys'
import useActivityArticles from '../../hooks/activity/useActivityArticles'
import useActivityEmployeePlaner from '../../hooks/activity/useActivityEmployeePlaner'
import useActivityTimes from '../../hooks/activity/useActivityTimes'
import useErgoTask from '../../hooks/useErgoTask'
import useObjectUtils from '../../hooks/useObjectUtils'
import { ActivityPlan, EditOrCreateViewRef, UploadStatus } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import { articleUtils } from '../../utils/ArticleUtils'
import TimeUtils from '../../utils/TimeUtils'
import { ValidationUtils } from '../../utils/ValidationUtils'
import ActivityCreationDefaultInputs from './ActivityCreationDefaultInputs'

export type ActivityCreateOrEditViewProps = ActivityPlan & {
  activity?: Activity
  presetActivity?: Activity
  contract?: Contract
  numberOfACtivitiesToCreate?: number
  handleCreateActivity?: (activity: Activity) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
  onDone: () => void
  onDeleted: () => void
}

function ActivityCreateOrEditView(
  {
    activity,
    contract,
    numberOfACtivitiesToCreate,
    technician,
    planDateFrom,
    planDateTo,
    presetActivity,
    onDone,
    onDeleted,
    onUploadStatus,
    handleCreateActivity,
  }: ActivityCreateOrEditViewProps & Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) {
  useImperativeHandle(ref, () => ({
    handleUpload: handleCreate,
  }))

  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const [loadingContract, setLoadingContract] = useState(!!contract || !!activity?.srvContractId)
  const [selectedContract, setSelectedContract] = useState(contract)
  const [technicianDisabled, setTechnicianDisabled] = useState<Technician[]>([])
  const [showUnavailableWarning, setShowUnavailableWarning] = useState(false)
  const activityObjectUtils = useObjectUtils<Activity>(apiDtoIds.activity)

  const { plannedEmployees, handleUpdatePlannedEmployees, setPlannedEmployees, loadingPlannedEmployees } = useActivityEmployeePlaner(
    activity,
    presetActivity ? presetActivity?.createActivityEmployees?.map(e => ({ ...e, id: Utils.getUid() })) ?? [] : undefined
  )

  const { articles: selectedArticles, loadingArticles, setArticles: setSelectedArticles, apiValues } = useActivityArticles(activity)

  const { emit: emitChartRefresh } = useEvent({ key: EVENT_KEYS.GANTT_CHART_REFRESH })
  const { emit: emitPlanRefresh } = useEvent({ key: EVENT_KEYS.GANTT_CHART_TO_PLAN_REFRESH })

  const { times: activityTimes, loadingTimes: loadingActivityTimes } = useActivityTimes(activity)

  const hasPredefinedContract = !!contract
  const initialValue = useMemo<Partial<Activity>>(() => {
    if (activity) return activity
    const result = {}

    if (presetActivity) {
      Object.assign(result, presetActivity)
    }
    if (contract) {
      Object.assign(result, {
        companyId: contract?.companyId,
        destinationId: contract?.destinationId,
        objectId: contract?.objectId,
        srvContractId: contract?.srvContractId,
        device: contract.device,
      })
    }

    return result
  }, [activity, contract, presetActivity])

  const [ergoTaskId, setErgoTaskId] = useState<number | undefined>(initialValue?.taskId)
  const { ergoTask } = useErgoTask(ergoTaskId)
  const deviceMandatory = useMemo(() => ergoTask?.paramList?.AUTOMATIC_DEVICE_COMPILATION !== '1', [ergoTask])

  const getRequiredFields = useCallback(
    (state?: Activity): EditDataHandlerRequiredFields<Activity> => {
      const fields: EditDataHandlerRequiredFields<Activity> = ['taskId', 'srvActivityTypeId', 'title', 'companyId']
      if (deviceMandatory) {
        fields.push(['objectId', 'device'], ['objectLotId', 'device', 'srvContractLotId'])
      }
      if (!state?.device && state?.objectId) {
        fields.push(['objectLotId', 'srvContractLotId'])
      }
      return fields
    },
    [deviceMandatory]
  )

  const { state, editMode, createOrUpdate, handleDataChange, requiredFields } = useEditDataHandler<
    Activity & { plannedEmployeesHash?: string; articleHash?: string }, // adding hash allows to activate save icon for plannedEmployees (since they are not part of the post-object)
    Pick<Activity, 'srvActivityId' | 'srvActivityTypeId' | 'srvActivityYear'>
  >(api.activities.post, api.activities.put, undefined, {
    eventKeyCreation: EVENT_KEYS.ACTIVITY_UPDATED,
    eventKeyModification: EVENT_KEYS.ACTIVITY_UPDATED,
    onDone: () => {
      emitChartRefresh()
      emitPlanRefresh()
      onDone()
    },
    onUploadStatus: onUploadStatus,
    editMode: !!activity,
    initialValue: initialValue,
    showErrorAlert: true,
    requiredFields: getRequiredFields,
    modifyDataBeforeRequest: data => {
      const request = activityUtils.createBasicRequest(data)
      if (editMode) return request
      if (plannedEmployees?.length) {
        request.createActivityEmployees = activityUtils.convertPlannedEmployeesForRequest(plannedEmployees, technicianDisabled)
      }
      if (selectedArticles?.length && !editMode) {
        request.createActivityArticles = selectedArticles
          .filter(q => !!q.quantity)
          .map(q => ({ ...q, activityArticlervActivityTypeId: data.srvActivityTypeId }))
      }
      return request
    },
    preDoneRoutine: type => {
      if (type === 'delete' || !editMode) return Promise.resolve()
      const promises: Promise<void>[] = [handleUpdatePlannedEmployees()]
      if (selectedArticles?.length && editMode && activity) {
        promises.push(articleUtils.createOrUpdateArticles(selectedArticles, activityObjectUtils.createRequestObject(activity), apiValues))
      }
      return Promise.all(promises)
    },
  })

  useEffect(() => setErgoTaskId(state?.taskId), [state?.taskId])

  useEffect(() => {
    if (!technician) return

    setPlannedEmployees([
      {
        id: '',
        technician,
        planDateFrom,
        planDateTo,
      },
    ])
  }, [])

  useEffect(() => {
    setTechnicianDisabled([])
  }, [state?.taskId])

  function handleCreate() {
    if (!state) return

    const createOrUpdateFnc = () => {
      if (handleCreateActivity) {
        handleCreateActivity({
          ...state,
          createActivityEmployees: activityUtils.convertPlannedEmployeesForRequest(plannedEmployees, technicianDisabled),
        })
      } else {
        createOrUpdate(numberOfACtivitiesToCreate)
      }
    }

    showUnavailableWarning
      ? alert(i18n.t('WARNING'), i18n.t('ACTIVITY_UNAVAILABLE_WARNING'), [
          {
            text: i18n.t('CANCEL'),
            onPress: () => {
              return
            },
            style: 'cancel',
          },
          { text: i18n.t('CONTINUE'), onPress: () => createOrUpdateFnc(), style: 'default' },
        ])
      : createOrUpdateFnc()
  }

  const contractSelected = !!state?.srvContractId
  const disableCustomerSelection = loadingContract

  const selectedLotto = useMemo(() => {
    if (state?.objectLotId) return state.objectLotId
    if (state?.srvContractLotId) return selectedContract?.lots?.find(l => l.srvContractLotId === state.srvContractLotId)?.objectLotId
    return undefined
  }, [state, selectedContract])

  function onArticlesChanged(articles: ActivityArticle[]) {
    setSelectedArticles(articles)
    handleDataChange({ articleHash: JSON.stringify(articles) })
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'bottom',
  }

  return (
    <ScrollViewForm disableHideKeyboardOnScroll>
      <CustomerSelector
        editable={(!contractSelected || !selectedContract?.companyId) && !disableCustomerSelection}
        required
        disabledInfo={contractSelected && i18n.t('FROM_CONTRACT')}
        onChange={value => {
          if (value?.companyId === state?.companyId) return
          handleDataChange({
            companyId: value?.companyId,
            destinationId: undefined,
            objectId: undefined,
            objectLotId: undefined,
            contactId: undefined,
            device: undefined,
          })
        }}
        value={state?.companyId}
        {...commonProps}
      />
      {!!state?.companyId && !disableCustomerSelection && (
        <ContactSelector
          customerId={state?.companyId}
          editable
          canCreateNew
          onChange={value => handleDataChange({ contactId: value?.contactId })}
          disableFastInput
          value={state?.contactId}
          title={i18n.t('CUSTOMER_CONTACT')}
          {...commonProps}
        />
      )}

      {(!contractSelected || !!state.objectId) && (
        <ConstructionSiteSelector
          customerId={state?.companyId}
          value={state?.objectId}
          editable={!contractSelected || (!selectedContract?.objectId && !selectedContract?.device)}
          onChange={value => {
            if (value?.objectId === state?.objectId) return
            handleDataChange({
              objectId: value?.objectId,
              objectLotId: undefined,
              device: value?.objectId ? undefined : state?.device,
              companyId: value?.companyId ?? state?.companyId,
              objectContactId: undefined,
            })
          }}
          disabledInfo={contractSelected && i18n.t('FROM_CONTRACT')}
          {...commonProps}
        />
      )}
      {!!state?.objectId && (!contractSelected || !state.device) && (
        <>
          <ConstructionSiteLottoSelector
            objectId={state.objectId}
            required={
              selectedContract
                ? ValidationUtils.isRequired('objectLotId', requiredFields)
                : ValidationUtils.isRequired('srvContractLotId', requiredFields)
            }
            editable={!selectedContract || (!!selectedContract.lots?.length && selectedContract.lots.length > 1)}
            onChange={value =>
              handleDataChange(
                selectedContract // if a contract exists set the srvContractLotId instead of the objectLotId
                  ? {
                      objectLotId: undefined,
                      device: undefined,
                      srvContractLotId: selectedContract.lots?.find(l => l.objectLotId === value?.lotId)?.srvContractLotId,
                    }
                  : { objectLotId: value?.lotId, device: undefined, srvContractLotId: undefined }
              )
            }
            contract={selectedContract}
            value={selectedLotto}
            disabledInfo={contractSelected && i18n.t('FROM_CONTRACT')}
            {...commonProps}
          />
        </>
      )}
      {!state?.objectLotId && (!contractSelected || !!state.device) && (
        // the device (apparato) is an alternative to the objectLot
        <TextInput
          title={i18n.t('LOTTO_DEVICE')}
          required={ValidationUtils.isRequired('device', requiredFields)}
          placeholder={i18n.t('LOTTO_DEVICE')}
          value={state?.device}
          editable={!contractSelected}
          onChangeText={value => handleDataChange({ device: value, objectLotId: undefined })}
          showClearTextIcon
          disabledInfo={contractSelected && i18n.t('FROM_CONTRACT')}
          {...commonProps}
        />
      )}
      {state?.companyId && !disableCustomerSelection && (
        <>
          {(!contractSelected || !!state.destinationId) && (
            <DestinationSelector
              customerId={state?.companyId}
              editable={!contractSelected}
              onChange={value => handleDataChange({ destinationId: value?.destinationId })}
              disableFastInput
              value={state?.destinationId}
              disabledInfo={contractSelected && i18n.t('FROM_CONTRACT')}
              {...commonProps}
            />
          )}

          {!!state.objectId && (
            <ConstructionSiteContactSelector
              objectId={state?.objectId}
              editable
              onChange={value => handleDataChange({ objectContactId: value?.contactId })}
              disableFastInput
              value={state?.objectContactId}
              {...commonProps}
            />
          )}
        </>
      )}
      <GroupSpacer />
      <ActivityCreationDefaultInputs
        state={state}
        handleDataChange={data => {
          if (data.taskId) {
            // remove all plannedEmployees of type supplier when task is changed
            setPlannedEmployees(prev => {
              prev.forEach(p => {
                if (p.technicianType !== 'supplier') return
                p.isDeleted = true
              })

              return [...prev].filter(p => !p.isDeleted || !!p.srvActivityEmployeeId)
            })

            onArticlesChanged([])
          }
          handleDataChange(data)
        }}
        initialValue={initialValue}
        editMode={editMode}
        hasTimesRegistered={activityTimes?.length ? true : loadingActivityTimes !== false ? 'loading' : false}
        hasPredefinedContract={hasPredefinedContract}
        contract={selectedContract}
        onContractSelected={value => {
          const srvContractLotId = value?.lots?.length === 1 ? value.lots[0].srvContractLotId : undefined
          setSelectedContract(value)
          setLoadingContract(false)
          if (value?.srvContractId === state?.srvContractId) {
            handleDataChange({
              companyId: value?.companyId,
              destinationId: value?.destinationId,
              objectId: value?.objectId,
              objectLotId: undefined,
              srvContractLotId: state?.srvContractLotId ?? srvContractLotId,
              device: value?.device,
            })
            return
          }
          handleDataChange({
            srvContractId: value?.srvContractId,
            companyId: value?.companyId,
            destinationId: value?.destinationId,
            objectId: value?.objectId,
            objectLotId: undefined,
            srvContractLotId: srvContractLotId,
            device: value?.device,
            contactId: undefined,
          })
        }}
        onTimeSlotChanged={timeSlot => {
          if (!timeSlot || editMode) return
          setPlannedEmployees(prev => {
            prev.forEach(planTime => {
              if (planTime.isDeleted || !timeSlot.hourFrom) return
              planTime.planDateFrom = TimeUtils.addTimeSpanToDate(planTime.planDateFrom || new Date(), timeSlot.hourFrom)?.toISOString()
              planTime.planDateTo = TimeUtils.addTimeSpanToDate(
                planTime.planDateTo || planTime.planDateFrom || new Date(),
                state?.estimatedTime ? TimeUtils.addTimeSpans(timeSlot.hourFrom, state.estimatedTime) : timeSlot.hourTo
              )?.toISOString()
            })
            return [...prev]
          })
        }}
      />

      <GroupSpacer />

      <PlanEmployeeSelector
        editable
        selectedEmployees={plannedEmployees}
        onChange={e => {
          setPlannedEmployees(e)
          handleDataChange({
            plannedEmployeesHash: JSON.stringify(e),
          })
        }}
        onTechnicianDisabled={(tech, disabled) => {
          setTechnicianDisabled(prev => {
            if (disabled) {
              return [...prev, tech]
            } else {
              return prev.filter(el => el.id === tech.id && el.technicianType === tech.technicianType)
            }
          })
        }}
        onUnavailable={setShowUnavailableWarning}
        loadingPlannedEmployees={loadingPlannedEmployees}
        taskId={state?.taskId}
        estimatedTime={state?.estimatedTime}
        timeSlotId={state?.srvTimeSlotId}
      />
      <GroupSpacer />

      {!editMode && (
        <>
          <MultiActivityArticleSelector
            onChange={onArticlesChanged}
            taskId={state?.taskId}
            selectedArticles={selectedArticles}
            {...commonProps}
            editable={!loadingArticles || !editMode}
          />
          <GroupSpacer />
        </>
      )}

      {editMode && !!activity && (
        <ApiDeleteButton
          onDeleted={() => {
            emitChartRefresh()
            emitPlanRefresh()
            onDeleted()
          }}
          data={activity}
          deleteAlertMessage={i18n.t('DELETE_ACTIVITY_ALERT')}
          deleteAlertTitle={i18n.t('DELETE_ACTIVITY')}
          deleteRequest={api.activities.delete}
          eventKey={EVENT_KEYS.ACTIVITY_UPDATED}
          {...commonProps}
        />
      )}
      <GroupSpacer />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityCreateOrEditView)
