import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { Activity } from '../../apis/types/apiResponseTypes'
import Pressable from '../../components/Infominds/Pressable'
import Separator from '../../components/Infominds/Separator'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import useUserSettings from '../../hooks/useUserSettings'
import { InfiniteLoadingType, LoadingType, ThemeColorExpanded } from '../../types'
import { activityUtils } from '../../utils/ActivityUtils'
import ActivityCardDescription from './components/ActivityCardDescription'
import ActivityCardDetailInfo from './components/ActivityCardDetailInfo'
import ActivityCardHeader from './components/ActivityCardHeader'
import ActivityCardTimeInfo from './components/ActivityCardTimeInfo'

type Props = {
  activity: Activity
  height: number
  spacing?: SpacingProps
  borderless?: boolean
  limitDescriptionToLines?: number
  forceLayout?: false | 'small' | 'medium' | 'large'
  isSelected?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  index: number
  total: number
  hasCoordinates?: boolean
  loading: LoadingType | InfiniteLoadingType
  assignable?: boolean
}

const ActivityMapCarouselCard = memo(function ActivityMapCarouselCard({
  activity,
  spacing,
  height,
  onPress,
  borderless,
  style,
  index,
  total,
  hasCoordinates,
  assignable = true,
  loading,
}: Props) {
  const { i18n, language } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { user } = useUserSettings()

  const showTimeInfo = useMemo(
    () =>
      !!activity.expireDate ||
      !!activity.closedDate ||
      !!activityUtils.formatNextActivityPlanDate(activity, language, user.isTechnician && user.technician ? user.technician : undefined)?.length,
    [activity, user]
  )

  return (
    <IM.Card
      noContentSpacing
      borderless={borderless}
      spacing={spacing}
      style={[{ backgroundColor: theme.card.background, height, borderRadius: IMLayout.borderRadius }, IMLayout.shadow, style]}>
      <Pressable style={styles.pressable} onPress={onPress}>
        {loading === false ? (
          <>
            <IM.View>
              <IM.View style={IMLayout.flex.row}>
                <ActivityCardHeader
                  style={IMLayout.flex.f1}
                  activity={activity}
                  type="carousel"
                  forceLayout="small"
                  assignable={assignable}
                  showEmployee
                />
              </IM.View>
              {showTimeInfo && (
                <>
                  <Separator spacing="vertical" />
                  <IM.View style={IMLayout.flex.row}>
                    <ActivityCardTimeInfo forceLayout="small" activity={activity} />
                  </IM.View>
                </>
              )}
              <Separator />
              <ActivityCardDetailInfo activity={activity} numberOfLines={1} />
              <ActivityCardDescription activity={activity} numberOfLines={1} spacing="top" />
            </IM.View>
            <IM.View style={[IMLayout.flex.row]}>
              <IM.View
                style={[
                  styles.counter,
                  {
                    backgroundColor: theme.chip.background,
                  },
                ]}>
                <Text secondary={colorScheme === 'light'} style={{ fontSize: IMStyle.typography.fontSizeSmall - 2 }}>
                  {index}/{total}
                </Text>
              </IM.View>
              {hasCoordinates === false && (
                <IM.View style={styles.message}>
                  <TextWithIcon icon={['fal', 'location-dot-slash']} iconColor={theme.general.error} secondary>
                    {i18n.t('MISSING_COORDINATES')}
                  </TextWithIcon>
                </IM.View>
              )}
            </IM.View>
          </>
        ) : (
          <IM.View style={IMLayout.flex.f1}>
            <SkeletonText width="60%" height={30} spacing="bottom" />
            <SkeletonText width="80%" height={30} spacing="vertical" />
            <SkeletonText width="100%" height={30} spacing="vertical" />
            <SkeletonText width="80%" height={30} spacing="vertical" />
            <SkeletonText width="60%" height={30} spacing="top" />
          </IM.View>
        )}
      </Pressable>
    </IM.Card>
  )
})

export default ActivityMapCarouselCard

const styles = StyleSheet.create({
  counter: { borderRadius: 25, paddingVertical: 3, paddingHorizontal: 10, alignSelf: 'flex-start', justifyContent: 'flex-end' },
  pressable: {
    padding: 12,
    height: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  message: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: IMLayout.horizontalMargin * 3,
  },
})
