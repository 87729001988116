import { LiteAssetType } from '@infominds/react-native-media-lite'

import { SyncResourceType } from './apiRequestTypes'

export interface ApiError {
  Code: number
  Message: string
}

export interface Mandant {
  id: number
  name: string
}

export interface TimeAttendance {
  id: number
  description?: string
}

export type Qualification = { id: number; description: string }
export type ErgoTitle = { titleId: number; description: string }
export type CorpPosition = { corpPositionId: number; description: string }
export type MailingType = { mailingTypeID: number; description: string }
export type ErgoLanguage = { languageId: number; description: string }

export type TechnicianType = 'employee' | 'supplier'

export type Technician = Employee & {
  technicianType: TechnicianType
  qualification?: Qualification
  address?: Address
  tasks?: number[]
  planTimes?: ActivityPlanTime[]
  disabled?: boolean
}

export interface UserSettings {
  employee?: Employee
  roles?: UserRole
}
export interface MenuItems {
  activities: boolean
  reports: boolean
  history: boolean
  planing: boolean
  anagrafics: boolean
  synchronization: boolean
}

export enum UserRole {
  technician = 'technician',
  teamLeader = 'teamLeader',
  planner = 'planner',
  admin = 'admin',
}

export interface Customer {
  companyId: number
  typeId: string
  subTypeId: number
  taxId: string
  vatId: string
  company1: string
  company2: string
  address?: Address
  phone: string
  phone2: string
  customID1: number
  customID2: number
  customID3: number
  customID4: number
  customID5: number
  fax: string
  email: string
  billDate: string
  billID: string
  billTypeID: number
  languageID: number
  kindOfSupplier: string
  intrastatMode: string
  payCodeID: number
  denomiState2: string
  firstName: string
  lastName: string
  outStandingOn: boolean
  kindOfEnterprise: string
  categ1ID: number
  categ2ID: number
  categ3ID: number
  priceCategID: number
  remindTypeID: number
  freightID: number
  mainSalesManID: number
  bonusGroup: number[]
  priceClassID: number
  accBanckID: number
  carrierID: number
  autoDelNotePrint: string
  birthDate: string
  salesManID: number[]
  invoice?: Invoice
}

export interface Address {
  street?: string
  zip?: string
  place?: string
  province?: string
  state?: string
  latitude?: string
  longitude?: string
}
export interface Invoice {
  feTypeDestination: string
  feCodeDestination: string
  fepecDestination: string
  feStableOrganization: number
  feFiscalRepresentative: number
}

export interface Contact {
  companyId?: number
  objectId?: number
  contactId: number
  contactType?: number
  description?: string
  firstName?: string
  lastName?: string
  salutation?: string
  phone?: string
  phone2?: string
  email?: string
  fax?: string
  address?: Address
  position?: string
  note?: string
  sex?: number
  titleId?: number
  corpPositionId?: number
  languageId?: number
}

export interface Destination {
  companyId: number
  destinationId: number
  priority?: number
  description?: string
  salutation?: string
  languageID?: number
  phone?: string
  fax?: string
  email?: string
  payCodeID?: number
  closed?: number
  billingDestID?: number
  isMainDestination?: boolean
  address?: Address
}

export interface ConstructionSite {
  objectId: number
  companyId: number
  destinationId?: number
  description?: string
  email?: string
  phone?: string
  address?: Address
}

export interface ConstructionSiteLotto {
  objectId: number
  lotId: number
  description?: string
}

export interface ActivityType {
  srvActivityTypeId: number
  descriptionEn?: string
  descriptionDe?: string
  descriptionIt?: string
  notToInvoice?: boolean
  timeAttendId?: number
  planColor?: string
}

export interface TimeSlot {
  srvTimeSlotId: number
  descriptionIt?: string
  descriptionDe?: string
  descriptionEn?: string
  titleIt?: string
  titleDe?: string
  titleEn?: string
  hourFrom?: string
  hourTo?: string
  increasePercentage?: number
}

export type Contract = {
  srvContractId: number
  noteIt?: string
  noteDe?: string
  noteEn?: string
  titleIt?: string
  titleDe?: string
  titleEn?: string
  startDate?: string
  expiredDate?: string
  activityNumber?: number
  objectId?: number
  companyId?: number
  destinationId?: number
  device?: string
  isDeleted?: boolean
  lots?: ContractObjectLot[]
  ergoDocDate?: string
  ergoDocNr?: string
  createObjectLots?: CreateActivityContractLots[] // post
  createActivities?: Partial<Activity>[] // post
}

export type CreateActivityContractLots = {
  objectLotId: number
  createActivities?: Partial<Activity>[]
}

export interface UnavailableType {
  srvUnavailableTypeId: number
  noteIt?: string
  noteDe?: string
  noteEn?: string
  titleIt?: string
  titleDe?: string
  titleEn?: string
  type?: UnavailableTypeDefinition
  planColor: string
  timeAttendId?: number
}

export enum UnavailableTypeDefinition {
  disease = 'disease',
  vacation = 'vacation',
  constructionSite = 'constructionSite',
}

export interface Unavailable {
  srvUnavailableId: number
  srvUnavailableTypeId: number
  fromDate?: string
  toDate?: string
  objectId?: number
  objectLotId?: number
  technicianId?: number
  technicianType?: TechnicianType
  device?: string
  unavailableTypeTitle: string
  unavailableTypeNote: string
  unavailableTypePlanColor: string
}

export type ConnectedActivity = {
  srvActivityEmployeeId: number
  srvActivityTypeId: number
  srvActivityYear: number
  srvActivityId: number
  technician: Technician
}

export interface Activity {
  srvActivityId: number // primary key
  srvActivityTypeId: number // primary key
  srvActivityYear: number // primary key
  srvContractId?: number
  srvContractLotId?: number
  contractLotDescription?: string
  activityTypeDescription?: string
  title?: string
  description?: string
  taskId?: number
  taskDescription?: string
  activityDate?: string // date when activity was created
  expireDate?: string
  closedDate?: string
  priority?: number
  companyId?: number
  objectId?: number
  destinationId?: number
  customerDescription?: string
  constructionSiteDescription?: string
  destinationDescription?: string
  address?: Address
  timeCount?: number
  notesCount?: number
  state: ActivityState
  assignedUser?: Technician[]
  technicalDescription?: string
  objectLotId?: number
  objectLotDescription?: string
  srvTimeSlotId?: number
  contactId?: number
  contactDescription?: string
  objectContactId?: number
  objectContactDescription?: string
  device?: string
  isOnlyReport?: number
  /**
   * @deprecated use assignedUser.planTime instead
   */
  planTime?: ActivityPlanTime[]
  estimatedTime?: string // TimeSpan 00:00
  contractLot?: ContractObjectLot
  paid?: number
  paidAmount?: number
  discount?: number
  isWorking?: boolean
  customerNote?: string
  customerEmail?: string
  totalAmount?: number
  totalVatAmount?: number
  createActivityEmployees?: Partial<ActivityEmployee>[]
  planColor?: string
  connectedActivity: ConnectedActivity[]
  referToDocId?: number
  referToDocType?: number
  referToSerialNumber?: number
  referToSerialYear?: number
  createActivityArticles?: Partial<ActivityArticle>[]
  inElaboration?: boolean
  planDate?: string
  timeSlot?: TimeSlot
  forceDeletion?: boolean // DELETE only
  rdaStatus?: RdaStatus
}

export enum ActivityState {
  Open = 'Open',
  Suspended = 'Suspended',
  Closed = 'Closed',
  ClosedWithInterventionConnected = 'ClosedWithInterventionConnected',
}

export interface ActivityPlanTime {
  planDateFrom: string
  planDateTo: string
}

export interface ErgoTask {
  id: number
  description?: string
  paramList?: ErgoTaskParamList
}

export type ErgoTaskParamList = {
  CAN_CREATE_CUSTOMER?: string
  CHANGE_SALE_PRICEANDDISCOUNT?: string
  HIDE_APP_PRICE?: string
  IMAGE_DOWNLOADMODE?: string
  PRICELISTID?: string
  PRICELISTID_SQL?: string
  SERIALNR?: string
  SERIALNR_SQL?: string
  SHOW_SALE_PRICE?: string
  SIGNATURE?: string
  WAREHOUSEID?: string
  WAREHOUSEID_SQL?: string
  CAN_USE_WAREHOUSEID_FROM_GOODCATEGORY?: string
  AUTOMATIC_DEVICE_COMPILATION?: string
  DEFAULT_ARTICLEID?: string
  CREATE_RDA?: string
  RDA_SERIALNR_SQL?: string
  RDA_SERIALNR?: string
}

export interface ActivityEmployee {
  srvActivityEmployeeId: number
  srvActivityTypeId: number
  srvActivityYear: number
  srvActivityId: number
  technician?: Technician
  planDateFrom?: string
  planDateTo?: string
  plannedByEmployee?: Employee
  technicianId?: number // for post, put and offline only. use technician.id instead
  technicianType?: TechnicianType
  plannedByEmployeeId?: number // for post, put and offline only. use plannedByEmployee.id instead
  title?: string
  srvContractId?: number
  objectId?: number
  state: ActivityState
  constructionSiteDescription?: string
  customerDescription?: string
  assignedUser?: Technician[]
  planColor?: string
  connectedActivity: ConnectedActivity[]
  inElaboration: boolean
  timeSlots: TimeSlot
}

export interface Employee {
  id: number
  firstName?: string
  lastName?: string
}

export type ActivityNote = {
  srvActivityNoteId: number
  srvActivityId: number
  srvActivityTypeId: number
  srvActivityYear: number
  notes?: string
  noteDate?: string
  employeeId?: number // for post, put and offline only. use employee.id instead
  employee?: Employee
  mediaCount?: number
}

export interface ActivityMedia {
  srvMediaId: number
  srvActivityId: number
  srvActivityTypeId: number
  srvActivityYear: number
  srvActivityNoteId: number
  externalType?: number
  media?: string
  mediaTitle?: string
  type?: LiteAssetType
  mediaSize?: number
  mediaDuration?: number
  note?: string
  isSignature?: number
}

export interface ActivityArticleMedia {
  srvMediaId: number
  srvActivityId: number
  srvActivityTypeId: number
  srvActivityYear: number
  srvActivityArticleId: number
  externalType?: number
  media?: string
  mediaTitle?: string
  type?: LiteAssetType
  mediaSize?: number
  mediaDuration?: number
  note?: string
}

export interface ActivityTime {
  srvActivityTimeId: number
  srvActivityTypeId: number
  srvActivityYear: number
  srvActivityId: number
  technician?: Technician
  timeDate?: string
  startTime?: string
  stopTime?: string
  total?: string
  activityTimeSrvActivityTypeId?: number
  technicianId?: number // required for put and offline DataProvider
  technicianType?: TechnicianType // required for put and offline DataProvider
  notes?: string
}

export type ActivityNotePostResult = Pick<ActivityNote, 'srvActivityId' | 'srvActivityTypeId' | 'srvActivityYear' | 'srvActivityNoteId'>

export interface ActivityTravel {
  srvActivityTravelId: number
  srvActivityId: number
  srvActivityTypeId: number
  srvActivityYear: number
  technician?: Technician
  travelDate?: string
  km?: number
  travelDuration?: string
  activityTravelSrvActivityTypeId?: number
  technicianId?: number // post, put only
  technicianType?: TechnicianType // post, put only
}

export interface Article {
  articleId: string
  description?: string
  goodCategory?: ArticleGoodCategory
  measureUnit?: ArticleMeasurementUnit
  actStock?: number
  mainSupplier?: string
  supplyArticleId?: string[]
}

export interface ArticleGoodCategory {
  goodCategoryId1: number
  goodCategoryId2: number
  goodCategoryId3: number
  goodCategoryId4: number
}

export interface ArticleMeasurementUnit {
  measureUnitId: number
  description: string
}

export interface SupplyArticle {
  supplyArticleId: string
  supplyArticleDescription?: string
  linkToArticleId: string
  supplierId: number
  supplierDescription?: string
}

export interface ActivityArticle {
  srvActivityArticleId: number
  srvActivityTypeId: number
  srvActivityYear: number
  srvActivityId: number
  employee?: Employee
  articleId?: string
  title?: string
  description?: string
  articleDate?: string
  quantity?: number
  activityArticlervActivityTypeId?: number
  measureUnit?: ArticleMeasurementUnit
  vatExEmpt?: VatExEmpt
  totalAmount?: number
  unitPrice?: number // price without vat and discount
  netPrice?: number // price without vat with discount
  buyPrice?: number //
  rowDiscount1?: number
  rowDiscount2?: number
  rowDiscount3?: number
  rowDiscount4?: number
  vatAmount?: number
  pos?: number
  determinePrice?: boolean // post & put
  measureUnitId?: number // post & put
  isJolly?: boolean
  mediaCount?: number
  refer2DocType?: number
  refer2SerialYear?: number
  refer2SerialNr?: number
  refer2DocId?: number
  refer2DocPosId?: number
  docDate?: Date
  isRda?: boolean
  rdaStatus?: RdaStatus
  actStock?: number
}

export enum RdaStatus {
  None = 'None',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
}

export interface VatExEmpt {
  vatExEmptId: number // id
  description: string
  vatRate: number // vat
}

export interface ArticlePriceList {
  priceListId: number
  filterCategory?: string
  cagetoryListKind?: string
  categoryList?: ArticlePriceListCategories
}

export interface ArticlePriceListCategories {
  [key: string]: ArticlePriceListNode
}

export interface ArticlePriceListNode {
  levelRowId: string
  parentLevelRowId?: string
  goodCategoryId1?: number
  goodCategoryId2?: number
  goodCategoryId3?: number
  goodCategoryId4?: number
  description?: string
  categoryNodeList?: ArticlePriceListCategories
}

export interface SynchronisationInfo {
  deviceId: string
  synchronisationDetails: SynchronisationDetail[]
}

export interface SynchronisationDetail {
  type: SyncResourceType
  dataCount: number
  blockCount: number
  startWithBlock: number
  fullSync: boolean
}

export interface SynchronisationData<T> {
  deviceId: string
  type: string
  blockNumber: number
  data: T[]
}

export interface ContractObjectLot {
  srvContractLotId: number
  srvContractId: number
  objectLotId?: number
}

export type ActivityClosingSummary = {
  articleSummary?: ArticleSummary[]
  timeSummary?: TimeSummary[]
  travelSummary?: TravelSummary[]
  totalArticle?: number
  totalTime?: number
  totalTravel?: number
  totalAmount?: number
  totalVatAmount?: number
  totalAmountWithVat?: number
}

export type ArticleSummary = {
  articleId?: string
  title?: string
  quantity?: number
  unitPrice?: number
  netPrice?: number
  vatExEmpt?: VatExEmpt
  rowDiscount1?: number
  rowDiscount2?: number
  rowDiscount3?: number
  rowDiscount4?: number
  totalNetPrice?: number
  totalVat?: number
  notToInvoice?: boolean
}

export type TimeSummary = {
  id?: number
  title?: string
  firstName?: string
  lastName?: string
  technicianType?: TechnicianType
  articleId?: string
  quantity?: number
  unitPrice?: number
  netPrice?: number
  rowDiscount1?: number
  rowDiscount2?: number
  rowDiscount3?: number
  rowDiscount4?: number
  totalNetPrice?: number
  notToInvoice?: boolean
}

export type TravelSummary = {
  id?: number
  title?: string
  firstName?: string
  lastName?: string
  technicianType?: TechnicianType
  articleId?: string
  quantity?: number
  unitPrice?: number
  netPrice?: number
  rowDiscount1?: number
  rowDiscount2?: number
  rowDiscount3?: number
  rowDiscount4?: number
  totalNetPrice?: number
  notToInvoice?: boolean
}

export type SupplierArticle = {
  supplyArticleId: string
  supplyArticleDescription?: string
  linkToArticleId: string
  supplierId: number
  supplierDescription?: string
  isMainSupplierId?: boolean
}

export type ActivityRDA = {
  srvActivityId: number
  srvActivityTypeId: number
  srvActivityYear: number
}
